import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import collectIcon from '../../../../assets/collect.svg';
import feePaidIcon from '../../../../assets/feepaid.svg';
import hgraphIcon from '../../../../assets/hgraph.svg';
import { Access } from '../PortalAccess/AccessEnum';
import DisplayCard from '../../../../components/DisplayCard';
import DisplayCardSmall from '../../../../components/DisplayCardSmall';
import Notification from '../../../../components/Notification/Notification';
import { dashboardContext } from '../../Dashboard';
import Modal from '../../../../components/Modal/Modal';
import { useMutation } from '@apollo/client';
import { SENT_REMINDERS_TO_STUDENTS } from './Querries';
import { toast } from 'react-toastify';
import ConfirmationBox from '../../../../components/ConfirmationBox/ConfirmationBox';
const options = ['Super Admin', 'Admin', 'Management Head', 'Management Staff'];
const getRoleFromAccess = (access: Access) => {
	if (access === Access.SUPER) return options[0];
	if (access === Access.ALL_ACCESS) return options[1];
	if (access === Access.MANAGEMENT) return options[2];
	if (access === Access.STAFF) return options[3];
};
const SchoolOverview = ({ number_of_students, number_of_sections }: any) => {
	return (
		<div className='flex gap-2'>
			<DisplayCardSmall count={number_of_students} countOf='Number of students' to='/student_profile' />
			<DisplayCardSmall count={number_of_sections} countOf='Number of sections' to='/sections' />
		</div>
	);
};

const FeeOverview = ({ date, number_of_students, number_of_paid_students, total_collected }: any) => {
	const [installment_collection, set_installment_collection] = useState(0);
	const [installment_paid_student_count, set_paid_student_count] = useState(0);
	const [installment_student_count, set_student_count] = useState(0);
	const [installment_date, set_installment_date] = useState('');
	useEffect(() => {
		set_installment_date(new Date(date).toString());
		set_installment_collection(total_collected);
		set_paid_student_count(number_of_paid_students);
		set_student_count(number_of_students);
	}, []);

	return (
		<div className='flex  text=edvion_black gap-5 px-2'>
			<DisplayCard
				to='/fee_reports/section_report'
				icon={collectIcon}
				value={'₹' + installment_collection?.toLocaleString('hi')}
				label={`Total fee collected for Installment (${new Date(installment_date).toDateString()})`}
			/>
			<DisplayCard
				to='/fee_reports/student_report'
				icon={feePaidIcon}
				value={installment_paid_student_count?.toString()}
				label={`Number of student who have paid fees for Installment (${new Date(installment_date).toDateString()})`}
				denominator={installment_student_count?.toString()}
			/>
			{/* <FeeCollected totalFeeCollected={43434} /> */}
			{/* <FeePaidStudentCount totalStudent={500} students={249} /> */}
		</div>
	);
};

const PortalUser = ({ user }: any) => {
	return (
		<div className='grid grid-cols-2 grid-body items-center p-3 mx-5 my-2 odd:bg-[#ECEDFB] even:bg-[#F8FAFB] odd:border-none even:border border-[#ECEDFB] rounded-lg'>
			<div className='text-center truncate'>{user.name}</div>
			<div className='text-center truncate'>{getRoleFromAccess(user.access)}</div>
		</div>
	);
};
const PortalAccessUsers = ({ portalUsers }: any) => {
	return (
		<div className='table w-2/3 shadow p-5 rounded-lg my-2 text-edvion_black'>
			<div className='grid grid-cols-2 grid-header p-3 font-semibold m-5 bg-[#ECEDFB] rounded-t-lg text-violet-900'>
				<div className='text-center truncate'>Name</div>
				<div className='text-center truncate'>Role</div>
			</div>
			{portalUsers?.map((user: any, i: number) => {
				return <PortalUser key={i} user={user} />;
			})}
		</div>
	);
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars

const RightSideBar = () => {
	const { school, notifications } = useContext(dashboardContext);
	const [showConfirmationModal, setConfirmationModal] = useState(false);
	const [sentReminders, { error }] = useMutation(SENT_REMINDERS_TO_STUDENTS);
	function isValidJson(json: any) {
		try {
			JSON.parse(json);
			return true;
		} catch (err) {
			return false;
		}
	}
	return (
		<div className='mx-5  text-center w-72  py-3 fixed right-0 top-40  h-[calc(100vh-160px)]'>
			<div className=' p-5 text-left bg-[#ECEDFB] text-edvion_black  rounded-lg h-full'>
				<div className='font-[18px]'>Tools for you</div>
				<Link to='/fee_reports'>
					<div className='mt-[15px] flex font-light text-[16px] items-center'>
						<div className='bg-[#6f6af840] rounded-lg p-3'>
							<i className='fa-solid fa-signal'></i>
						</div>
						<div className='ml-[16px]'>View fee reports</div>
					</div>
				</Link>
				<Link to='/fee_reports/status_report'>
					<div className='mt-[15px] flex font-light text-[16px] items-center'>
						<div className='bg-[#6f6af840] rounded-lg p-3'>
							<i className='fa-solid fa-signal'></i>
						</div>
						<div className='ml-[16px]'>View Cheque/DD Status Report</div>
					</div>
				</Link>
				<button
					onClick={() => setConfirmationModal(true)}
					disabled={!(new Date().getTime() >= new Date(school.school.reminders_available_from).getTime())}
				>
					<div
						className={
							'mt-[15px] flex font-light text-[16px] items-center  ' +
							(!(new Date().getTime() >= new Date(school.school.reminders_available_from).getTime())
								? ' cursor-not-allowed'
								: '  cursor-pointer')
						}
					>
						<div
							className={
								' rounded-lg p-3 ' +
								(!(new Date().getTime() >= new Date(school.school.reminders_available_from).getTime())
									? '  bg-gray-300'
									: '  bg-[#6f6af840]')
							}
						>
							<i
								className={
									'fa-solid fa-paper-plane text-[20px] ' +
									(!(new Date().getTime() >= new Date(school.school.reminders_available_from).getTime()) &&
										' text-gray-600')
								}
							></i>
						</div>
						<div className='ml-[16px]'>Send Reminders</div>
					</div>
				</button>

				<div className='mt-10 ml-[2px] text-edvion_black'>
					<div className='flex items-center'>
						<div className='font-[18px]'>Notifications</div>
						{/* <div className='ml-[12px]'>
							<img src={notificationIcon} alt='' />
						</div> */}
					</div>
					{notifications && (
						<div className='overflow-y-auto scrollbar-hide h-[36.5rem]'>
							{notifications?.map((notification: any) => {
								return <Notification log={isValidJson(notification.data) ? JSON.parse(notification.data).log : null} />;
							})}
						</div>
					)}
				</div>
			</div>
			<Modal open={showConfirmationModal} setOpen={setConfirmationModal}>
				<ConfirmationBox
					setOpen={setConfirmationModal}
					funtion={async () => {
						sentReminders();
						if (error) toast.error(error?.graphQLErrors[0].message);
						setConfirmationModal(false);
					}}
					confirmationText='send reminders'
					ButtonText='Send Reminders'
				/>
			</Modal>
		</div>
	);
};

const Overview = ({ portalUsers, school }: any) => {
	const latest_due_date = school.instllment_wise_fee_report
		.filter((i: any) => new Date(i.due_date) < new Date())
		.sort((a: any, b: any) => {
			return new Date(b.due_date).getTime() - new Date(a.due_date).getTime();
		})[0]?.due_date;
	const current_installment = school.instllment_wise_fee_report.find((i: any) => i.due_date === latest_due_date);

	return (
		// <Loading component={<DashboardSmallLoader />}>
		<div className='flex-col overview w-full flex pr-[20rem] text-edvion_black'>
			<div className='heading text-4xl h-16 font-normal sticky top-12 z-2 bg-[#F8FAFB]'>Dashboard</div>
			<div className='flex flex-row mb-0 '>
				<div className='flex flex-col flex-1  h-[calc(100vh-112px)] overflow-y-scroll no-scrollbar px-2'>
					<div className='my-5 text-lg font-semibold'>Institute Overview</div>
					<SchoolOverview
						number_of_sections={school.number_of_sections || 0}
						number_of_students={school.number_of_students || 0}
					/>
					<div className=' my-5 text-lg font-semibold '>
						<Link to={'/fee_collection'}>
							<div className='flex items-center'>
								<span className='hover:underline hover:underline-offset-2'>Fee Overview</span>
								<i className='fa-solid fa-arrow-right ml-2 text-[#9747FF] text-xl transform -rotate-45'></i>
							</div>
						</Link>
					</div>
					<FeeOverview
						date={latest_due_date}
						number_of_students={school.number_of_students || 0}
						number_of_paid_students={
							(current_installment?.total_students_paid && current_installment?.total_students_paid) || 0
						}
						total_collected={(current_installment?.total_amount_paid && current_installment?.total_amount_paid) || 0}
					/>
					<Link to='/portal_access'>
						<div className='my-5 text-lg font-semibold cursor-pointer'>
							<span className='hover:underline hover:underline-offset-2'> Portal Access</span>
							<span className='ml-2'>
								<i className='fa-solid fa-pen'></i>
							</span>
						</div>
					</Link>
					<PortalAccessUsers portalUsers={portalUsers} />
				</div>
				<RightSideBar></RightSideBar>
			</div>
		</div>
		// </Loading>
	);
};
export { RightSideBar };
export default Overview;
