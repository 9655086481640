import React from 'react';
import rightcrossarrowIcon from '../../../assets/rightpurpalarrow.svg';
import { Link } from 'react-router-dom';
import { Access } from '../../PortalAccess/AccessEnum';
import { useContext } from 'react';
import { dashboardContext } from '../../../Dashboard';
import { Mixpanel } from '../../../../../util/mixpanel';
const Options = ({ option, path, user, school }: any) => {
	return (
		<Link
			to={`/fee_reports/${path}`}
			onClick={() => {
				Mixpanel.track('reports', {
					schoolId: user.school?.id,
					school: user.school?.name,
					user_name: user?.name,
					report_type: path,
				});
			}}
		>
			<div className='mt-2 flex py-4 px-10 justify-between items-center bg-[#DADBFC] rounded-lg cursor-pointer'>
				<div>{option}</div>
				<div>
					<img src={rightcrossarrowIcon} alt='' />
				</div>
			</div>
		</Link>
	);
};
const ViewReports = () => {
	const { user, school } = useContext(dashboardContext);
	return (
		<div className='text-[20px] my-5  py-5 px-4 rounded-lg shadow'>
			<div className='mb-5'>View Reports</div>
			<div>
				<Options user={user} school={school} option={'Defaulters'} path={'defaulters'} />
				<Options user={user} school={school} option={'Section Wise Report'} path={'section_report'} />
				<Options user={user} school={school} option={'Installment Wise Report'} path={'installment_report'} />
				<Options user={user} school={school} option={'Payment Mode Wise Report'} path={'payment_report'} />
				{/* <Options user={user} school={school} option={'Time Wise Report'} path={'time_report'} /> */}
				{user.access === Access.SUPER || user.access === Access.ALL_ACCESS || user.access === Access.MANAGEMENT ? (
					<Options user={user} school={school} option={'Cheque/DD Status Report'} path={'status_report'} />
				) : (
					''
				)}

				<Options user={user} school={school} option={'Availed Discount Report'} path={'discount_report'} />
				<Options user={user} school={school} option={'Fine Wise Report'} path={'fine_report'} />
				<Options user={user} school={school} option={'Student Wise Report'} path={'student_report'} />
				{/* <Options user={user} school={school} option={'POS Transactions Report'} path={'pos_report'} /> */}
			</div>
		</div>
	);
};

export default ViewReports;
