import React, { useEffect, useState } from 'react';
import { preventNegativeValues } from '../../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../../helper/preventPasteNegative';
import { isValidNumber } from '../../../../../../../util/IsValidNumber';

const RangeCard = ({ onDelete, cardIndex, cards, breakPoint, fees, setFees, setBreakPoint }: any) => {
	const error =
		(cardIndex === 0 && cards.length > 1 && (breakPoint[cardIndex] <= 0 || !isValidNumber(breakPoint[cardIndex]))) ||
		(cardIndex > 0 && cardIndex < cards.length && Number(breakPoint[cardIndex - 1]) >= Number(breakPoint[cardIndex]));
	const error1 = cardIndex < cards.length - 1 && isNaN(Number(breakPoint[cardIndex]));

	return (
		<div>
			<div className='flex justify-between items-center'>
				<h3 className=' text-sm font-medium text-edvion_black'>Range {cardIndex + 1}</h3>
				<h3 className=' text-sm font-medium text-edvion_black'>Amount</h3>
			</div>
			<div className='flex justify-between gap-4 items-center mt-4'>
				<div className='flex items-end gap-4 min-w-[16rem] max-w-[20rem]'>
					<input
						type='number'
						name='initialRange'
						disabled={true}
						value={cardIndex === 0 ? 0 : breakPoint[cardIndex - 1]}
						placeholder='set initial range'
						className='rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs '
					/>

					<input
						type='text'
						name='finalRange'
						pattern='/^[0-9∞]*$/'
						onChange={(e) => {
							if (/^[0-9∞]*$/.test(e.target.value)) {
								setBreakPoint(
									breakPoint.map((v: any, i: number) => {
										if (i === cardIndex) {
											return e.target.value;
										} else {
											return breakPoint[i];
										}
									}),
								);
							}
						}}
						disabled={cardIndex === cards.length - 1}
						placeholder='set final range'
						value={cardIndex === cards.length - 1 ? '∞' : breakPoint[cardIndex]}
						className={
							'rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs ' +
							((error || error1) && ' border border-red-500')
						}
					/>
					<span className='text-[#959595] text-opacity-80 -ml-2 text-xs flex-none'>(in km)</span>
				</div>
				<div className='w-1/4'>
					<input
						type='number'
						name='amount'
						value={fees[cardIndex]}
						onChange={(e) =>
							setFees(
								fees.map((v: any, i: number) => {
									if (i === cardIndex) {
										return e.target.value;
									} else {
										return fees[i];
									}
								}),
							)
						}
						placeholder='₹'
						className={
							'rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs placeholder:text-right '
						}
						min={0}
						onKeyDown={preventNegativeValues}
						onPaste={preventPasteNegative}
					/>
				</div>
			</div>
			<div className='flex justify-end mt-2'>
				{cards.length > 1 && cards.length === cardIndex + 1 && (
					<button className='text-[#6F6AF8] text-xs font-medium' onClick={onDelete}>
						Delete
					</button>
				)}
			</div>
		</div>
	);
};

function KmRateCard({ data, setData, setActiveTab, setOtherField }: any) {
	const [cards, setCards] = useState<any>([1]);
	const [breakPoint, setBreakPoint] = useState<any>([]);
	const [fees, setFees] = useState<any>([0]);
	const [isValidInput, setIsValidInput] = useState<boolean>(false);

	useEffect(() => {
		setIsValidInput(
			!breakPoint
				.map((c: any, i: number) => {
					if (i === 0) {
						return isValidNumber(breakPoint[i]) && breakPoint[i] > 0;
					} else {
						return (
							isValidNumber(breakPoint[i - 1]) &&
							isValidNumber(breakPoint[i]) &&
							Number(breakPoint[i - 1]) < Number(breakPoint[i])
						);
					}
				})
				.includes(false),
		);
	}, [breakPoint, fees]);

	const addCard = () => {
		setCards([...cards, cards.length]);
		if (cards.length > 0) {
			setBreakPoint([...breakPoint, '']);
		}
		if (cards.length > 0) setFees([...fees, 0]);
	};
	const deleteCard = (index: any) => {
		setCards(cards.filter((cardIndex: any, i: any) => i !== index));
		setBreakPoint(breakPoint.slice(0, breakPoint.length - 1));
		setFees(fees.slice(0, fees.length - 1));
	};

	return (
		<div className='min-h-[32rem] h-full shadow rounded-md p-4 flex flex-col justify-between'>
			<div>
				<div className='flex justify-between items-center mb-8'>
					<h3 className=' text-lg font-medium text-edvion_black'>Rate Card</h3>
					<button className='capitalize text-[#6F6AF8] text-xs font-medium' onClick={addCard}>
						+ add new range
					</button>
				</div>
				<div className='space-y-4 overflow-hidden overflow-y-auto max-h-[22rem] pr-6'>
					{cards.map((cardIndex: any, index: any) => (
						<RangeCard
							breakPoint={breakPoint}
							setBreakPoint={setBreakPoint}
							key={index}
							cards={cards}
							fees={fees}
							setFees={setFees}
							cardIndex={index}
							onDelete={() => deleteCard(index)}
						/>
					))}
				</div>
			</div>
			<div className='flex justify-end'>
				<button
					disabled={!isValidInput}
					onClick={() => {
						setActiveTab(2);
						setOtherField(true);
						setData({
							...data,
							breakPoint,
							fees,
						});
					}}
					className='rounded-lg disabled:bg-[#D9D9D978] disabled:text-[#959595] bg-blue-700 text-white px-8 py-1 max-w-fit'
				>
					Next
				</button>
			</div>
		</div>
	);
}

export default KmRateCard;
