import { gql } from '@apollo/client';
export const CREATE_DISCOUNT = gql`
	mutation Create_individual_discount(
		$type: DiscountType!
		$fee_head_id: ID
		$value: Float!
		$student_id: ID!
		$discount_name: String!
	) {
		create_individual_discount(
			type: $type
			fee_head_id: $fee_head_id
			value: $value
			student_id: $student_id
			discount_name: $discount_name
		) {
			_id
		}
	}
`;

export const CREATE_FINE = gql`
	mutation Create_individual_fine($amount: Float!, $fine_name: String!, $student_id: ID!) {
		create_individual_fine(amount: $amount, fine_name: $fine_name, student_id: $student_id) {
			_id
		}
	}
`;
export const DELETE_INDIVIDUAL_FINE = gql`
	mutation Delete_individual_fine($fine_id: ID!) {
		delete_individual_fine(fine_id: $fine_id)
	}
`;

export const GET_STUDENT = gql`
	query Student($id: String!) {
		student(id: $id) {
			school {
				_id
				name
				email_id
				phone_number
				convenience_fee
				logo_url
			}
			name
			father_name
			school_generated_id
			_id
			category
			class
			dob
			gender
			phone_number
			section
			previous_session_dues
			has_sibling
		}
	}
`;

export const SEND_WHATSAPP_REMINDER_TO_STUDENT = gql`
	mutation Send_reminders($student_id: String!) {
		send_reminder(student_id: $student_id)
	}
`;
