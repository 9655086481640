import { gql } from '@apollo/client';

export const REVERT_CASH_TRANSACTION = gql`
	mutation Revert_cash_transaction($invoice_id: ID!) {
		revert_cash_transaction(invoice_id: $invoice_id) {
			_id
			createdAt
			fee_breakup
			invoice_pdf_link
			status
		}
	}
`;
