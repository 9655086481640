import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

export function NestedSidebarItem({ icon, name, onTap, className, children, path, set_path, open: sidebarOpen }: any) {
	const [open, set_open] = useState(false);
	return (
		<div className={className + ' nested-sidebar-item '}>
			<div
				className={
					'sidebar-item items-center p-3 my-3 rounded-lg cursor-pointer hover:bg-[#6F6AF8] bg-opacity-10 text-white font text-sm flex w-100 ' +
					(sidebarOpen ? ' gap-6' : ' gap-[0.5rem]')
				}
				onClick={() => {
					set_open(!open);
					// onTap();
				}}
			>
				{icon && (
					<div className='icon'>
						<img className='w-3 h-3' src={icon} alt='' />{' '}
					</div>
				)}
				{sidebarOpen && <div className='name'>{name}</div>}
				<i className={'fa-solid fa-angle-up ' + (open ? '' : 'rotate-180')}></i>
			</div>

			<div className='pl-10'>
				{open
					? children.map((c: any, i: any) => {
							return React.cloneElement(c, { key: i, path, set_path, open: sidebarOpen });
					  })
					: null}
			</div>
		</div>
	);
}

export function SidebarItem({ icon, name, onTap, className, to, path, set_path, open }: any) {
	return (
		<Link to={to} className={className}>
			<div
				className={
					'sidebar-item flex items-center gap-6 p-3 my-3 rounded-lg cursor-pointer hover:bg-[#F8FAFB] hover:bg-opacity-10 bg-opacity-80 text-white font text-sm ' +
					(path === to ? 'bg-[#F8FAFB] bg-opacity-[25%]' : '') +
					(open ? ' justify-start' : ' justify-center')
				}
				onClick={() => {
					set_path(to);
					if (onTap) onTap();
				}}
			>
				{icon && (
					<div className='icon'>
						<img className='w-5 h-5' src={icon} alt='' />{' '}
					</div>
				)}
				{open && <div className='name'>{name}</div>}
			</div>
		</Link>
	);
}

export function Sidebar({ children, schoolName, open, setOpen }: any) {
	const [path, set_path] = useState(window.location.pathname);

	const location = useLocation();
	useEffect(() => {
		// conssole.log(location);
		set_path(location.pathname);
	}, [location.pathname]);
	//eslint-disable-next-line
	// const [open, set_open] = useState(true);
	//console.log(schoolName.replace(/.{10}\S*\s+/g, '$&@').split(/\s+@/)[0].length);

	return (
		<div className={'sidebar-container min-h-screen p-5 z-50 flex sticky top-0'}>
			<div
				onMouseOver={() => setOpen(true)}
				onMouseLeave={() => setOpen(false)}
				className={
					`bg-[#6F6AF8] rounded-3xl flex flex-col transition-all duration-500  fixed h-[calc(100vh-40px)]` +
					(open ? ' w-[16rem]' : ' w-20')
				}
			>
				{/* <img src={user.school.logo_url} alt="" /> */}

				<div className='school_name mx-auto font-medium text-[24px] my-10 capitalize  text-white'>
					{open ? (
						<>
							{' '}
							{schoolName.replace(/.{10}\S*\s+/g, '$&@').split(/\s+@/)[0]}
							<h4 className='text-base'>
								{schoolName.slice(schoolName.replace(/.{10}\S*\s+/g, '$&@').split(/\s+@/)[0].length)}
							</h4>{' '}
						</>
					) : (
						''
					)}
				</div>

				<div className='sidebar-items p-3 flex flex-col h-full'>
					{children.map((c: any, i: any) => {
						return React.cloneElement(c, { key: i, path, set_path, open: open });
					})}
				</div>
			</div>
		</div>
	);
}

export default Sidebar;
