import React, { useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Access } from '../../pages/Dashboard/AccessEnum';
import { dashboardContext } from '../../pages/Dashboard/Dashboard';

const Table = ({
	data,
	heading,
	footer,
	description,
	actions,
	className,
	csv_name,
	truncate,
	textAlignMent,
	shadow,
}: any) => {
	const [csv_link, set_csv_link] = React.useState('');
	const refs: any = useRef<any>([]);
	const { user } = useContext(dashboardContext);

	useEffect(() => {
		// console.log({ refs });
		let csvContent =
			'data:text/csv;charset=utf-8,' +
			(data[0].join(',') +
				'\n' +
				refs?.current
					.map((r?: any) =>
						r
							? [].slice.call(r?.children).map((c: any) => {
									return JSON.stringify(c?.innerText).replace('\u20b9', '');
							  })
							: '',
					)
					.map((e: any) => (e ? e.join(',') : ''))
					.join('\n'));
		var encodedUri = encodeURI(csvContent);
		set_csv_link(encodedUri);
	}, [data]);

	// const []

	return (
		<div className='flex flex-col w-full overflow-x-auto'>
			<div className={'table  w-full p-5 rounded-lg my-2 text-edvion_black ' + (shadow && shadow ? shadow : ' shadow')}>
				<div className=' flex  w-full'>
					<div className='w-full'>
						<div className='text-lg font-semibold mx-5 mt-3 mb-2'>{heading}</div>

						<div className='text-sm text-gray-500 mx-5 mb-3'>{description}</div>
					</div>
					<div className={'ml-auto flex-none ' + className}>
						{user && (user.access === Access.SUPER || user.access === Access.ALL_ACCESS) && (
							<a
								download={typeof heading !== 'string' ? csv_name : heading}
								href={csv_link}
								className='focus:outline-none outline-none'
							>
								<button className='ml-auto bg-[#6F6AF8] text-white px-5 py-2 rounded-lg mt-2'>Download CSV</button>
							</a>
						)}

						{actions}
					</div>
				</div>
				<div
					ref={(e) => (refs.current[0] = e)}
					className={`grid grid-cols-${data[0].length} grid-header p-3 font-semibold m-5 bg-[#ECEDFB] rounded-t-lg text-violet-900`}
				>
					{data[0].map((item: any, i: any) => {
						return (
							<div className={'col-span-1 ' + textAlignMent && textAlignMent ? textAlignMent : ' text-center'} key={i}>
								{item}
							</div>
						);
					})}
				</div>
				{data.slice(1).map((row: any, key: any) => {
					const get_grids_class = (len: number) => {
						switch (len) {
							case 1:
								return 'grid-cols-1';
							case 2:
								return 'grid-cols-2';
							case 3:
								return 'grid-cols-3';
							case 4:
								return 'grid-cols-4';
							case 5:
								return 'grid-cols-5';
							case 6:
								return 'grid-cols-6';
							case 7:
								return 'grid-cols-7';
							case 8:
								return 'grid-cols-8';
							case 9:
								return 'grid-cols-9';
							case 10:
								return 'grid-cols-10';
						}
					};
					// console.log({ key });
					return (
						<div
							ref={(e) => (refs.current[key] = e)}
							className={`grid ${get_grids_class(
								data[0].length,
							)} grid-body items-center  p-3 mx-5 my-2 odd:bg-[#ECEDFB] even:bg-[#F8FAFB] odd:border-none even:border border-[#ECEDFB] rounded-lg`}
						>
							{row.map((item: any, i: any) => {
								return (
									<div
										className={
											' col-span-1 ' +
											(truncate === 'false' ? ' ' : ' truncate') +
											(textAlignMent && textAlignMent ? textAlignMent : ' text-center')
										}
										key={i}
									>
										{item}
									</div>
								);
							})}
						</div>
					);
				})}
				{footer}
			</div>
		</div>
	);
};

export default Table;
