import React, { useEffect, useState } from 'react';
import { preventNegativeValues } from '../../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../../helper/preventPasteNegative';

const RangeCard = ({ stop, setStops, stops }: any) => {
	const [stopFee, setStopFee] = useState(0);

	useEffect(() => {
		if (stopFee > 0) {
			setStops([
				...stops.filter((s: any) => {
					if (s.stop_id === stop._id) {
						return false;
					}
					return true;
				}),
				{ fees: stopFee, stop_id: stop._id },
			]);
		}
	}, [stopFee]);

	return (
		<div>
			<div className='flex justify-between gap-4 items-center mt-2'>
				<div className='w-full'>{stop.name}</div>
				<div className='max-w-[8rem]'>
					<input
						type='number'
						name='stopFee'
						defaultValue={stopFee}
						placeholder='enter in ₹ '
						onChange={(e) => {
							setStopFee(Number(e.target.value));
						}}
						min={0}
						onKeyDown={preventNegativeValues}
						onPaste={preventPasteNegative}
						className='rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-[10px] w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs placeholder:text-right'
					/>
				</div>
			</div>
		</div>
	);
};

function StopRateCard({ data, setData, setActiveTab, all_stops, setOtherField }: any) {
	const [stops, setStops] = useState<any>([]);

	return (
		<div className='min-h-[32rem] h-full shadow rounded-md p-4 flex flex-col justify-between'>
			<div>
				<div className='flex justify-between items-center mb-2'>
					<h3 className=' text-lg font-medium text-edvion_black'>Rate Card</h3>
				</div>
				<div className='space-y-4 overflow-hidden overflow-y-auto min-h-[20rem] max-h-[22rem] pr-6'>
					{all_stops?.school?.stops.map((stop: any, index: any) => (
						<RangeCard key={index} stop={stop} stops={stops} setStops={setStops} />
					))}
				</div>
			</div>

			<div className='flex justify-end'>
				<button
					disabled={!stops.length}
					onClick={() => {
						setActiveTab(2);
						setOtherField(true);
						setData({
							...data,
							stops: stops,
						});
					}}
					className='rounded-lg disabled:bg-[#D9D9D978] disabled:text-[#959595] bg-blue-700 text-white px-8 py-1 max-w-fit'
				>
					Next
				</button>
			</div>
		</div>
	);
}

export default StopRateCard;
