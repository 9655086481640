import React, { useState } from 'react';

import Select from '../../../../../../../components/Select/Select';
import Input from '../../../../../../../components/Input/Input';

import Form from '../../../../../../../components/Form/Form';
import { fee_type_string_to_frequency_months } from '../../FeeStructureTab/FeeStructureTab';
import { useMutation } from '@apollo/client';
import { CREATE_STOP_WISE_FEE, CREATE_TRANSPORT_FEE } from '../Querries';
import { TransportFeeType } from '../TransportFee';
import { preventNegativeValues } from '../../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../../helper/preventPasteNegative';
import { toast } from 'react-toastify';

function OtherFields({ set_transport_modal, data: fee_data }: any) {
	const [selectFeeType, setSelectFeeType] = useState({
		name: 'Monthly',
	});

	const [createTransportFee] = useMutation(CREATE_TRANSPORT_FEE);
	const [createStopWiseTransportFee] = useMutation(CREATE_STOP_WISE_FEE);
	return (
		<div className='h-full shadow-lg rounded-md p-4 flex flex-col justify-between'>
			<div>
				<h3 className=' text-lg font-medium text-edvion_black'>Other Fields</h3>
				<Form
					onSubmit={(data: any) => {
						if (fee_data.type === '')
							return toast.error('To generate the transport fee, please provide the required data.');
						if (data['3. Select start date'] >= data['4. Select end date'])
							return toast.error(`start date should not be after end date`);
						if (fee_data.type === TransportFeeType.KM_BASED || fee_data.type === TransportFeeType.SLAB_BASED) {
							createTransportFee({
								variables: {
									frequency_months: fee_type_string_to_frequency_months(data['2. Select Fee Type']),
									name: data['1. Name'],
									start_date: data['3. Select start date'],
									tax: Number(data['5. Tax']),
									type: fee_data.type,
									slab_details: {
										breakpoints: fee_data?.breakPoint.map((i: any) => Number(i)),
										fees: fee_data?.fees.map((i: any) => Number(i)),
									},
									end_date: data['4. Select end date'],
								},
							});
							toast.success(
								`${
									fee_data.type === TransportFeeType.KM_BASED
										? 'Km Based'
										: fee_data.type === TransportFeeType.SLAB_BASED
										? 'Slab Based'
										: ''
								} fee is created`,
							);
							window.location.reload();
						}
						if (fee_data.type === TransportFeeType.STOP_BASED) {
							createStopWiseTransportFee({
								variables: {
									frequency_months: fee_type_string_to_frequency_months(data['2. Select Fee Type']),
									name: data['1. Name'],
									start_date: data['3. Select start date'],
									tax: Number(data['5. Tax']),
									type: fee_data.type,
									stops: fee_data?.stops,
									end_date: data['4. Select end date'],
								},
							});
							toast.success(`Stop Based fee is created`);
							window.location.reload();
						}
						set_transport_modal(false);
					}}
					className='mt-4 space-y-8'
				>
					<Input type='text' required name='1. Name' labelStyle='text-[#6F6AF8] text-sm' add_error={() => {}} />
					<Select
						selected={selectFeeType}
						setSelected={setSelectFeeType}
						labelStyle='text-[#6F6AF8] text-sm'
						label='2. Select Fee Type'
						options={['Monthly', 'Biomonthly', 'Quarterly', 'Half Yearly', 'One Time'].map((c) => {
							return { name: c };
						})}
					/>
					<Input
						type='date'
						labelStyle='text-[#6F6AF8] text-sm'
						name='3. Select start date'
						add_error={() => {}}
						min='1990-01-01'
						max={`${new Date().getFullYear()}-${
							new Date().getMonth() > 9 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1)
						}-${new Date().getDate() > 9 ? new Date().getDate() + 1 : '0' + new Date().getDate()}`}
						required
					/>
					<Input
						type='date'
						labelStyle='text-[#6F6AF8] text-sm'
						name='4. Select end date'
						add_error={() => {}}
						required
					/>
					<Input
						postfix='%'
						type='number'
						labelStyle='text-[#6F6AF8] text-sm'
						name='5. Tax'
						add_error={() => {}}
						required
						min={0}
						onKeyDown={preventNegativeValues}
						onPaste={preventPasteNegative}
					/>
					<div className='flex justify-end mt-4'>
						<button className='rounded-lg bg-blue-700 text-white  px-8 py-1 max-w-fit'>Create</button>
					</div>
				</Form>
			</div>
		</div>
	);
}

export default OtherFields;
