import React, { useEffect, useState } from 'react';
import RadioInput from '../../../../../../../components/RadioInput/RadioInput';
import Input from '../../../../../../../components/Input/Input';
import Form from '../../../../../../../components/Form/Form';
import { preventNegativeValues } from '../../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../../helper/preventPasteNegative';
import CheckboxInput from '../../../../../../../components/CheckboxInput/CheckboxInput';
import { boolean_map_to_array } from '../../FeeStructureTab/FeeStructureTab';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_ABSOLUTE_DISCOUNT } from '../Queries';
import { CREATE_TAG_DISCOUNT, GET_TAGS } from '../../../../SchoolTags/Querries';
import  { NewTags } from '../../../../../../../components/Tags/Tags';
import { toast } from 'react-toastify';


export enum DiscountType {
	RECURRING_PERCENTAGE_OF_FEE_HEAD = 'RECURRING_PERCENTAGE_OF_FEE_HEAD',
	RECURRING_PERCENTAGE_OF_INSTALLMENT = 'RECURRING_PERCENTAGE_OF_INSTALLMENT',
	ABSOLUTE_ONE_TIME = 'ABSOLUTE_ONE_TIME',
}
function CreateGroupDiscount({
	set_discount_type,
	discount_type,
	createDiscount,
	getDiscounts,
	set_get_discounts,
	set_show_modal,
	classes,
}: any) {
	const [fee_type, set_fee_type] = React.useState('');
	const [category, setCategory] = React.useState<any>([]);
	const [_classes, setClasses] = React.useState<any>([]);
	const [gender, setGender] = React.useState<any>([]);
	const [fee_heads, set_fee_heads] = React.useState<any>([]);
	const [fee_head, set_fee_head] = React.useState<any>({});
	const [isFeehead, setIsFeeHead] = React.useState<any>(false);
	const [type_value, set_type_value] = useState(0);
	const [discountType, setDiscountType] = useState<any>('');
	const [isTagDiscount,setIsTagDiscount]=React.useState<boolean>(false)
	
    const [selectedTags,setSelectedTags]=useState([])
	const [createTagDiscount, { data: tagDiscountData, loading:tagDiscountLoading, error:tagDiscountError }] = useMutation(CREATE_TAG_DISCOUNT);
	const [createAbsoluteDiscount, { data: result, loading, error }] = useMutation(CREATE_ABSOLUTE_DISCOUNT);
	const {data:tagsData,loading:tagsLoading,error:tagsError}=useQuery(GET_TAGS);
	
	const getFeeHeadForGroup = async () => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
		myHeaders.append('Content-Type', 'application/json');

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};

		const res = await fetch(
			process.env.REACT_APP_BACKEND_URL + `/api/fee-config/fee-head-group?class=${_classes}`,
			requestOptions,
		);
		if (res.ok) {
			return await res.json();
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
		}
	};

	React.useEffect(() => {
		(async () => {
			if (isFeehead === true) {
				if (_classes.length) {
					set_fee_heads(await getFeeHeadForGroup());
				}
			}
		})();
	}, [_classes]);

	return (
		<div className='create-fee-head capitalize mt-5 w-full'>
			<div className='w-full flex justify-center items-center'>
				<RadioInput
					onChange={(n: any, v: any) => {
						set_discount_type(n);
						setDiscountType(() => {
							if (v === 'Absolute One Time') return 'absolute-one-time';
							if (v === 'Recurring Percentage') return 'recurring-percentage';
						});
						setCategory([]);
						setClasses([]);
						setGender([]);
					}}
					color='text-violet-500'
					add_error={() => {}}
					options={['Absolute One Time', 'Recurring Percentage']}
					required
				/>
			</div>
			{discountType === 'absolute-one-time' && (
				<Form
					onSubmit={async (data: any) => {
						try {
							if(isTagDiscount===true){
								if(selectedTags.length===0){
                                  toast.error("Please select atleast one tag")
								}
								//console.log(selectedTags)
								await createTagDiscount({
									variables: {
										discount_name: data['Discount Name'],
										value: Number(data['Value']),
										class: _classes,
										gender:gender,
										type: DiscountType.ABSOLUTE_ONE_TIME,
										tags: tagsData?.tags?.filter((tag:any)=> {
											return selectedTags.some((selectedTag:any)=>{
												return (String(tag.tagCode)===String(selectedTag))
											})
										}).map((tag:any)=>{
											return tag._id;
										})
									},
								});
							}
							else{
								await createAbsoluteDiscount({
									variables: {
										category: category,
										gender: gender,
										discount_name: data['Discount Name'],
										value: Number(data['Value']),
										class: _classes,
										type: DiscountType.ABSOLUTE_ONE_TIME,
									},
								});
							}
							set_get_discounts(await getDiscounts());
							set_show_modal(false);
						} catch (err) {
							console.log('error', err);
						}
					}}
					className='grid grid-cols-2 gap-5'
				>
					<Input add_error={() => {}} name='Discount Name' type='text' placeholder='Enter Name' required />
					<Input
						add_error={() => {}}
						name='Value'
						type='number'
						placeholder='Enter Value'
						prefix={'₹'}
						onKeyDown={preventNegativeValues}
						onPaste={preventPasteNegative}
						min={0}
						required
					/>
					<div>
						<label className='mr-2 text-sm'>Is Tag Discount</label>
						<input checked={isTagDiscount} type="checkbox" onChange={(event:any)=>setIsTagDiscount(event.target.checked)} />
						{isTagDiscount ?
					<div className='text-sm my-3 '
					style={{textTransform:'none'}}
					> Select Tags
						<NewTags tags={tagsData?.tags} setSelectedTags={setSelectedTags} />
					</div>
					:<></>}
					</div>
					
					
					<CheckboxInput
						onChange={(e: any) => {
							setGender(boolean_map_to_array(e));
						}}
						className='capitalize'
						name='Gender'
						options={['male', 'female', 'others']}
						required
					/>
					{!isTagDiscount?<CheckboxInput
						name='Category'
						options={['general', 'st', 'sc', 'obc'].map((e) => {
							if (e === 'general') return 'General';
							if (e === 'sc') return 'SC';
							if (e === 'st') return 'ST';
							if (e === 'obc') return 'OBC';
						})}
						onChange={(e: any) => {
							setCategory(boolean_map_to_array(e));
						}}
					/>:<></>}
					
					<CheckboxInput
						onChange={(e: any) => {
							setClasses(boolean_map_to_array(e));
						}}
						name='Class'
						options={classes}
						required
					/>
					<div className='col-span-2 flex justify-center items-center'>
						<button
							disabled={!(isTagDiscount?( _classes.length && gender.length && selectedTags.length):(category.length && _classes.length && gender.length))}
							className={
								' ml-auto mx-auto max-w-[10rem] w-full inline-flex disabled:bg-[#6F6AF8] disabled:bg-opacity-70 bg-[#6F6AF8] justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow hover:bg-[#6F6AF8]  sm:w-auto '
							}
						>
							Create Discount
						</button>
					</div>
				</Form>
			)}
			{discountType === 'recurring-percentage' && (
				<Form
					className='grid grid-cols-2 gap-5'
					onSubmit={async (data: any) => {
						try {
							if (isFeehead === true) {
								if(isTagDiscount===true){
									if(selectedTags.length===0){
										toast.error("Please select atleast one tag")
									  }
									await createTagDiscount({
										variables: {
											
											discount_name: data['Discount Name'],
											value: Number(data['Value']),
											class: _classes,
											gender:gender,
											fee_head_id: fee_head[0]?._id,
											type: DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD,
											tags:tagsData?.tags?.filter((tag:any)=> {
												return selectedTags.some((selectedTag:any)=>{
													return (String(tag.tagCode)===String(selectedTag))
												})
											}).map((tag:any)=>{
												return tag._id;
											})
										},
									});
								}
								else{
									await createAbsoluteDiscount({
										variables: {
											category: category,
											gender: gender,
											discount_name: data['Discount Name'],
											value: Number(data['Value']),
											class: _classes,
											fee_head_id: fee_head[0]?._id,
											type: DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD,
										},
									});
								}
								
							} else {
								if(isTagDiscount===true){
									if(selectedTags.length===0){
										toast.error("Please select atleast one tag")
									  }
									await createTagDiscount({
										variables: {
											
											discount_name: data['Discount Name'],
											value: Number(data['Value']),
											class: _classes,
											gender:gender,
											type: DiscountType.RECURRING_PERCENTAGE_OF_INSTALLMENT,
											tags:tagsData?.tags?.filter((tag:any)=> {
												return selectedTags.some((selectedTag:any)=>{
													return (String(tag.tagCode)===String(selectedTag))
												})
											}).map((tag:any)=>{
												return tag._id;
											})
										},
									});
								}
								else{
									await createAbsoluteDiscount({
										variables: {
											category: category,
											gender: gender,
											discount_name: data['Discount Name'],
											value: Number(data['Value']),
											class: _classes,
											type: DiscountType.RECURRING_PERCENTAGE_OF_INSTALLMENT,
										},
									});
								}
								
							}
							set_get_discounts(await getDiscounts());
							set_show_modal(false);
						} catch (err) {
							console.log('errror', err);
						}
					}}
				>
					<Input add_error={() => {}} name='Discount Name' type='text' placeholder='Enter Name' required />

					<Input
						add_error={() => {}}
						name='Value'
						type='number'
						placeholder='Enter Value'
						prefix={'%'}
						onKeyDown={preventNegativeValues}
						onPaste={preventPasteNegative}
						maxAmount={100}
						min={0}
						required
					/>
						<div className='items-center my-3'>
						<label className='mr-2 text-sm'>Is Tag Discount</label>
						<input checked={isTagDiscount} type="checkbox" onChange={(event:any)=>{setIsTagDiscount(event.target.checked);}} />
						{isTagDiscount ? <div
						style={{textTransform:'none'}}
						className='text-sm my-2'> Select Tag
						<NewTags tags={tagsData?.tags} setSelectedTags={setSelectedTags} />
					</div>:<></>}
					</div>
					
					
					<RadioInput
						name='Discount'
						onChange={(n: any) => {
							setIsFeeHead(() => {
								if (n === 'Feehead') {
									return true;
								}
								if (n === 'Installment') {
									return false;
								}
							});
						}}
						add_error={() => {}}
						options={['Feehead', 'Installment']}
						required
					/>
					<CheckboxInput
						onChange={(e: any) => {
							setGender(boolean_map_to_array(e));
						}}
						className='capitalize'
						name='Gender'
						options={['male', 'female', 'others']}
						required
					/>
					{!isTagDiscount?<CheckboxInput
						name='Category'
						options={['general', 'st', 'sc', 'obc'].map((e) => {
							if (e === 'general') return 'General';
							if (e === 'sc') return 'SC';
							if (e === 'st') return 'ST';
							if (e === 'obc') return 'OBC';
						})}
						onChange={(e: any) => {
							setCategory(boolean_map_to_array(e));
						}}
						
					/>:<></>}
					
					<CheckboxInput
						onChange={(e: any) => {
							setClasses(boolean_map_to_array(e));
						}}
						name='Class'
						options={classes}
						required
					/>

					{isFeehead === true ? (
						<>
							{fee_heads?.length > 0 ? (
								<RadioInput
									name='Select Fee Head'
									onChange={(e: any, v: any) => {
										set_fee_head(
											Object.values(fee_heads).filter((fee: any) => {
												return fee.name === v;
											}),
										);
									}}
									add_error={() => {}}
									options={Object.values(fee_heads).map((fee: any) => {
										return fee.name;
									})}
									required
								/>
							) : (
								<></>
							)}
						</>
					) : (
						<></>
					)}
					<div className='col-span-2 flex justify-center items-center'>
						<button
							disabled={!(isTagDiscount?( _classes.length && gender.length && selectedTags.length):(category.length && _classes.length && gender.length))}
							className={
								' ml-auto mx-auto max-w-[10rem] w-full inline-flex disabled:bg-[#6F6AF8] disabled:bg-opacity-70 bg-[#6F6AF8] justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow hover:bg-[#6F6AF8]  sm:w-auto '
							}
						>
							Create Discount
						</button>
					</div>
				</Form>
			)}
			{/* <Form
				className='w-full grid grid-cols-2 gap-5'
				onSubmit={async (data: any) => {
					let discount: any = {};
					discount_type === 'Recurring'
						? (discount = {
								value: data['Value'],
								name: data['Discount Name'],
								type: fee_type?.toUpperCase(),
								group_category: category,
								group_gender: gender,
								group_class: _classes,
								discount_type: discount_type === 'Recurring' ? 'RECURRING' : '',
								fee_head: fee_head[0]._id,
						  })
						: (discount = {
								value: data['Value'],
								name: data['Discount Name'],
								type: fee_type?.toUpperCase(),
								group_category: category,
								group_gender: gender,
								group_class: _classes,
								discount_type: discount_type === 'One Time' ? 'ONE_TIME' : '',
						  });
					await createDiscount(discount);
					set_get_discounts(await getDiscounts());
					set_show_modal(false);
				}}
			>
				<Input add_error={() => {}} name='Discount Name' type='text' placeholder='Enter Name' required />

				<RadioInput
					name='Frequency'
					onChange={(n: any) => {
						set_discount_type(n);
					}}
					add_error={() => {}}
					options={['Recurring', 'One Time']}
					required
				/>

				<RadioInput
					add_error={() => {}}
					name='Fee Type'
					onChange={(v: any) => {
						set_fee_type(v);
					}}
					options={discount_type === 'Recurring' ? ['Absolute', 'Percentage'] : ['Absolute']}
					required
				/>
				<Input
					add_error={() => {}}
					name='Value'
					type='number'
					placeholder='Enter Value'
					prefix={fee_type === 'Absolute' ? '₹' : fee_type !== undefined ? '%' : ''}
					value={type_value}
					onKeyDown={preventNegativeValues}
					onPaste={preventPasteNegative}
					min={0}
					maxAmount={fee_type === 'Percentage' && 100}
					onChange={(n: any, e: any) => {
						set_type_value(n);
					}}
					required
				/>

				<CheckboxInput
					onChange={(e: any) => {
						setGender(boolean_map_to_array(e));
					}}
					className='capitalize'
					name='Gender'
					options={['male', 'female', 'others']}
					required
				/>
				<CheckboxInput
					name='Category'
					options={['general', 'st', 'sc', 'obc'].map((e) => {
						if (e === 'general') return 'General';
						if (e === 'sc') return 'SC';
						if (e === 'st') return 'ST';
						if (e === 'obc') return 'OBC';
					})}
					onChange={(e: any) => {
						setCategory(boolean_map_to_array(e));
					}}
					required
				/>

				<CheckboxInput
					onChange={(e: any) => {
						setClasses(boolean_map_to_array(e));
					}}
					name='Class'
					options={classes}
					required
				/>

				{discount_type === 'Recurring' ? (
					<>
						{fee_heads?.length > 0 ? (
							<RadioInput
								name='Select Fee Head'
								onChange={(e: any, v: any) => {
									set_fee_head(
										Object.values(fee_heads).filter((fee: any) => {
											console.log(fee);

											return fee.name === v;
										}),
									);
								}}
								add_error={() => {}}
								options={Object.values(fee_heads).map((fee: any) => {
									return fee.name;
								})}
								required
							/>
						) : (
							<div></div>
						)}
					</>
				) : (
					<div></div>
				)}
				<div className='col-span-2 flex justify-center items-center'>
					<button
						disabled={fee_heads.length <= 0}
						className={
							' ml-auto mx-auto max-w-[10rem] w-full inline-flex bg-[#6F6AF8] justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow hover:bg-[#6F6AF8]  sm:w-auto ' +
							(fee_heads.length <= 0 ? 'disabled:bg-violet-400' : ' bg-[#6F6AF8]')
						}
					>
						Create Discount
					</button>
				</div>
			</Form> */}
		</div>
	);
}

export default CreateGroupDiscount;
