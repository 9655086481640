import React, { useContext, useEffect, useState } from 'react';

import dummypicIcon from '../../../assets/dummy_stu_profile.svg';
import { Access } from '../../PortalAccess/AccessEnum';
import { dashboardContext } from '../../../Dashboard';
import { useParams } from 'react-router-dom';
import { get_student } from '../pages/studentDetails/StudentDetails';

// @ts-ignore

export function formatDate(date: Date) {
	var d = new Date(date),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [day, month, year].join('/');
}
const BasicDetails = ({ setEditStudent, isEdit, student }: any) => {
	const { user } = useContext(dashboardContext);

	return (
		<div>
			<div className='flex justify-between  items-center'>
				<div className='text-xl font-medium'>Basic Details</div>
				{isEdit &&
					(user.access === Access.SUPER || user.access === Access.ALL_ACCESS || user.access === Access.MANAGEMENT) && (
						<div onClick={(e) => setEditStudent(true)} className='cursor-pointer'>
							<i className='fa-regular fa-edit mr-2 text-[#959595] text-[24px]'></i>
						</div>
					)}
			</div>
			{!student && <div className='bg-gray-400 animate-pulse p-4 w-full mt-6 flex h-20 shadow rounded-md '></div>}
			{student && (
				<div className=' p-4 w-full mt-6 flex  shadow rounded-md items-center text-center'>
					<div className='text-left border-r-[0.3px] border-gray'>
						<div className=' items-center flex  text-violet-500  '>
							<div className='w-20 h-20'>
								<img src={dummypicIcon} alt='' />
							</div>
							<div className=' text-left px-4'>
								<div className='text-xl capitalize'>{student?.name || student[0]?.name}</div>
								<div>{student?.school_generated_id || student[0]?.school_generated_id}</div>
							</div>
						</div>
					</div>

					<div className='w-full grid  grid-cols-5 gap-2'>
						<div className='  p-2 border-r-[0.3px] border-gray'>
							<div className='text-base text-[#959595]'>Class & Section</div>
							<div className='text-base text-edvion_black capitalize'>
								{student?.class || student[0]?.class} {student.section || student[0]?.section}
							</div>
						</div>

						<div className='p-2 border-r-[0.3px] border-gray'>
							<div className=' text-base text-[#959595]'>Mobile Number</div>
							<div className='text-base text-edvion_black'>{student.phone_number || student[0]?.phone_number[0]}</div>
						</div>
						<div className='p-2 border-r-[0.3px] border-gray'>
							<div className=' text-base text-[#959595]'>Gender</div>
							<div className='text-base  text-edvion_black capitalize'>{student.gender || student[0]?.gender}</div>
						</div>
						<div className=' p-2 border-r-[0.3px] border-gray'>
							<div className=' text-base text-[#959595]'>DOB</div>
							<div className='text-base  text-edvion_black '>
								{!student[0]?.dob ? new Date(Number(student.dob)).toLocaleDateString() : formatDate(student[0]?.dob)}
							</div>
						</div>
						<div className=' p-2 '>
							<div className=' text-base text-[#959595]'>Category</div>
							<div
								className={
									'text-base text-edvion_black ' +
									((student?.category || student[0]?.category) === 'general' ? ' capitalize' : ' uppercase')
								}
							>
								{student?.category || student[0]?.category}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default BasicDetails;
