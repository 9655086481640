import React, { useState, useContext } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import LoadingIcon from '../../../../../../assets/loading-purple.svg';

import Input from '../../../../../../components/Input/Input';

import { Access } from '../../../PortalAccess/AccessEnum';

import { dashboardContext } from '../../../../Dashboard';
import Modal from '../../../../../../components/Modal/Modal';
import Form from '../../../../../../components/Form/Form';
import { preventNegativeValues } from '../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../helper/preventPasteNegative';

const ParentsDetails = ({ setParentsSelected, getStudent, updateStudent }: any) => {
	const [loader, setLoader] = useState(false);
	const { id } = useParams();
	const { user } = useContext(dashboardContext);

	const [student, setStudent] = useState<any>({});
	const [show_modal, set_show_modal] = useState(false);

	useEffect(() => {
		setParentsSelected(true);
		return () => {
			setParentsSelected(false);
		}; // eslint-disable-next-line
	}, []);

	return (
		<LoadAndRender
			promise={async () => {
				setStudent(await getStudent(id));
			}}
			height='none'
		>
			{loader ? (
				<div className='m-auto'>
					<img src={LoadingIcon} alt='Loading...' />
				</div>
			) : (
				<div className='ml-4 mt-4 mr-2 w-full border-gray-200 rounded-md shadow p-5 mx-5'>
					<Modal className='max-w-7xl w-full ' open={show_modal} setOpen={set_show_modal}>
						<Form
							className='grid grid-cols-4 gap-5'
							onSubmit={async (data: any) => {
								let studentData = {
									father_name: data['Father Name'] || student[0]?.father_name,
									father_contact_number: data['Father Contact Number'] || student[0]?.father_contact_number,
									father_email_id: data['Father Email Id'] || student[0]?.father_email_id,
									father_education_qualification:
										data['Father Education Qualification'] || student[0]?.father_education_qualification,
									father_occupation: data['Father Occupation'] || student[0]?.father_occupation,
									father_designation: data['Father Designation'] || student[0]?.father_designation,
									father_annual_income: data['Father Income'] || student[0]?.father_annual_income,
									mother_name: data['Mother Name'] || student[0]?.mother_name,
									mother_contact_number: data['Mother Contact Number'] || student[0]?.mother_contact_number,
									mother_email_id: data['Mother Email Id'] || student[0]?.mother_email_id,
									mother_education_qualification:
										data['Mother Education Qualification'] || student[0]?.mother_education_qualification,
									mother_occupation: data['Mother Occupation'] || student[0]?.mother_occupation,
									mother_designation: data['Mother Designation'] || student[0]?.mother_designation,
									mother_annual_income: data['Mother Income'] || student[0]?.mother_annual_income,
									guardian_name: data['Guardian Name'] || student[0]?.guardian_name,
									guardian_contact_number: data['Guardian Contact Number'] || student[0]?.guardian_contact_number,
									guardian_email_id: data['Guardian Email Id'] || student[0]?.guardian_email_id,
									guardian_education_qualification:
										data['Guardian Education Qualification'] || student[0]?.guardian_education_qualification,
									guardian_occupation: data['Guardian Occupation'] || student[0]?.guardian_occupation,
									guardian_designation: data['Guardian Designation'] || student[0]?.guardian_designation,
									guardian_annual_income: data['Guardian Income'] || student[0]?.guardian_annual_income,
									guardian_relationship: data['Guardian Relationship'] || student[0]?.guardian_relationship,
								};
								setLoader(true);
								await updateStudent(studentData, id);
								setStudent(await getStudent(id));
								setLoader(false);
								set_show_modal(false);
							}}
						>
							<Input value={student[0]?.father_name} type='text' name='Father Name' add_error={() => {}} />
							<Input
								value={student[0]?.father_contact_number}
								type='number'
								maxLength={10}
								onKeyDown={preventNegativeValues}
								onPaste={preventPasteNegative}
								min={0}
								name='Father Contact Number'
								add_error={() => {}}
							/>
							<Input value={student[0]?.father_email_id} type='email' name='Father Email Id' add_error={() => {}} />
							<Input
								value={student[0]?.father_education_qualification}
								type='text'
								name='Father Education Qualification'
								add_error={() => {}}
							/>
							<Input value={student[0]?.father_occupation} type='text' name='Father Occupation' add_error={() => {}} />
							<Input
								value={student[0]?.father_designation}
								type='text'
								name='Father Designation'
								add_error={() => {}}
							/>
							<Input
								value={student[0]?.father_annual_income}
								type='number'
								onKeyDown={preventNegativeValues}
								onPaste={preventPasteNegative}
								min={0}
								name='Father Income'
								add_error={() => {}}
							/>
							<Input value={student[0]?.mother_name} type='text' name='Mother Name' add_error={() => {}} />
							<Input
								value={student[0]?.mother_contact_number}
								type='number'
								maxLength={10}
								onKeyDown={preventNegativeValues}
								onPaste={preventPasteNegative}
								min={0}
								name='Mother Contact Number'
								add_error={() => {}}
							/>
							<Input value={student[0]?.mother_email_id} type='email' name='Mother Email Id' add_error={() => {}} />
							<Input
								value={student[0]?.mother_education_qualification}
								type='text'
								name='Mother Education Qualification'
								add_error={() => {}}
							/>
							<Input value={student[0]?.mother_occupation} type='text' name='Mother Occupation' add_error={() => {}} />
							<Input
								value={student[0]?.mother_designation}
								type='text'
								name='Mother Designation'
								add_error={() => {}}
							/>
							<Input
								value={student[0]?.mother_annual_income}
								type='number'
								onKeyDown={preventNegativeValues}
								onPaste={preventPasteNegative}
								min={0}
								name='Mother Income'
								add_error={() => {}}
							/>
							<Input value={student[0]?.guardian_name} type='text' name='Guardian Name' add_error={() => {}} />
							<Input
								value={student[0]?.guardian_contact_number}
								type='number'
								maxLength={10}
								onKeyDown={preventNegativeValues}
								onPaste={preventPasteNegative}
								min={0}
								name='Guardian Contact Number'
								add_error={() => {}}
							/>
							<Input value={student[0]?.guardian_email_id} type='email' name='Guardian Email Id' add_error={() => {}} />
							<Input
								value={student[0]?.guardian_education_qualification}
								type='text'
								name='Guardian Education Qualification'
								add_error={() => {}}
							/>
							<Input
								value={student[0]?.guardian_occupation}
								type='text'
								name='Guardian Occupation'
								add_error={() => {}}
							/>
							<Input
								value={student[0]?.guardian_designation}
								type='text'
								name='Guardian Designation'
								add_error={() => {}}
							/>
							<Input
								value={student[0]?.guardian_annual_income}
								type='number'
								onKeyDown={preventNegativeValues}
								onPaste={preventPasteNegative}
								min={0}
								name='Guardian Income'
								add_error={() => {}}
							/>
							<div className='col-span-4 w-full flex'>
								{(user.access === Access.SUPER ||
									user.access === Access.ALL_ACCESS ||
									user.access === Access.MANAGEMENT) && (
									<button
										className={`bg-[#6F6AF8]
							 text-white rounded-lg   py-2 px-10 mx-auto max-w-[15rem] w-full `}
									>
										Update
									</button>
								)}
							</div>
						</Form>
					</Modal>
					<div className='flex justify-between items-center mb-2'>
						<h2 className='text-md text-violet-500 text-lg'>Parents Details</h2>
						<i
							className='fa-regular fa-edit mr-2 text-[#959595] text-[20px] cursor-pointer'
							onClick={() => set_show_modal(!show_modal)}
						></i>
					</div>
					<div className='grid grid-cols-3 gap-4 w-full max-h-[33rem] overflow-y-auto h-full'>
						<div className='col-span-3 -mb-3'>
							<h2 className='text-md text-edvion_black text-lg'>Father Details</h2>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Father Name</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.father_name || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Father Contact</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.father_contact_number || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Father Email Id</div>
							<div className='text-base text-[#1E1B59lowercaseze'>{student[0]?.father_email_id || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Father Education Qualification</div>
							<div className='text-base text-edvion_black capitalize'>
								{student[0]?.father_education_qualification || 'NA'}
							</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Father Occupation</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.father_occupation || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Father Designation</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.father_designation || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Father Annual Income</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.father_annual_income || 'NA'}</div>
						</div>
						<div className='col-span-3 -mb-3'>
							<h2 className='text-md text-edvion_black text-lg'>Mother Details</h2>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Mother Name</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.mother_name || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Mother Contact</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.mother_contact_number || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Mother Email Id</div>
							<div className='text-base text-[#1E1B59lowercaseze'>{student[0]?.mother_email_id || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Mother Education Qualification</div>
							<div className='text-base text-edvion_black capitalize'>
								{student[0]?.mother_education_qualification || 'NA'}
							</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Mother Occupation</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.mother_occupation || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Mother Designation</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.mother_designation || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Mother Annual Income</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.mother_annual_income || 'NA'}</div>
						</div>
						<div className='col-span-3 -mb-3'>
							<h2 className='text-md text-edvion_black text-lg'>Guardian Details</h2>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Guardian Name</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.guardian_name || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Guardian Contact</div>
							<div className='text-base text-edvion_black capitalize'>
								{student[0]?.guardian_contact_number || 'NA'}
							</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Guardian Email Id</div>
							<div className='text-base text-edvion_black lowercase'>{student[0]?.guardian_email_id || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Guardian Education Qualification</div>
							<div className='text-base text-edvion_black capitalize'>
								{student[0]?.guardian_education_qualification || 'NA'}
							</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Guardian Occupation</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.guardian_occupation || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Guardian Designation</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.guardian_designation || 'NA'}</div>
						</div>
						<div className='p-2 w-full'>
							<div className=' text-base text-[#959595]'>Guardian Annual Income</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.guardian_annual_income || 'NA'}</div>
						</div>
					</div>
				</div>
			)}
		</LoadAndRender>
	);
};

export default ParentsDetails;
