import React, { useState } from 'react';
import FeePerKmTab from './Tabs/FeePerKmTab';
import FeePerSlabTab from './Tabs/FeePerSlabTab';
import FeePerStopTab from './Tabs/FeePerStopTab';

export enum TransportFeeType {
	SLAB_BASED = 'SLAB_BASED',
	KM_BASED = 'KM_BASED',
	STOP_BASED = 'STOP_BASED',
}
function TransportFee({ set_transport_modal, set_fee_heads }: any) {
	const [activeTab, setActiveTab] = useState(1);
	const [otherField, setOtherField] = useState(false);
	const handleTabClick = (tab: any) => {
		setActiveTab(tab);
	};
	return (
		<div>
			<div className='flex w-full justify-between  items-center'>
				<button
					className={`${
						activeTab === 1
							? 'text-[#6F6AF8]  border-b-4 border-[#6F6AF8]'
							: ' text-edvion_black border-b border-b-1 border-[#00000080]'
					} px-4 py-2 w-full`}
					onClick={() => {
						setOtherField(false);
						handleTabClick(1);
					}}
				>
					Fee Per km
				</button>
				<button
					className={`${
						activeTab === 2
							? 'text-[#6F6AF8]  border-b-4 border-[#6F6AF8]'
							: ' text-edvion_black border-b border-b-1 border-[#00000080]'
					} px-4 py-2 w-full`}
					onClick={() => {
						setOtherField(false);
						handleTabClick(2);
					}}
				>
					Fee Per Slab
				</button>
				<button
					className={`${
						activeTab === 3
							? 'text-[#6F6AF8]  border-b-4 border-[#6F6AF8]'
							: ' text-edvion_black border-b border-b-1 border-[#00000080]'
					} px-4 py-2 w-full`}
					onClick={() => {
						setOtherField(false);
						handleTabClick(3);
					}}
				>
					Fee Per Stop
				</button>
			</div>
			<div className='w-full'>
				{activeTab === 1 && (
					<FeePerKmTab
						otherField={otherField}
						setOtherField={setOtherField}
						set_transport_modal={set_transport_modal}
						set_fee_heads={set_fee_heads}
					/>
				)}
				{activeTab === 2 && (
					<FeePerSlabTab
						otherField={otherField}
						setOtherField={setOtherField}
						set_transport_modal={set_transport_modal}
						set_fee_heads={set_fee_heads}
					/>
				)}
				{activeTab === 3 && (
					<FeePerStopTab
						otherField={otherField}
						setOtherField={setOtherField}
						set_transport_modal={set_transport_modal}
						set_fee_heads={set_fee_heads}
					/>
				)}
			</div>
		</div>
	);
}

export default TransportFee;
