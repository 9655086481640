import React, { useEffect, useState } from 'react';
import payment_susscessfull from '../../../../assets/payment_success.svg';
import { Link, useParams } from 'react-router-dom';
import LoadAndRender from '../../../../components/LoadAndRender/LoadAndRender';

function PaymentSuccess() {
	let { id } = useParams();
	const [data, setData] = useState<any>([]);
	const [seconds, setSeconds] = useState(5);

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds !== 0) {
				setSeconds((prevSeconds) => prevSeconds - 1);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [seconds]);
	if (seconds === 0) window.location.replace('/fee_collection/transactions');
	const getInvoice = async () => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};
		return await (await fetch(process.env.REACT_APP_BACKEND_URL + `/payment/invoice/${id}`, requestOptions)).json();
	};

	return (
		<LoadAndRender
			className=' h-full w-full overflow-hidden'
			promise={async () => {
				setData(await getInvoice());
			}}
		>
			<div className='flex h-full justify-between w-full z-50 bg-white  items-center p-4'>
				<div className='max-w-4xl w-full mx-auto rounded-lg shadow border px-4 py-2'>
					<div className='w-full flex justify-center items-center my-4'>
						<img src={payment_susscessfull} alt='payment_susscessfull' />
					</div>

					<h3 className='text-black text-3xl font-medium text-center'>Payment Successful!</h3>
					<p className='text-center my-8 text-edvion_black md:text-2xl text-xl font-medium'>
						You have successfully paid your child’s fees. The fee receipt has also been sent to the registered Whatsapp
						number.
					</p>
					<p className='text-center  text-edvion_black text-md font-medium'>Order ID: {id}</p>
					<p className='text-center mt-4 mb-8 text-edvion_black text-md font-medium'>
						Amount: ₹{data?.transaction?.amount.toLocaleString('hi')}
					</p>

					<Link to='/fee_collection/transactions'>
						<p className='text-center text-gray-500 my-4'>Go back to Transaction page</p>
					</Link>
					<p className='text-xs text-center font-medium text-[#959595] my-4'>Redirect In {seconds} Seconds</p>
				</div>
			</div>
		</LoadAndRender>
	);
}

export default PaymentSuccess;
