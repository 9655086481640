import React from 'react';
import { Link } from 'react-router-dom';
import extendIcon from '../assets/extend.svg';
const DisplayCard = ({
	icon,
	value,
	label,
	denominator,
	to,
}: {
	icon: string;
	value: string;
	label: string;
	denominator?: string;
	to?: string;
}) => {
	const className =
		' flex justify-between shadow w-2/5  text-edvion_black rounded-[8px] transform transition duration-500 hover:scale-105';
	const toRender = (
		<div className='m-4 flex flex-col w-full'>
			<div className='flex justify-between items-center w-full'>
				<img src={icon} className='h-8' alt='' />
				<div className='pt-[18px] pr-[18px] text-blue-800 text-2xl font-thin cursor-pointer'>
					{to ? <img src={extendIcon} alt='' /> : ''}
				</div>
			</div>
			<div className='my-3 text-4xl text-green-400 font-semibold'>
				{value && value ? value : 0}
				{denominator && <span className='text-edvion_black font-medium text-3xl'>{'/' + denominator}</span>}
			</div>

			<div className='mt-auto text-base text-grey-400 font-normal'>{label}</div>
		</div>
	);
	if (to) {
		return (
			<Link to={to} className={className}>
				{toRender}
			</Link>
		);
	} else {
		return <div className={className}>{toRender}</div>;
	}
};

export default DisplayCard;
