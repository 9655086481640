import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { studentContext } from '../../StudentProfile';
import Modal from '../../../../../../components/Modal/Modal';
import Form from '../../../../../../components/Form/Form';
import Input from '../../../../../../components/Input/Input';
import { parse } from 'papaparse';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import SelectClassSection from '../../../../../../components/SelectClassSection/SelectClassSection';
import { toast } from 'react-toastify';
import { dashboardContext } from '../../../../Dashboard';
import { Access } from '../../../PortalAccess/AccessEnum';


import { useQuery } from '@apollo/client';
import { GET_STUDENT_TAGS } from '../../../SchoolTags/Querries';

import ConfirmationBox from '../../../../../../components/ConfirmationBox/ConfirmationBox';
import { SEND_WHATSAPP_REMINDER_TO_STUDENT } from '../studentDetails/Queries';
import { useMutation } from '@apollo/client';

const downloadTxtFile = (array: any[]) => {
	const element = document.createElement('a');
	const file = new Blob([array.map((d, i) => `${i + 1}) ${d}`).join('\n')], { type: 'text/plain;charset=utf-8' });
	element.href = URL.createObjectURL(file);
	element.download = 'Error_Log_File.txt';
	document.body.appendChild(element);
	element.click();
};
function array2json(data: any, sections: any, close: any) {
	var headers = data.data[0];

	var result: any = [];
	var res: any = [];
	const validClasses = sections.map((d: any) => d.class);
	const validSections = sections
		.map((d: any) => d.section)
		.filter((value: any, index: any) => sections.map((d: any) => d.section).indexOf(value) === index);
	let validCategories = ['obc', 'sc', 'st', 'general'];
	let validGender = ['male', 'm', 'female', 'f', 'other', 'o'];
	let errors: string[] = [];
	const seenIds = new Set();
	for (var i = 1; i < data.data.length; i++) {
		var obj: any = {};
		for (var j = 0; j < headers.length; j++) {
			obj[headers[j]] = data.data[i][j];
		}
		result.push(obj);
	}
	for (const student of result) {
		const {
			name,
			category,
			class: studentClass,
			section,
			school_generated_id: schoolId,
			gender,
			has_sibling,
		} = student;
		let hasError = false;
		if (!has_sibling) {
			errors.push(`The 'has_sibling' field does not have any value for student '${name}'.`);
			hasError = true;
		}
		if (!validCategories.includes(category.toLowerCase())) {
			errors.push(
				`Invalid category '${category}' for student '${name}'. Valid categories are ${validCategories.join(', ')}.`,
			);
			hasError = true;
		}
		if (!validGender.includes(gender.toLowerCase())) {
			errors.push(`Invalid gender '${gender}' for student '${name}'. Valid gender are ${validGender.join(', ')}.`);
			hasError = true;
		}

		if (!validClasses.includes(studentClass)) {
			errors.push(
				`Invalid class '${studentClass}' for student '${name}'. Valid classes are ${validClasses.join(', ')}.`,
			);
			hasError = true;
		}
		if (!validSections.includes(section)) {
			errors.push(
				`Invalid section '${section}' for student '${name}'. Valid sections are ${validSections.join(', ')}.`,
			);
		}
		if (seenIds.has(schoolId)) {
			errors.push(`Duplicate school_generated_id '${schoolId}' found for student '${name}'. Each ID must be unique.`);
			hasError = true;
		} else {
			seenIds.add(schoolId);
		}
		if (!hasError) {
			res.push({ ...student, has_sibling: student.has_sibling.toLowerCase(), category: category.toLowerCase() });
		}
	}

	if (errors.length) {
		close(false);
		toast.error(
			({ closeToast }) => (
				<div className='flex flex-col z-50 max-w-fit'>
					<span>Download Error Log File ❗</span>
					<button onClick={() => downloadTxtFile(errors)}>click here</button>
				</div>
			),
			{ autoClose: 10000 },
		);
	} else {
		console.log(res);

		return res;
	}
}
function csv2json(file: any, sections: any, close: any): any {
	return new Promise(async (resolve, reject) => {
		try {
			parse(file, {
				complete: (results) => {
					resolve(array2json(results, sections, close));
				},
				skipEmptyLines: true,
			});
		} catch (error) {
			window.alert("Couldn't parse the file");
		}
	});
}

const create_students_via_csv = async (data: any) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify(data);

	var requestOptions: RequestInit = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	const res: any = await fetch(
		process.env.REACT_APP_BACKEND_URL + '/api/student/create-student-via-csv',
		requestOptions,
	);

	if (res.ok) {
		window.location.reload();
		return await res.json();
	} else {
		let message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			//window.location.reload();
		}
		toast.error(`${message.message}`, {
			position: 'top-center',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});
	}
};

// const get_section_wise_students = async (section_id: string) => {
// 	var myHeaders = new Headers();
// 	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

// 	var requestOptions: RequestInit = {
// 		method: 'GET',
// 		headers: myHeaders,
// 		redirect: 'follow',
// 	};
// 	console.log(section_id);

// 	const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/section/' + section_id, requestOptions);
// 	console.log(res);

// 	if (res.ok) {
// 		return await res.json();
// 	} else {
// 		const message = await res.json();
// 		if (message.statusCode === 401) {
// 			localStorage.removeItem('token');
// 			window.location.reload();
// 		}
// 	}
// };

const get_formated_date = (_dateStr: any) => {
	const dateStr = _dateStr || '25-03-2001';
	let dateParts;
	if (dateStr.includes('-')) {
		dateParts = dateStr.split('-');
	}
	if (dateStr.includes('/')) {
		dateParts = dateStr.split('/');
	}

	return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
};

const StudentList = () => {
	// const { sections } = useContext(dashboardContext);
	const [inputText, setInputText] = useState('');
	const { students } = useContext(studentContext);
	const [studentId, setStudentId] = useState('');

	const { setAddStudent } = useContext(studentContext);
	const [csv_link, set_csv_link] = useState('');
	const [csv_sample_link, set_csv_sample_link] = useState('');
	const [_class, setClass] = useState('');
	const [open, set_open] = useState(false);
	const [csv_student_list, set_csv_student_list] = useState([]);
	const [section_id, set_section_id] = useState('');
	const { user, students: all_students, sections } = useContext(dashboardContext);


	const [send_whatsapp_reminder_to_student] = useMutation(SEND_WHATSAPP_REMINDER_TO_STUDENT);
	const [sendReminderConfirmationModal, setSendReminderConfirmationModal] = useState(false);

	useEffect(() => {
		if (students?.length > 1) {
			const rows = [
				['name', 'father_name', 'school_generated_id', 'class', 'section', 'gender', 'phone_number', 'category', 'dob'],
				...students?.map((student: any) => {
					return [
						student.name,
						student.father_name,
						student.school_generated_id,
						student.class,
						student.section,
						student.gender,
						student.phone_number,
						student.category,
						new Date(student.dob).toLocaleDateString(),
						student.has_sibling,
					];
				}),
			];

			let csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
			var encodedUri = encodeURI(csvContent);
			var encodedUri_sample = encodeURI(
				'data:text/csv;charset=utf-8,' +
					[
						'name',
						'middle_name',
						'last_name',
						'father_name',
						'school_generated_id',
						'class',
						'section',
						'gender',
						'phone_number',
						'category',
						'dob',
						'email_id',
						'roll_number',
						'date_of_addmission',
						'blood_group',
						'address_line1',
						'address_line2',
						'city',
						'state',
						'pin',
						'country',
						'aadhar_number',
						'pan_number',
						'father_contact_number',
						'father_email_id',
						'father_education_qualification',
						'father_occupation',
						'father_work_organization',
						'father_designation',
						'father_annual_income',
						'mother_name',
						'mother_contact_number',
						'mother_email_id',
						'mother_education_qualification',
						'mother_occupation',
						'mother_work_organization',
						'mother_designation',
						'mother_annual_income',
						'guardian_name',
						'guardian_contact_number',
						'guardian_email_id',
						'guardian_relationship',
						'guardian_education_qualification',
						'guardian_occupation',
						'guardian_work_organization',
						'guardian_designation',
						'guardian_annual_income',
						'religion',
						'nationality',
						'bpl_student',
						'bpl_card_number',
						'person_with_disability',
						'type_of_disability',
						'identification_mark',
						'mother_tougue',
						'second_language',
						'emergency_contact_number',
						'single_parent_child',
						'sponsored_student',
						'sponsored_name',
						'bank_name',
						'bank_account_number',
						'ifsc_code',
						'account_holder_name',
						'weight',
						'height',
						'bmi',
						'allergies',
						'school_name',
						'school_address',
						'board',
						'medium_of_instruction',
						'tc_number',
						'last_class_passed',
						'percentage_of_last_class_passed',
						'has_sibling',
					].join(',') +
					'\n',
			);
			set_csv_link(encodedUri);
			set_csv_sample_link(encodedUri_sample);
		}
	}, [students]);
	console.log(studentId);

	return (
		<div className='mr-5'>
			<div className='w-full  font-semibold '>
				<div className='flex justify-between my-6 items-center'>
					{/* <div className='bg-[#817DFF]  items-center bg-opacity-10 flex py-3  px-6 max-w-md w-full rounded-[24px]'>
						<i className='text-edvion_black text-opacity-50 fa-solid fa-magnifying-glass'></i>
						<input
							onChange={(e) => setInputText(e.target.value)}
							type='text'
							placeholder='Search by student name, enrolment number'
							className='ml-4 bg-transparent focus:outline-none w-full placeholder:font-normal'
						/>
					</div> */}
					<SelectClassSection
						className='flex'
						onChange={(s: any) => {
							setClass(s.class);
							set_section_id(s.section);
						}}
					></SelectClassSection>
					{/* <button
						onClick={() => setAddStudent(true)}
						className='m-2 bg-[#6F6AF8] text-white rounded-lg font-medium py-2 px-5'
					>
						Add New Student
					</button> */}
					<div className='flex'>
						{(user?.access === Access.SUPER || user.access === Access.ALL_ACCESS) && (
							<a
								download='students.csv'
								href={csv_link}
								className=' ml-auto m-2 border-2 border-violet-500 rounded-lg text-violet-500 font-medium py-2 px-5'
							>
								Download CSV File
							</a>
						)}

						<button
							className='m-2 bg-[#6F6AF8] text-white rounded-lg font-medium py-2 px-5'
							onClick={() => set_open(true)}
						>
							Upload Student Data
						</button>
					</div>
				</div>
			</div>

			<Modal title='Upload CSV File ( Bulk Data )' open={open} setOpen={set_open}>
				<div className='flex w-full'>
					<div className='mx-auto'>
						<Form
							onSubmit={async (data: any) => {
								await create_students_via_csv(csv_student_list);
								set_open(false);
							}}
						>
							<Input
								type='file'
								onChange={async (value: any, e: any) => {
									const student_updated_list = await csv2json(e.target.files[0], sections, set_open);

									set_csv_student_list(
										student_updated_list
											?.filter((row: any) => {
												const res: any = Object.values(row);
												return !/^,+$/g.test(res);
											})
											.map((row: any) => {
												switch (row['gender']) {
													case 'Female':
													case 'F':
													case 'f':
														row['gender'] = 'female';

														break;
													case 'Male':
													case 'M':
													case 'm':
														row['gender'] = 'male';

														break;
													case 'Other':
													case 'O':
													case 'o':
														row['gender'] = 'other';

														break;
												}

												return {
													...row,
													gender: row['gender'],
													category: row['category']?.toLowerCase(),
													dob: get_formated_date(row['dob']),
													date_of_addmission: get_formated_date(row['date_of_addmission']),
												};
											}),
									);
								}}
								label='CSV File'
							/>
							<a
								download='add_students_sample.csv'
								href={csv_sample_link}
								className=' border-none text-sm text-violet-500  font-normal outline-0 mb-0'
							>
								Download CSV Sample File
							</a>

							<button type='submit' className='m-2 mt-6 bg-[#6F6AF8] text-white rounded-lg font-medium py-2 px-5'>
								Upload
							</button>
						</Form>
					</div>
				</div>
			</Modal>
			<Modal open={sendReminderConfirmationModal} setOpen={setSendReminderConfirmationModal}>
				<ConfirmationBox
					setOpen={setSendReminderConfirmationModal}
					funtion={() => {
						send_whatsapp_reminder_to_student({
							variables: {
								student_id: studentId,
							},
						});
					}}
					confirmationText='send reminder'
					ButtonText='Send Reminder'
				/>
			</Modal>
			{section_id && (
				<LoadAndRender>
					<div className='flex flex-col w-full'>
						<div className='table w-full shadow p-5 rounded-lg my-2'>
							<div className='flex items-center justify-between'>
								<div className='bg-[#817DFF] ml-5 h-12 items-center bg-opacity-10 flex  px-6 max-w-md w-full rounded-[24px]'>
									<i className='text-edvion_black text-opacity-50 fa-solid fa-magnifying-glass'></i>
									<input
										onChange={(e) => setInputText(e.target.value)}
										type='text'
										placeholder='Search by student name, enrolment number'
										className='ml-4 bg-transparent focus:outline-none w-full placeholder:font-normal'
									/>
								</div>
								<div className=' text-right'>
									<button
										onClick={() => setAddStudent(true)}
										className='m-2 bg-[#6F6AF8] text-white rounded-lg font-medium py-2 px-5'
									>
										Add New Student
									</button>
								</div>
							</div>

							<div className='grid grid-cols-8 grid-header p-3 font-semibold m-5 bg-[#ECEDFB] rounded-t-lg text-violet-900'>

								<div className='text-center'>Student Details</div>
								<div className='text-center'>Father's Name</div>
								<div className='text-center'>Id</div>
								<div className='text-center'>Class</div>
								<div className='text-center'>Tags</div>
								<div className='text-center'>Gender</div>
								<div className='text-center'>Mobile Number</div>
								<div className='text-center'></div>
							</div>
							{/* DRY CODE */}
							{inputText !== '' ? (
								<>
									{all_students
										.filter((s: any) => {
											const arr = [s.name, s.school_generated_id, s?.father_name, s?.phone_number, s?.tags].join(',');
											return arr.toLowerCase().includes(inputText.toLowerCase());
										})
										.map((r: any, i: any) => {
											return (
												<div
													key={i}

													className='text-edvion_black grid grid-cols-8 grid-body items-center p-3 mx-5 my-2 odd:bg-[#ECEDFB] even:bg-[#F8FAFB] odd:border-none even:border border-[#ECEDFB] rounded-lg'

												>
													<div className='text-center'>
														<Link to={`/student_profile/${r._id}`}>
															<div className='items-center flex justify-center'>
																{/* <i className='fa-regular fa-user mr-2 text-[#959595]'></i> */}
																{/* <img src={studentIcon} alt='' /> */}
																<div className='ml-4'>
																	<span className='text-violet-500 text-base'>{r.name}</span>
																</div>
															</div>
														</Link>
													</div>
													<div className='text-center'>{r.father_name}</div>
													<div className='text-center'>
														<span className='text-edvion_black text-base'>{r.school_generated_id}</span>
													</div>
													<div className='text-center'>{r.class + '-' + r.section}</div>
													{r.tags.length ? (
														<div className='text-center flex flex-row gap-2 justify-center '>
															{r.tags.slice(0,3).map((el: any) => {
																return (
																	<span
																		key={el.tagCode}
																		className='bg-[#6F6AF8] text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-[#6F6AF8]-900 dark:text-white'
																	>
																		{el.tagCode.length>5?`${el.tagCode.substring(0,5)}...`:el.tagCode}
																	</span>
																);
															})
															
															}{r.tags.length >= 3 && <span className='text-[#6F6AF8]'>...</span>}
														</div>
													) : (
														<div className='text-center flex flex-row gap-2 justify-center '>
															<span className=' text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-[#a1a1aa]'>
																NA
															</span>
														</div>
													)}

													<div className='text-center capitalize'>{r.gender}</div>
													<div className='text-center'>{r.phone_number}</div>
													<div className='text-center'>
														<i
															onClick={() => {
																setStudentId(r._id);
																setSendReminderConfirmationModal(true);
															}}
															className='fa-solid fa-ellipsis-vertical cursor-pointer'
															aria-hidden='true'
														></i>
													</div>
												</div>
											);
										})}
								</>
							) : (
								<>
									{all_students?.length
										? all_students
												.filter((s: any) => {
													return (
														s.class.toLowerCase() === _class.toLowerCase() &&
														s.section.toLowerCase() === section_id.toLowerCase()
													);
												})
												.map((r: any, i: any) => {
													//console.log(r)
													return (
														<div
															key={i}

															className='text-edvion_black grid grid-cols-8 grid-body items-center p-3 mx-5 my-2 odd:bg-[#ECEDFB] even:bg-[#F8FAFB] odd:border-none even:border border-[#ECEDFB] rounded-lg'

														>
															<div className='text-center'>
																<Link to={`/student_profile/${r._id}`}>
																	<div className='items-center flex justify-center'>
																		{/* <i className='fa-regular fa-user mr-2 text-[#959595]'></i> */}
																		{/* <img src={studentIcon} alt='' /> */}
																		<div className='ml-4'>
																			<span className='text-violet-500 text-base'>{r.name}</span>
																		</div>
																	</div>
																</Link>
															</div>
															<div className='text-center'>{r.father_name}</div>
															<div className='text-center'>
																<span className='text-edvion_black text-base'>{r.school_generated_id}</span>
															</div>
															<div className='text-center'>{r.class + '-' + r.section}</div>
															{r.tags.length ? (
																<div className='text-center flex flex-row gap-2 justify-center '>
																	{r.tags.slice(0,3).map((el: any) => {
																		return (
																			<span className='bg-[#6F6AF8] text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-[#6F6AF8]-900 dark:text-white'>
{																		el.tagCode.length>5?`${el.tagCode.substring(0,5)}...`:el.tagCode}																			</span>
																		);
																	})}{r.tags.length >= 3 && <span className='text-[#6F6AF8]'>...</span>}
																</div>
															) : (
																<div className='text-center flex flex-row gap-2 justify-center '>
																	<span className=' text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-[#a1a1aa]'>
																		NA
																	</span>
																</div>
															)}
															<div className='text-center capitalize'>{r.gender}</div>
															<div className='text-center'>{r.phone_number}</div>
															<div className='text-center'>
																<i
																	onClick={() => {
																		setStudentId(r._id);
																		setSendReminderConfirmationModal(true);
																	}}
																	className='fa-solid fa-ellipsis-vertical cursor-pointer'
																	aria-hidden='true'
																></i>
															</div>
														</div>
													);
												})
										: ''}
								</>
							)}
						</div>
					</div>
				</LoadAndRender>
			)}
		</div>
	);
};

export default StudentList;
