import { gql } from "@apollo/client";

export const CREATE_TAG = gql`

mutation createTag($name:String!,$tagCode:String!,$description:String!) {
    createTag(input:{name:$name,description:$description,tagCode:$tagCode}) {
        _id
        description
        name
        school
        tagCode
    }
}


`

export const GET_TAGS= gql`
query Tags {
    tags {
        _id
        description
        name
        school
        tagCode
        count
    }
}`

export const ADD_TAG_STUDENT= gql`

mutation AddTagToStudent($student:String!,$tagIds:[String!]!) {
    addTagToStudent(student:$student, tagIds: $tagIds) {
        _id
       
    }
}

`

export const DELETE_TAG= gql `

mutation DeleteTag($id:String!) {
    deleteTag(id:$id) {
        _id
        description
        name
        school
        tagCode
    }
}


`


export const GET_TAG_BY_ID= gql`
query Tag($id:String!) {
    tag(id:$id ) {
        _id
        description
        name
        school
        tagCode
    }
}
`

export const CREATE_TAG_DISCOUNT= gql`

mutation Create_tag_discount(
    $discount_name:String!
    ,$tags:[String!]!,
    $type:DiscountType!,
    $class:[String!],
    $fee_head_id:ID,
    $value:Float!,
    $gender:[String!]) {
    create_tag_discount(
        discount_name: $discount_name, 
        tags: $tags,
         type: $type,
         class:$class,
         gender:$gender,
         fee_head_id:$fee_head_id,
         value:$value) {
        _id
        applicable_on
        createdAt
        discount_type
        group_category
        group_class
        group_gender
        name
        tags
        updatedAt
        value
    }
}
`

export const REMOVE_TAG_FROM_STUDENT= gql`

mutation DeleteTagFromStudent($student:String!,$tag:String!) {
    deleteTagFromStudent(student:$student, tag:$tag) {
        _id
       
    }
}

`

export const GET_STUDENT_TAGS= gql`

query Student($id:String!) {
    student(id: $id) {
        _id
        tags{
            _id
            name
            tagCode
            description
            count
        }
    
    }
}
`