import React, { useState, useContext, useEffect } from 'react';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import Table from '../../../../../../components/Table/Table';
import Modal from '../../../../../../components/Modal/Modal';
import Form from '../../../../../../components/Form/Form';
import Input from '../../../../../../components/Input/Input';

import { parse } from 'papaparse';

import DisplayCard from '../DiscountsTab/components/DisplayCard';
import { Link } from 'react-router-dom';
import SelectClassSection from '../../../../../../components/SelectClassSection/SelectClassSection';
import Loading from '../../../../Loading';
import FeeConfigDuesSmallLoader from '../../../SmallSkeleton/FeeConfigDuesSmallLoader';
import { dashboardContext } from '../../../../Dashboard';
import { Access } from '../../../PortalAccess/AccessEnum';
import RadioInput from '../../../../../../components/RadioInput/RadioInput';
import { useMutation } from '@apollo/client';
import { UPDATE_PREVIOUS_SESSION_DUE_AMOUNT } from './Queries';
import CollectionRuleModal from './CollectionRuleModal';

function array2json(data: any): any {
	var headers = data.data[0];
	var result: any = [];
	for (var i = 1; i < data.data.length; i++) {
		var obj: any = {};
		for (var j = 0; j < headers.length; j++) {
			obj[headers[j]] = data.data[i][j];
		}
		result.push(obj);
	}
	console.log(result);
	return result;
}
function csv2json(file: any): any {
	return new Promise(async (resolve, reject) => {
		try {
			console.log({ file });
			parse(file, {
				complete: (results) => {
					console.log(results);

					resolve(array2json(results));
				},
			});
		} catch (error) {
			window.alert("Couldn't parse the file");
		}
	});
}

const send_prev_session_dues = async (data: any) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify(data);

	var requestOptions: RequestInit = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/prev-dues', requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

const get_students = async () => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

	var requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/students', requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

const get_section_wise_dues = async (section_id: string) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

	var requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/section/' + section_id, requestOptions);

	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};
const PrevSessionDuesTab = () => {
	const [students, set_students]: [any, any] = useState([]);
	const [csv_link, set_csv_link]: [any, any] = useState(null);
	const [defaulter, set_defaulter] = useState<any>(null);
	const [show_download_modal, set_show_download_modal] = useState(false);
	const [selected_class_link, set_selected_class_link] = useState<any>('');
	const [open, set_open] = useState(false);
	const [editPrevDuesModal, setEditPrevDuesModal] = useState(false);
	const [collectionRuleModal, setCollectionRuleModal] = useState(false);
	const [section_id, set_section_id] = useState(null);
	const [studentIdOfEditPrevDue, setStudentIdOfEditPrevDue] = useState('');
	const { user, sections, school_data } = useContext(dashboardContext);
	const [updatePrevDueAmount, { loading }] = useMutation(UPDATE_PREVIOUS_SESSION_DUE_AMOUNT);

	useEffect(() => {}, [section_id]);

	return (
		<Loading component={<FeeConfigDuesSmallLoader />}>
			<LoadAndRender
				reload_upon_change={[loading]}
				promise={async () => {
					var _students = await get_students();
					set_students(_students);
					const rows = [
						['School Generated ID', 'Name', 'Class', 'Previous Session Dues'],
						...(_students &&
							_students.map((student: any) => {
								return [
									student.school_generated_id,
									student.name,
									student.class + '-' + student.section,
									student.previous_session_dues,
								];
							})),
					];

					let csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
					var encodedUri = encodeURI(csvContent);
					set_csv_link(encodedUri);
				}}
				className='w-full'
			>
				<Modal title='Upload Dues List' open={open} setOpen={set_open}>
					<div className='flex w-full'>
						<div className='mx-auto'>
							<Form
								onSubmit={async (data: any) => {
									const payload = students.map((student: any) => {
										return {
											student_id: student._id,
											previous_session_dues: parseFloat(student.previous_session_dues.replace(/,/g, '')),
										};
									});
									console.log({ payload });
									await send_prev_session_dues(payload);
									//set_students(await get_students());
									set_section_id(sections[0]._id);
									set_open(false);
								}}
							>
								<Input
									type='file'
									onChange={async (value: any, e: any) => {
										const json = await csv2json(e.target.files[0]);
										console.log({ json });
										const updated_students = students.map((student: any) => {
											const _student = json.find((s: any) => s['School Generated ID'] === student.school_generated_id);
											const updated_student = { ...student, previous_session_dues: _student['Previous Session Dues'] };
											return updated_student;
										});

										set_students(updated_students);
									}}
									label='CSV File'
								/>
								<button className='m-2 bg-[#6F6AF8] text-white rounded-lg font-medium py-2 px-5'>Upload</button>
							</Form>
						</div>
					</div>
				</Modal>
				<Modal title='Edit Previous Session Dues' open={editPrevDuesModal} setOpen={setEditPrevDuesModal}>
					<Form
						onSubmit={(data: any) => {
							updatePrevDueAmount({
								variables: {
									student_id: studentIdOfEditPrevDue,
									previous_due_amount: parseFloat(data['Previous Session Due Amount']),
								},
							});
							setEditPrevDuesModal(false);
						}}
					>
						<Input
							type='number'
							name='Previous Session Due Amount'
							placeholder={'Enter Amount To Update Due'}
							add_error={() => {}}
						/>
						<div className='flex justify-center'>
							<button className='py-2 px-10 bg-green-500 disabled:bg-green-200 text-white rounded-lg'>
								{loading ? 'loading...' : 'Update'}
							</button>
						</div>
					</Form>
				</Modal>
				<Modal
					className='max-w-4xl w-full'
					title='Collection Rule'
					open={collectionRuleModal}
					setOpen={setCollectionRuleModal}
				>
					<CollectionRuleModal setCollectionRuleModal={setCollectionRuleModal} />
				</Modal>
				<div className='m-5 w-full'>
					<div className=''>
						<div className='flex gap-x-9 my-6 w-full'>
							<DisplayCard value={students.length} label={'Total Students'} />

							{/* <DisplayCard
								value={
									'₹ ' +
									school_data?.school.instllment_wise_fee_report
										?.map((d: any) => d.total_amount - d.total_amount_paid)
										?.reduce((a: any, b: any) => a + b, 0)
										.toLocaleString('hi')
								}
								label={'Total Amount'}
							/> */}
						</div>
					</div>

					<div className='flex justify-between items-center'>
						<div>
							<SelectClassSection className='flex' onChange={(s: any) => set_section_id(s._id)}></SelectClassSection>
						</div>
						<div className='flex'>
							<button
								onClick={() => setCollectionRuleModal(true)}
								className=' ml-auto m-2 border-2 border-violet-500 rounded-lg text-violet-500 font-medium py-2 px-5'
							>
								Set Collection Rule
							</button>
							<button
								className='m-2 bg-[#6F6AF8] text-white rounded-lg font-medium py-2 px-5'
								onClick={() => set_open(true)}
							>
								Upload Dues List
							</button>
						</div>
					</div>
					{section_id && (
						<LoadAndRender
							promise={async () => {
								const res = await get_section_wise_dues(section_id);

								const rows = [
									['School Generated ID', 'Name', 'Class', 'Previous Session Dues'],
									...res.map((student: any) => {
										return [
											student.school_generated_id,
											student.name,
											student.class + '-' + student.section,
											student.previous_session_dues,
										];
									}),
								];

								let csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
								var encodedUri = encodeURI(csvContent);
								set_selected_class_link(encodedUri);
								set_students(res);
							}}
							reload_upon_change={[section_id]}
						>
							<Modal
								className='max-w-2xl w-full'
								title='Download CSV'
								setOpen={set_show_download_modal}
								open={show_download_modal}
							>
								<div className='flex items-center gap-x-2'>
									<div className='flex-1'>
										<RadioInput
											name='Download Previous Session Dues list'
											options={['Download selected class list', 'Download all class list']}
											onChange={(e: any, v: any) => set_defaulter(v)}
											add_error={() => {}}
											required
										/>
									</div>

									{(user?.access === Access.SUPER || user.access === Access.ALL_ACCESS) && (
										<a
											download={
												defaulter === 'Download selected class list'
													? 'defaulter_selected_list'
													: 'defaulter_all_class_list'
											}
											href={defaulter === 'Download selected class list' ? selected_class_link : csv_link}
											className=' ml-auto m-2 border-2 border-violet-500 rounded-lg text-violet-500 font-medium py-2 px-5'
										>
											Download
										</a>
									)}
								</div>
							</Modal>
							<div className='w-full'>
								<Table
									className='hidden'
									heading={
										<div className='flex w-full justify-between items-center'>
											<h2>Previous Session Dues</h2>
											<button
												className='ml-auto font-medium text-base bg-[#6F6AF8] text-white px-5 py-2 rounded-lg mt-2 '
												onClick={() => set_show_download_modal(true)}
											>
												Download CSV
											</button>
										</div>
									}
									data={[
										['School Generated ID', 'Name', 'Father Name', 'Class', 'Previous Session Dues', 'Paid', 'Action'],
										...(students &&
											students.map((student: any) => {
												return [
													student.school_generated_id,
													<Link to={'/student_profile/' + student._id} className='text-violet-500'>
														{student.name}
													</Link>,

													student.father_name,
													student.class + '-' + student.section,
													<h2 className={student.previous_session_dues - 0 > 0 ? 'text-[#FC4343]' : ' '}>
														{'₹ ' + (student.previous_session_dues - 0).toLocaleString('hi')}
													</h2>,
													<h2 className={student.previous_session_dues_paid - 0 > 0 ? 'text-[#2EB418]' : ' '}>
														{'₹ ' + (student.previous_session_dues_paid - 0).toLocaleString('hi')}
													</h2>,
													<i
														onClick={() => {
															setEditPrevDuesModal(true);
															setStudentIdOfEditPrevDue(student._id);
														}}
														className='fa-solid fa-pen cursor-pointer'
													></i>,
												];
											})),
									]}
								></Table>
							</div>
						</LoadAndRender>
					)}
				</div>
			</LoadAndRender>
		</Loading>
	);
};

export default PrevSessionDuesTab;
