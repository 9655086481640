import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Menu, MenuItem } from '../../../../components/HorizontalMenu/Menu';
import FeeHelp from './pages/FeeHelp/FeeHelp';
import FeeOverview from './pages/FeeOverview/FeeOverview';
import FeeTransactions from './pages/FeeTransactions/FeeTransactions';

const FeeCollection = ({  school }: any) => {
	const [path, set_path] = React.useState<any>(window.location.pathname);
	return (
		<div className='fee-config w-full flex flex-col my-5 px-5'>
			<div className='menu'>
				<Menu path={path} set_path={set_path}>
					<MenuItem name='Overview' to='/fee_collection' />
					<MenuItem name='Transactions' to='/fee_collection/transactions' />
				</Menu>
			</div>
			<div className=''>
				<Routes>
					<Route
						path='/'
						element={<FeeOverview set_path={set_path}  school={school} />}
					/>
					<Route path='transactions' element={<FeeTransactions />} />
					<Route path='help' element={<FeeHelp />} />
				</Routes>
			</div>
		</div>
	);
};

export default FeeCollection;
