import React, { useContext, useEffect, useState } from 'react';
import Modal from '../../../../../../components/Modal/Modal';
import BasicDetails from '../../../StudentProfile/components/BasicDetails';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import Select from '../../../../../../components/Select/Select';
import Input from '../../../../../../components/Input/Input';
import { preventNegativeValues } from '../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../helper/preventPasteNegative';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import * as queries from './Queries';
import { toast } from 'react-toastify';

import Table from '../../../../../../components/Table/Table';
import SequentialInstallmentSelect from './components/SequentialInstallmentSelect';

import { PaymentMode } from '../../../../AccessEnum';
import InvoicePDF from '../FeeOverview/InvoicePDF';
import { Mixpanel } from '../../../../../../util/mixpanel';
import { dashboardContext } from '../../../../Dashboard';
import posPendingStatus from '../../../../assets/posPendingStatus.svg';
import posFailedStatus from '../../../../assets/posFailedStatus.svg';
import PosPaymentFailure from '../../../PaymentStatus/PosPaymentFailure';
const InstallmentListSection = ({
	student_id,
	student,
	setShowModal,
	setInvoiceModal,
	setStudents,
	section_id,
	get_collection,
	set_invoice_data,
	setPosModal,
	set_pos_data,
}: any) => {
	const [payment_mode, set_payment_mode] = useState({
		name: 'Cash',
	});

	const [cheque_no, setChequeNo] = useState<any>(null);
	const [cheque_description, set_cheque_description] = useState<any>(null);
	const [dd_number, set_dd_number] = useState<any>(null);
	const [date_on_dd, set_date_on_dd] = useState<any>(null);
	const { loading, data: installments_query_response } = useQuery(queries.GET_INSTALLMENTS, {
		variables: {
			id: student_id,
		},
	});

	const [prev_due_amount, set_prev_due_amount] = useState<any>(0);
	const [min_prev_sess_dues_to_pay, set_min_prev_sess_dues_to_pay] = useState<any>(0);
	const [max_prev_sess_dues_to_pay, set_max_prev_sess_dues_to_pay] = useState<any>(0);
	const [selectedInstallments, setSelectedInstallments] = useState<any>([]);
	const [installments_final_amount, set_installments_final_amount] = useState<any>();
	const [PaymentData] = useMutation(queries.PAY);
	const [PayViaPos] = useMutation(queries.PAY_VIA_POS);
	const [MarkPayment, { loading: pay_status }] = useMutation(queries.MARK_COLLECTED_PAY);
	const { user, school_data } = useContext(dashboardContext);
	const [pos, setPos] = useState({
		name: school_data?.school?.pos[0] && school_data?.school?.pos[0].name,
		id: school_data?.school?.pos[0] && school_data?.school?.pos[0]._id,
	});
	const paymentTypes = ['Cash', 'Cheque', 'DD', 'Online'];
	const types = school_data?.school?.pos?.length > 0 ? paymentTypes.concat(['PoS']) : paymentTypes;
	useEffect(() => {
		setChequeNo(null);
		set_dd_number(null);
		set_cheque_description(null);
	}, [payment_mode]);
	useEffect(() => {
		set_prev_due_amount(min_prev_sess_dues_to_pay);
	}, [min_prev_sess_dues_to_pay]);

	let installments_duedate = selectedInstallments
		?.map((data: any) => {
			if (data.to_pay !== 0) {
				return new Date(data.due_date).toDateString();
			}
		})
		.filter((s: any) => s !== undefined);

	const installments = installments_query_response?.student?.installments;

	const _paid_till_installment_index: number =
		installments &&
		installments
			?.map((installment: any, idx: number) => (installment?.to_pay === 0 ? idx : -1))
			.findIndex((i: number) => i === -1) - 1;

	const [pay_till_installment_index, set_pay_till_installment_index] = useState<number>(-3);
	const paid_till_installment_index =
		_paid_till_installment_index === -2 ? installments.length - 1 : _paid_till_installment_index;

	if (pay_till_installment_index === -3 && paid_till_installment_index !== undefined) {
		set_pay_till_installment_index(paid_till_installment_index);
	}

	useEffect(() => {
		if (installments) {
			setSelectedInstallments(installments?.slice(0, pay_till_installment_index + 1));
		}
	}, [pay_till_installment_index]);

	async function handleFeeCollect() {
		if (prev_due_amount < min_prev_sess_dues_to_pay || prev_due_amount > max_prev_sess_dues_to_pay) {
			return toast.error(
				`Previous due amount should be between ${min_prev_sess_dues_to_pay} & ${max_prev_sess_dues_to_pay}`,
			);
		}
		if (payment_mode.name === 'Online') {
			const res = await PaymentData({
				variables: {
					pay_till_installment: pay_till_installment_index + 1,
					selected_discounts: [],
					student_id: student._id,
					is_school: true,
					prev_due_amount: prev_due_amount,
				},
			});
			if (res?.data['pay']) window.location.replace(res?.data['pay']);

			Mixpanel.track('collect_fee', {
				schoolId: student.school?.id,
				school: student.school?.name,
				studentId: student?._id,
				studentName: student?.name,
				user_name: user?.name,
				mode_of_payment: 'Online',
				installments_duedate,
			});
		}
		if (payment_mode.name === 'PoS') {
			const res = await PayViaPos({
				variables: {
					pay_till_installment: pay_till_installment_index + 1,
					selected_discounts: [],
					student_id: student._id,
					is_school: true,
					prev_due_amount: prev_due_amount,
					pos_id: pos.id,
				},
			});
			console.log(res);
			set_pos_data(res);
			setPosModal(true);
			Mixpanel.track('collect_fee', {
				schoolId: student.school?.id,
				school: student.school?.name,
				studentId: student?._id,
				studentName: student?.name,
				user_name: user?.name,
				mode_of_payment: 'Via Pos',
				installments_duedate,
			});
		}
		if (payment_mode.name === 'Cash') {
			const res = await MarkPayment({
				variables: {
					pay_till_installment: pay_till_installment_index + 1,
					selected_discounts: [],
					student_id: student._id,
					payment_mode: PaymentMode.CASH,
					amount: installments_final_amount,
					prev_due_amount: prev_due_amount,
				},
			});
			Mixpanel.track('collect_fee', {
				schoolId: student.school?.id,
				school: student.school?.name,
				studentId: student?._id,
				studentName: student?.name,
				mode_of_payment: 'Cash',
				installments_duedate,
				user_name: user?.name,
			});
			set_invoice_data(res);
			setInvoiceModal(true);
		}
		if (payment_mode.name === 'Cheque') {
			await MarkPayment({
				variables: {
					pay_till_installment: pay_till_installment_index + 1,
					selected_discounts: [],
					student_id: student._id,
					account_holder: cheque_description,
					payment_mode: PaymentMode.CHEQUE,
					cheque_dd_no: cheque_no,
					date_on_cheque_dd: date_on_dd,
					amount: installments_final_amount,
					prev_due_amount: prev_due_amount,
				},
			});
			Mixpanel.track('collect_fee', {
				schoolId: student.school?.id,
				school: student.school?.name,
				studentId: student?._id,
				studentName: student?.name,
				mode_of_payment: 'Cheque',
				installments_duedate,
				user_name: user?.name,
			});
		}
		if (payment_mode.name === 'DD') {
			await MarkPayment({
				variables: {
					pay_till_installment: pay_till_installment_index + 1,
					selected_discounts: [],
					student_id: student._id,
					account_holder: cheque_description,
					payment_mode: PaymentMode.DD,
					cheque_dd_no: dd_number,
					date_on_cheque_dd: date_on_dd,
					amount: installments_final_amount,
					prev_due_amount: prev_due_amount,
				},
			});
			Mixpanel.track('collect_fee', {
				schoolId: student.school?.id,
				school: student.school?.name,
				studentId: student?._id,
				studentName: student?.name,
				mode_of_payment: 'DD',
				installments_duedate,
				user_name: user?.name,
			});
		}

		setShowModal(false);
	}

	return (
		<>
			{
				<LoadAndRender loading={loading} height='0' promise={async () => {}}>
					<div className='w-full'>
						{
							<div className='flex w-full'>
								<div className='text-base w-[50%] border-r  p-4'>
									<h2 className='text-xl font-medium text-edvion_black'>Amount Details</h2>
									<div className='flex items-start mt-3 gap-x-4 mb-4'>
										<div className='w-full flex gap-x-4 justify-between items-center'>
											<div className='w-full max-w-[14rem]'>
												<Select
													label='Payment Mode'
													options={types.map((type: any) => {
														return { name: type };
													})}
													selected={payment_mode}
													setSelected={set_payment_mode}
												/>
											</div>
											{payment_mode.name === 'PoS' && school_data?.school?.pos.length > 0 && (
												<Select
													label='POS'
													options={school_data?.school?.pos.map((pos: any) => {
														return { name: pos.name, id: pos._id };
													})}
													selected={pos}
													setSelected={setPos}
													className='w-full'
												/>
											)}
										</div>

										{payment_mode.name === 'Cheque' && (
											<div className='-my-2'>
												<Input
													type='number'
													name='Cheque No'
													add_error={() => {}}
													value={cheque_no}
													onChange={(e: any, chequeNo: any) => {
														setChequeNo(chequeNo);
													}}
													onKeyDown={preventNegativeValues}
													onPaste={preventPasteNegative}
													min={0}
													maxLength='6'
													required
												/>
												<Input
													type='date'
													name='Date on Cheque'
													min='1990-01-01'
													onChange={(e: any, dd_date: any) => {
														set_date_on_dd(dd_date);
													}}
													add_error={() => {}}
													required
												/>
											</div>
										)}

										{payment_mode.name === 'DD' && (
											<div className='-my-2'>
												<Input
													type='number'
													name='DD Number'
													add_error={() => {}}
													value={dd_number}
													onChange={(e: any, dd_number: any) => {
														set_dd_number(dd_number);
													}}
													onKeyDown={preventNegativeValues}
													onPaste={preventPasteNegative}
													min={0}
													maxLength='6'
													required
												/>
												<Input
													type='date'
													name='Date on DD'
													min='1990-01-01'
													onChange={(e: any, dd_date: any) => {
														set_date_on_dd(dd_date);
													}}
													add_error={() => {}}
													required
												/>
											</div>
										)}
									</div>
									{(payment_mode.name === 'Cheque' || payment_mode.name === 'DD') && (
										<div className='max-w-md w-full'>
											<Input
												type='text'
												name='Account Holder Name'
												add_error={() => {}}
												middle_name
												value={cheque_description}
												onChange={(e: any, chequeDes: any) => {
													set_cheque_description(chequeDes);
												}}
												required
											/>
										</div>
									)}
									<div className='w-56 -my-2'>
										<Input
											type='number'
											name='Previous Session Dues'
											add_error={() => {}}
											value={min_prev_sess_dues_to_pay}
											onChange={(e: any, value: any) => {
												set_prev_due_amount(Number(value));
											}}
											onKeyDown={preventNegativeValues}
											onPaste={preventPasteNegative}
											prefix={'₹'}
											min={min_prev_sess_dues_to_pay}
											max={max_prev_sess_dues_to_pay}
											required
										/>
									</div>
									<div className='flex gap-x-4'>
										<div className=''>
											{
												<SequentialInstallmentSelect
													name='Installment'
													due_dates={
														installments && installments.map((installment: any) => new Date(installment.due_date))
													}
													installments={selectedInstallments}
													paid_till_installment_index={paid_till_installment_index}
													pay_till_installment_index={pay_till_installment_index}
													set_pay_till_installment_index={set_pay_till_installment_index}
												/>
											}
										</div>
									</div>
								</div>
								<CalculationSection
									installment_index={pay_till_installment_index}
									student={student && student}
									payment_mode={payment_mode.name}
									installmentData={installments}
									selectedInstallments={selectedInstallments}
									set_installments_final_amount={set_installments_final_amount}
									installments_final_amount={installments_final_amount}
									set_min_prev_sess_dues_to_pay={set_min_prev_sess_dues_to_pay}
									set_max_prev_sess_dues_to_pay={set_max_prev_sess_dues_to_pay}
									min_prev_sess_dues_to_pay={min_prev_sess_dues_to_pay}
									max_prev_sess_dues_to_pay={max_prev_sess_dues_to_pay}
									prev_due_amount={prev_due_amount}
								/>
							</div>
						}
						<div className='w-full my-8 text-center'>
							<button
								disabled={!installments_final_amount}
								onClick={handleFeeCollect}
								className='disabled:bg-violet-400 bg-violet-500 text-white max-w-[200px] w-full  rounded-lg px-6 py-2'
							>
								{pay_status ? 'loading..' : 'Collect Fee'}
							</button>
						</div>
					</div>
				</LoadAndRender>
			}
		</>
	);
};

const CalculationSection = ({
	installment_index,
	student,
	installmentData,
	set_installments_final_amount,
	installments_final_amount,
	selectedInstallments,
	payment_mode,
	min_prev_sess_dues_to_pay,
	max_prev_sess_dues_to_pay,
	set_min_prev_sess_dues_to_pay,
	set_max_prev_sess_dues_to_pay,
	prev_due_amount,
}: any) => {
	const [finalAmount, { data: result }] = useMutation(queries.GET_FINAL_AMOUNT, {});
	const [total_installments_amount, set_total_installments_amount] = useState(0);

	const [show_feebreakup, set_show_fee_breakup] = useState(false);
	const [feebreakup_discount, set_fee_breakup_discount] = useState<any>([]);
	const [show_feebreakup_discount, set_show_fee_breakup_discount] = useState(false);
	const [discounts, set_discounts] = useState<any>([]);

	const [show_discount, set_show_discount] = useState(false);

	function getSum(total: any, num: any) {
		return total + num;
	}

	const totalAmount =
		selectedInstallments?.map((installment: any) => {
			return installment.applicable_fee_heads
				.map((fee: any) => {
					return fee.to_pay;
				})
				.reduce(getSum, 0);
		}) || [];

	useEffect(() => {
		let installments_amount = 0;
		selectedInstallments?.map((installment: any) => {
			return (installments_amount += installment.to_pay);
		});
		set_total_installments_amount(installments_amount);
	}, [selectedInstallments?.length]);
	useEffect(() => {
		async function amount() {
			const res = await finalAmount({
				variables: {
					pay_till_installment: installment_index + 1,
					selected_discounts: [],
					student_id: student._id,
					prev_due_amount,
				},
			});

			set_installments_final_amount(res.data.generate_payment_information.amount);
			set_min_prev_sess_dues_to_pay(res.data.generate_payment_information.min_prev_sess_dues_to_pay);
			set_max_prev_sess_dues_to_pay(res.data.generate_payment_information.max_prev_sess_dues_to_pay);
		}

		amount();
	}, [installment_index, prev_due_amount]);

	return (
		<>
			<Modal title={'Fee Head Discount'} open={show_feebreakup_discount} setOpen={set_show_fee_breakup_discount}>
				<div className='space-y-4 mt-2'>
					{!feebreakup_discount?.discounts?.length && <h1>No Discount Available</h1>}
					{feebreakup_discount?.discounts?.length && (
						<Table
							className='hidden'
							data={[
								['Name', 'Value'],
								...feebreakup_discount?.discounts?.map((discount: any) => [
									discount.name,
									<div>{discount.value}%</div>,
									<div>{discount.value}%</div>,
								]),
							]}
						/>
					)}
				</div>
			</Modal>
			{/* <Modal title={'Fee Breakup'} open={show_feebreakup} setOpen={set_show_fee_breakup}>
				
			</Modal> */}
			<Modal title={'Discount'} open={show_discount} setOpen={set_show_discount}>
				<div className='space-y-4 mt-2'>
					{!discounts?.length && <h1>No Discount Available</h1>}
					{discounts?.length && (
						<Table
							className='hidden'
							heading={'Discounts'}
							data={[
								['Name', 'Value'],
								...discounts?.map((discount: any) => [discount.name, <div>{discount.value}%</div>]),
							]}
						/>
					)}
				</div>
			</Modal>
			<div className='flex flex-col flex-1 px-4 '>
				<div className='space-y-4  mt-2 max-h-[22rem] overflow-y-auto pr-10'>
					{selectedInstallments?.map((installment: any, i: any) => {
						if (installment?.to_pay === 0) {
							return;
						}
						return (
							<div className='w-full'>
								<h2
									className='text-lg font-medium cursor-pointer hover:underline hover:underline-offset-4 text-edvion_black'
									onClick={() => {
										set_discounts(installment.discounts);
										set_show_discount(!show_discount);
									}}
								>
									{`Installment ${i + 1} ${new Date(installment?.due_date).toDateString()}`}
								</h2>
								{installment?.applicable_fee_heads.map((fee: any) => {
									return (
										<div className='flex flex-col'>
											<div className='flex justify-between ml-5'>
												<h3
													className='text-base cursor-pointer  hover:underline hover:underline-offset-4 font-medium text-edvion_black capitalize'
													onClick={() => {
														set_fee_breakup_discount(fee);
														set_show_fee_breakup_discount(!show_feebreakup_discount);
													}}
												>
													{fee?.fee_head?.name}
												</h3>
												<h2 className='text-xl font-medium text-[#6F6AF8]'>₹{fee?.to_pay}</h2>
											</div>
										</div>
									);
								})}
								<hr className='my-2' />
								{(payment_mode === 'Online' || payment_mode === 'PoS') && installment?.to_pay > 0 && (
									<div className='text-lg my-2 text-[#5e5e5e] flex justify-between ml-5'>
										<h2 className=' font-medium text-[#5e5e5e]'>Platform Fee</h2>
										<div className=''>₹{student?.school?.convenience_fee.toLocaleString('hi')}</div>
									</div>
								)}
								<div className='text-lg my-2 text-[#5e5e5e] flex justify-between ml-5'>
									<h2 className=' font-medium text-[#5e5e5e]'>Fine</h2>
									<div className=''>₹{installment?.fine.toLocaleString('hi')}</div>
								</div>

								<div className='text-2xl my-2 flex justify-between ml-5'>
									<h2 className='text-lg font-medium text-edvion_black'>Total</h2>
									<div className='text-[#2EB418]'>
										₹
										{payment_mode === 'Online' || (payment_mode === 'PoS' && installment?.to_pay > 0)
											? (totalAmount[i] + installment?.fine + student?.school?.convenience_fee).toLocaleString('hi')
											: (totalAmount[i] + installment?.fine).toLocaleString('hi')}
									</div>
								</div>
							</div>
						);
					})}

					{result?.generate_payment_information['applied_discounts'].length > 0 && (
						<div className='text-lg'>
							<span className=' font-medium text-edvion_black'>Discount</span>
							<br />
							<div className='text-sm ml-5'>
								{result?.generate_payment_information['applied_discounts'].map((discount: any) => {
									return (
										<div className='flex justify-between items-center'>
											<span>{discount?.discount?.name}</span>{' '}
											<span>₹ {discount?.discount?.value.toLocaleString('hi')}</span>
										</div>
									);
								})}
							</div>
						</div>
					)}
					{result?.generate_payment_information['unpaid_fines'].length > 0 && (
						<div className='text-lg'>
							<span className=' font-medium text-edvion_black'>fine</span>
							<div className='text-sm ml-5'>
								{result &&
									result?.generate_payment_information['unpaid_fines'].map((fine: any) => {
										return (
											<div className='flex justify-between items-center'>
												<span>{fine?.name}</span> <span>₹ {fine?.amount.toLocaleString('hi')}</span>
											</div>
										);
									})}
							</div>
						</div>
					)}
					<div className='text-2xl flex justify-between items-center'>
						<h2 className='text-lg font-medium text-edvion_black'>Grand Total:</h2>
						<div className='text-[#2EB418]'>
							₹
							{payment_mode === 'Online' || payment_mode === 'PoS'
								? selectedInstallments.filter((d: any) => d.to_pay > 0)?.length
									? (
											result?.generate_payment_information?.amount +
											student?.school?.convenience_fee * selectedInstallments.filter((d: any) => d.to_pay > 0)?.length
									  ).toLocaleString('hi')
									: 0
								: result?.generate_payment_information?.amount.toLocaleString('hi')}
						</div>
					</div>
				</div>
				<div className='h-auto space-y-4 m-4'>
					<hr className='border' />
					<div className='text-lg'>
						<span className=' font-medium text-edvion_black'>Previous Session Dues</span>:
						<span className=' text-green-400 ml-2'> ₹{student?.previous_session_dues?.toLocaleString('hi')}</span>
					</div>
					<div className='text-lg'>
						<span className=' font-medium text-edvion_black'>Total</span>:{' '}
						<span className=' text-green-400 ml-2'>
							₹
							{payment_mode === 'Online' || payment_mode === 'PoS'
								? selectedInstallments.filter((d: any) => d.to_pay > 0)?.length
									? (
											installments_final_amount +
											student?.school?.convenience_fee * selectedInstallments.filter((d: any) => d.to_pay > 0)?.length
									  ).toLocaleString('hi')
									: 0
								: installments_final_amount?.toLocaleString('hi')}{' '}
							<br />
						</span>
					</div>
				</div>
			</div>
		</>
	);
};

const CollectFeeModal = ({
	showModal,
	setShowModal,
	students,
	student,
	setStudents,
	section_id,
	get_collection,
}: any) => {
	const [invoceModal, setInvoiceModal] = useState(false);
	const [posModal, setPosModal] = useState(false);
	const [posTransactionFailedModal, setPosTransactionFailedModal] = useState(false);
	const [invoice_data, set_invoice_data] = useState<any>(null);
	const [pos_data, set_pos_data] = useState<any>(null);
	const [show_receipt, set_show_receipt] = useState(false);
	const [getTransaction, { loading, error, data }] = useLazyQuery(queries.GET_POS_TRANSACTION);
	const [getInvoice, { data: invoice }] = useLazyQuery(queries.GET_INVOICE);
	const [timer, setTimer] = useState<any>(null);
	const [startingTime, setStartingTime] = useState<any>(null);
	const [timeOut, setTimeOut] = useState<any>(false);
	useEffect(() => {
		if (pos_data?.data?.pay_via_pos.status === 'PENDING') {
			setStartingTime(new Date());
			setTimer(
				setInterval(() => {
					getTransaction({
						variables: {
							id: pos_data?.data?.pay_via_pos?._id,
						},
					});
				}, 1000),
			);
		}

		return () => clearTimeout(timer);
	}, [pos_data]);

	if (!timeOut && (startingTime && (new Date()?.getTime() - startingTime?.getTime()) / 1500) >= 100) {
		setTimeOut(true);
		clearTimeout(timer);
	}

	useEffect(() => {
		if (data?.school?.pos_transaction?.status === 'FAILED') {
			clearTimeout(timer);
			setPosModal(false);
			getInvoice({
				variables: {
					invoice_id: data?.school?.pos_transaction?.invoice,
				},
			});
			setPosTransactionFailedModal(true);
		}
		if (data?.school?.pos_transaction?.status === 'SUCCESS') {
			clearTimeout(timer);
			setPosModal(false);
			getInvoice({
				variables: {
					invoice_id: data?.school?.pos_transaction?.invoice,
				},
			});
			setInvoiceModal(true);
		}
	}, [data]);

	return (
		<>
			<Modal open={showModal} setOpen={setShowModal} title='Collect Fee' className='max-w-5xl h-full'>
				<BasicDetails classname={''} isEdit={false} student={student} student_id={student && student._id} />
				<InstallmentListSection
					setInvoiceModal={setInvoiceModal}
					setPosModal={setPosModal}
					set_pos_data={set_pos_data}
					setShowModal={setShowModal}
					student={student}
					student_id={student && student._id}
					setStudents={setStudents}
					section_id={section_id}
					get_collection={get_collection}
					set_invoice_data={set_invoice_data}
				/>
			</Modal>
			<Modal className='max-w-3xl h-[25rem]' open={posModal} setOpen={setPosModal}>
				<div className='flex justify-center items-center w-full h-full px-10'>
					<div className='w-full rounded-lg flex justify-center flex-col items-center'>
						{!timeOut ? (
							<>
								<img src={posPendingStatus} alt='pending....' />
								<h2 className=' text-2xl font-medium text-edvion_black my-4'>Processing Your Payment</h2>
								<div className='bg-gray-400 h-[2px] w-full max-w-md'>
									<div
										className='bg-[#6F6AF8] h-full'
										style={{ width: `${startingTime && (new Date()?.getTime() - startingTime?.getTime()) / 1500}%` }}
									></div>
								</div>
								<p className=' text-xl font-medium text-edvion_black text-center max-w-md my-4'>
									We are confirming your payment. Please wait for a few minutes.{' '}
								</p>
							</>
						) : (
							<>
								<img src={posFailedStatus} alt='failed....' />
								<h2 className=' text-2xl font-medium text-edvion_black my-4'>Oops! It’s a Timeout </h2>

								<p className=' text-xl font-medium text-edvion_black text-center max-w-xl my-4'>
									Please visit the POS reports section to check the status of the transaction.
								</p>
								<p className=' text-base font-normal text-[#959595] text-center my-4'>
									Click Here to go to POS report.
								</p>
							</>
						)}
					</div>
				</div>
			</Modal>
			<Modal className='max-w-3xl h-full' open={posTransactionFailedModal} setOpen={setPosTransactionFailedModal}>
				<PosPaymentFailure data={invoice} />
			</Modal>
			<Modal open={invoceModal} setOpen={setInvoiceModal} title='Invoice' className='max-w-5xl'>
				<>
					{student && <BasicDetails student={student} student_id={student && student._id} />}

					<div className='w-full py-20 px-20 text-3xl text-center text-green-500  font-semibold'>
						<div>
							<div className='mb-2'>
								<i className='fa-sharp fa-solid fa-circle-check text-5xl'></i>
							</div>
							Payment Successfull!
							<p className=' mt-1 font-light text-sm text-gray-500'>
								Transaction Number: {invoice?.school && invoice?.school?.invoice?._id}{' '}
								{invoice_data?.data?.mark_collected?._id && invoice_data?.data?.mark_collected?._id}
							</p>
						</div>

						<button
							onClick={() => {
								set_show_receipt(true);
								setInvoiceModal(false);
							}}
							className='text-violet-500  text-base mt-2 cursor-pointer border border-voilet-500 px-6 py-2 rounded-lg'
						>
							Show Receipt
							<span className='ml-1'>
								<i className='fa-solid fa-file-invoice'></i>
							</span>
						</button>
					</div>
				</>
			</Modal>
			<Modal
				open={show_receipt}
				setOpen={set_show_receipt}
				className='max-w-[50rem] w-full'
				title={'Transaction Details'}
			>
				{invoice_data && student && (
					<InvoicePDF
						tId={invoice_data?.data?.mark_collected?._id}
						invoice_data={invoice_data?.data?.mark_collected}
						show_receipt={false}
						school={student && student?.school}
					/>
				)}
				{invoice && student && (
					<InvoicePDF
						tId={invoice?.school?.invoice?._id}
						invoice_data={invoice?.school?.invoice}
						show_receipt={false}
						school={student && student?.school}
					/>
				)}
			</Modal>
		</>
	);
};

export default CollectFeeModal;
