import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { dashboardContext } from '../../Dashboard';

// @ts-ignore
// @ts-ignore
import adminEditIcon from '../../assets/adminediticon.svg';
// @ts-ignore
import actionIcon from '../../assets/actionicon.svg';
// @ts-ignore
import editAccessCross from '../../assets/editaccesscross.svg';
// @ts-ignore
import editCross from '../../assets/editportalcross.svg';
import AddEditModal from './components/AddEditModal';
import { Access } from './AccessEnum';
import Modal from '../../../../components/Modal/Modal';
import { Link } from 'react-router-dom';
import backIcon from '../../assets/backicon.svg';
import BackButton from '../../../../components/BackButton/BackButton';
import { toast } from 'react-toastify';
import { Mixpanel } from '../../../../util/mixpanel';
const options = ['Super Admin', 'Admin', 'Management Head', 'Management Staff'];

// const getAccessFromRole = (role: string) => {
// 	if (role === options[0]) return Access.SUPER;
// 	if (role === options[1]) return Access.ALL_ACCESS;
// 	if (role === options[2]) return Access.MANAGEMENT;
// 	if (role === options[3]) return Access.STAFF;
// };
const getRoleFromAccess = (access: Access) => {
	if (access === Access.SUPER) return options[0];
	if (access === Access.ALL_ACCESS) return options[1];
	if (access === Access.MANAGEMENT) return options[2];
	if (access === Access.STAFF) return options[3];
};
// const getAllAdmins = async () => {
// 	var myHeaders = new Headers();
// 	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

// 	var requestOptions: RequestInit = {
// 		method: 'GET',
// 		headers: myHeaders,
// 		redirect: 'follow',
// 	};

// 	return await (await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/admins', requestOptions)).json();
// };

const createAdmin = async (data: any) => {
	const { name, email_id, access, phone_number } = data;
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify({
		name: name,
		email_id: email_id,
		role: access,
		phone_number: phone_number,
	});

	var requestOptions: RequestInit = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	let res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/create-admin', requestOptions);
	if (res.ok) {
		toast.success('member is created');
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
		toast.error(message.message);
	}
};
const editAdmin = async (data: any) => {
	const { id, name, email_id, access, phone_number } = data;
	console.log(data);

	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify({
		admin_id: id,
		name: name,
		email_id: email_id,
		access: access,
		phone_number: phone_number,
	});

	var requestOptions: RequestInit = {
		method: 'PATCH',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	let res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/admin', requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

const accessUpdate = async (data: any) => {
	const { id, access } = data;
	console.log(id);

	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify({
		admin_id: id,
		access: access,
	});

	var requestOptions: RequestInit = {
		method: 'PATCH',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	let res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/admin', requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

const deleteAdmin = async (id: any) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	var requestOptions: RequestInit = {
		method: 'DELETE',
		headers: myHeaders,
		redirect: 'follow',
	};

	let res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/school/admin/${id}`, requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

const PortalUser = ({
	setAddMember,
	setEditMember,
	setDeleteMember,
	portalUser,
	setPortalUsers,
	currentUser,
	school,
}: any) => {
	const [isEditAccess, setEditAccess] = useState(false);
	const [optionToggle, setOptionToggle] = useState(false);
	// console.log(school.school.name);

	return (
		// <div className='mb-[16px] border-solid border-2  border-[#ECEDFB]   rounded-[25px] text-edvion_black bg-[#ECEDFB] even:bg-[#ECEDFB] odd:bg-[#F8FAFB]'>
		<div
			className='grid grid-cols-5 grid-body items-center p-3 mx-5 my-2 odd:bg-[#ECEDFB] even:bg-[#F8FAFB] odd:border-none even:border border-[#ECEDFB] rounded-lg'
			onMouseLeave={() => setOptionToggle(false)}
		>
			<div className='text-center truncate'>{portalUser.name}</div>
			<div className='text-center truncate'>{portalUser.email_id}</div>
			{/* <div>EM001</div> */}
			<div className='text-center truncate'>{portalUser.phone_number}</div>
			{/* <div>{portalUser.access}</div> */}
			{/* <div>Active</div> */}
			<div className='relative mx-auto text-center' onMouseLeave={() => setEditAccess(false)}>
				<div className='flex  items-center'>
					<div>{getRoleFromAccess(portalUser.access)}</div>
					{currentUser._id !== portalUser._id && currentUser.access === Access.SUPER ? (
						<div onClick={() => setEditAccess(!isEditAccess)} className='p-1 ml-1 cursor-pointer'>
							<img src={adminEditIcon} alt='' />
						</div>
					) : (
						''
					)}
				</div>
				{isEditAccess ? (
					<AccessLevelEdit
						role={portalUser.access}
						setEditAccess={setEditAccess}
						portalUserId={portalUser._id}
						setPortalUsers={setPortalUsers}
						school={school?.school}
						currentUser={currentUser}
					/>
				) : (
					''
				)}
			</div>
			{currentUser._id !== portalUser._id && currentUser.access === Access.SUPER ? (
				<div
					onClick={() => setOptionToggle(!optionToggle)}
					className='p-1 text-center  relative mx-auto cursor-pointer'
				>
					<img src={actionIcon} alt='' />
					{optionToggle ? (
						<EditDelete
							setAddMember={setAddMember}
							setEditMember={setEditMember}
							setDeleteMember={setDeleteMember}
							portalUserId={portalUser._id}
						/>
					) : (
						''
					)}
				</div>
			) : (
				''
			)}
		</div>
		// </div>
	);
};
const PortalAccessUsers = ({
	isAddMember,
	isEditMember,
	isDeleteMember,
	setAddMember,
	setEditMember,
	setDeleteMember,
	portalUsers,
	setPortalUsers,
	currentUser,
}: any) => {
	const { school } = useContext(dashboardContext);

	// const [portalUsers, setPortalUsers]: [any, any] = useState(adminUsers);
	return (
		<>
			<div className='table w-full shadow p-5 rounded-lg my-2 text-edvion_black'>
				<div className='grid grid-cols-5 grid-header p-3 font-semibold m-5 bg-[#ECEDFB] rounded-t-lg text-violet-900'>
					<div className='text-center'>Name</div>
					<div className='text-center'>Email ID</div>
					{/* <div>Employment ID</div> */}
					<div className='text-center'>Mobile no</div>
					{/* <div>Role</div> */}
					{/* <div>Status</div> */}
					<div className='text-center'>Level of Access</div>
					<div className='text-center'>Action</div>
				</div>
				{portalUsers.map((portaluser: any, i: any) => {
					return (
						<PortalUser
							key={portalUsers._id}
							setAddMember={setAddMember}
							setEditMember={setEditMember}
							setDeleteMember={setDeleteMember}
							portalUser={portaluser}
							setPortalUsers={setPortalUsers}
							currentUser={currentUser}
							school={school}
						/>
					);
				})}
				{/* <div className={`${isAddMember || isEditMember.isEdit || isDeleteMember.isDelete ? 'scale-1' : 'scale-0'}`}> */}
				{/* {isAddMember ? ( */}
				<Modal
					children={
						<AddNewMember
							setAddMember={setAddMember}
							currentUser={currentUser}
							school={school}
							setPortalUsers={setPortalUsers}
						/>
					}
					title='Add New Member'
					open={isAddMember}
					setOpen={setAddMember}
					className={'max-w-sm w-full'}
				/>
				{/* ) : isEditMember.isEdit ? ( */}
				<Modal
					children={
						<EditMemeber
							isEditMember={isEditMember}
							setEditMember={setEditMember}
							portalUser={portalUsers.filter((user: any) => user._id === isEditMember.id)}
							setPortalUsers={setPortalUsers}
							currentUser={currentUser}
							school={school}
						/>
					}
					title=''
					open={isEditMember.isEdit}
					setOpen={(res: any) => {
						setEditMember({ isEdit: res, id: '' });
					}}
					className={'max-w-sm w-full'}
				/>
				{/* ) : isDeleteMember.isDelete ? ( */}
				<Modal
					children={
						<DeleteMember setDeleteMember={setDeleteMember} id={isDeleteMember.id} setPortalUsers={setPortalUsers} />
					}
					title=''
					open={isDeleteMember.isDelete}
					setOpen={(res: any) => setDeleteMember({ isDelete: res, id: '' })}
					className={'max-w-sm w-full'}
				/>
				{/* ) : ( */}

				{/* )} */}
				{/* <div className='z-[11] fixed top-0 bottom-0 left-0 right-0 bg-black opacity-50'></div> */}
				{/* </div> */}
			</div>
		</>
	);
};
const AccessName = ({ option, selectedOption, setSelectedOption }: any) => {
	return (
		<div className='mt-6 px-6 text-[#959595]'>
			<label className='flex items-center'>
				<input
					onChange={() => {
						setSelectedOption(option.role);
					}}
					className=' w-4 h-4 cursor-pointer'
					type='radio'
					name='access'
					value={option.name}
					checked={selectedOption === option.role}
				/>
				<div className='px-4'>{option.name}</div>
			</label>
		</div>
	);
};
const AccessLevelEdit = ({ setEditAccess, role, portalUserId, setPortalUsers, school, currentUser }: any) => {
	const [selectedOption, setSelectedOption] = useState(role);

	const options = [
		// { role: Access.SUPER, name: 'Super Admin' },
		{ role: Access.ALL_ACCESS, name: 'Admin' },
		{ role: Access.MANAGEMENT, name: 'Management Head' },
		{ role: Access.STAFF, name: 'Management Staff' },
	];

	async function handleAccessUpdate() {
		try {
			let res = await accessUpdate({ id: portalUserId, access: selectedOption });
			if (res instanceof Error) {
				toast.error('something went wrong!');
			} else {
				setPortalUsers((prev: any) => {
					let user = prev.findIndex((obj: any) => obj._id === portalUserId);
					prev[user].access = selectedOption;
					return prev;
				});
				Mixpanel.track('change_member_role', {
					user_name: currentUser?.name,
					schoolId: currentUser?.school_id,
					school: school?.name,
				});
				setEditAccess(false);
			}
		} catch (err) {
			console.log(err);
		}
	}
	return (
		<div className={`z-10 absolute  top-6  right-2 w-80 h-fit bg-[#F8FAFB] rounded-lg  shadow`}>
			<div className='px-4 pt-4 mb-2 flex items-center justify-between'>
				<div>Edit Level of Access</div>
				<div onClick={() => setEditAccess(false)} className=' cursor-pointer'>
					<img src={editAccessCross} alt='' />
				</div>
			</div>
			<hr />
			<div className='mt-5'>
				{options.map((option, index) => {
					return (
						<AccessName
							key={index}
							option={option}
							selectedOption={selectedOption}
							setSelectedOption={setSelectedOption}
						/>
					);
				})}
			</div>
			<div className='mt-6 mb-3 mr-4 text-right'>
				<button onClick={handleAccessUpdate} className='w-[108px] py-1 px-2 rounded-lg bg-[#6F6AF8] text-white'>
					Update
				</button>
			</div>
		</div>
	);
};

const EditDelete = ({ setAddMember, setEditMember, setDeleteMember, portalUserId }: any) => {
	return (
		<div className='z-10 absolute   bottom-0 right-5  w-36 rounded-lg shadow bg-[#F8FAFB]'>
			<div
				onClick={() => {
					setEditMember({ isEdit: true, id: portalUserId });
				}}
				className='py-2 px-2 hover:bg-slate-200 rounded-t-lg '
			>
				Edit
			</div>
			<hr />
			<div
				onClick={() => {
					setDeleteMember({ isDelete: true, id: portalUserId });
				}}
				className='py-2 px-2 text-red-400 hover:bg-slate-200  rounded-b-lg'
			>
				Delete
			</div>
		</div>
	);
};

const AddNewMember = ({ setAddMember, setPortalUsers, currentUser, school }: any) => {
	const [adminData, setAdminData]: [any, any] = useState({});

	async function handleAddAdmin() {
		var res = await createAdmin(adminData);
		if (res.statusCode === 400) {
			setAddMember(true);
		} else {
			setAddMember((prev: any) => !prev);
			adminData._id = res._id;
			Mixpanel.track('add_new_member', {
				user_name: currentUser?.name,
				schoolId: currentUser?.school_id,
				school: school?.school?.name,
			});
			setPortalUsers((prev: any) => [...prev, adminData]);
		}
	}
	return (
		<>
			{/* <div className=' z-[12] absolute  transition duration-300 ease-out top-[50%] left-[50%]  translate-x-[-50%] translate-y-[-50%] w-1/2 bg-[#F8FAFB] rounded-2xl'> */}
			{/* <div className='py-2 px-2  text-center '> */}
			{/* <div className='text-base font-semibold leading-6 text-gray-900 text-center'>Add New Member</div> */}
			{/* <div onClick={() => setAddMember((prev: any) => !prev)} className='absolute top-0 right-0 p-7 cursor-pointer'>
					<img src={editCross} alt='' />
				</div> */}
			{/* </div> */}
			{/* <hr className='px-12' /> */}
			<AddEditModal setAdminData={setAdminData} portalUser={[]} />
			<div className='mt-5 mb-10 text-center'>
				<button onClick={handleAddAdmin} className='py-2 px-16 rounded-md bg-[#6F6AF8] text-white'>
					Add
				</button>
			</div>
			{/* </div> */}
		</>
	);
};

const EditMemeber = ({ isEditMember, setEditMember, portalUser, setPortalUsers, currentUser, school }: any) => {
	const [adminData, setAdminData]: [any, any] = useState({});
	async function handleEdit() {
		var res = await editAdmin(adminData);
		if (res instanceof Error) {
			console.log('something went wrong!');
		} else {
			setEditMember({ isEdit: false, id: '' });
			setPortalUsers((prev: any) => {
				let user = prev.findIndex((obj: any) => obj._id === adminData.id);
				prev[user].name = adminData.name;
				prev[user].access = adminData.access;
				prev[user].phone_number = adminData.phone_number;
				prev[user].email_id = adminData.email_id;
				return prev;
			});
			Mixpanel.track('edit_member', {
				user_name: currentUser?.name,
				schoolId: currentUser?.school_id,
				school: school?.school?.name,
			});
		}
	}
	return (
		<>
			{/* <div className=' z-10 absolute  transition duration-300 ease-out top-[50%] left-[50%]  translate-x-[-50%] translate-y-[-50%] w-1/2 bg-[#F8FAFB] rounded-2xl'> */}
			{/* <div className='py-2 px-2  text-center '> */}
			<div className='text-base font-semibold leading-6 text-gray-900 text-center'>Edit</div>
			{/* <div
					onClick={() => setEditMember({ isEdit: false, id: '' })}
					className='absolute top-0 right-0 p-7 cursor-pointer'
				>
					<img src={editCross} alt='' />
				</div> */}
			{/* </div> */}
			{/* <hr className='px-12' /> */}
			<AddEditModal setAdminData={setAdminData} portalUser={portalUser} />
			<div className='mt-5 mb-10 text-center'>
				<button onClick={handleEdit} className=' py-2 px-16 rounded-lg bg-[#6F6AF8] text-white'>
					Update
				</button>
			</div>
			{/* </div> */}
		</>
	);
};

const DeleteMember = ({ setDeleteMember, id, setPortalUsers }: any) => {
	async function handleDelete() {
		try {
			let res = await deleteAdmin(id);
			if (res instanceof Error) {
				console.log('something went wrong!');
			} else {
				setDeleteMember({ isDelete: false, id: '' });
				setPortalUsers((prev: any) => [...prev.filter((user: any) => user._id !== id)]);
			}
		} catch (err) {
			console.log(err);
		}
	}
	return (
		<>
			{/* <div className='h-2/5 z-10 absolute  transition duration-300 ease-out top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-1/2 bg-[#F8FAFB] rounded-2xl'> */}

			<div className='text-base font-semibold leading-6 text-gray-900 text-center'>Delete</div>
			<div className='w-11/12 m-auto text-center'>
				<div className='mt-10'>Are you sure you want to delete this member?</div>
				<div className='mt-10 mb-10 grid grid-cols-2 gap-4'>
					<button
						onClick={() => setDeleteMember({ isDelete: false, id: '' })}
						className='py-1 px-4 rounded-md bg-[#F8FAFB] text-red-400 border-2 border-red-200 outline-none'
					>
						Cancel
					</button>
					<button onClick={handleDelete} className='py-2 px-4 rounded-md bg-red-400 text-white outline-none'>
						Delete
					</button>
				</div>
			</div>
		</>
	);
};

const PortalAccess = ({ portalUsers, setPortalUsers, currentUser }: any) => {
	const [isAddMember, setAddMember] = useState(false);
	const [isEditMember, setEditMember] = useState({ isEdit: false, id: '' });
	const [isDeleteMember, setDeleteMember] = useState({ isDelete: false, id: '' });
	const { user } = useContext(dashboardContext);
	const [csv_link, set_csv_link] = useState('');

	useEffect(() => {
		const rows = [
			['Name', 'Email ID', 'Phone Number', 'Level Of Access'],
			...(portalUsers &&
				portalUsers.map((user: any) => {
					return [user.name, user.email_id, user.phone_number, user.access];
				})),
		];

		let csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
		var encodedUri = encodeURI(csvContent);
		set_csv_link(encodedUri);
	}, []);

	return (
		<div className='text-edvion_black w-full pr-6'>
			<div className='mt-2 ml-2 flex items-center py-2'>
				{/* <Link to={'..'}>
					<div className='pr-4'>
						<img src={backIcon} alt='' />
					</div>
				</Link>
				<div className=' text-2xl font-semibold text-violet-600'>Portal Access</div> */}
				<BackButton label='Portal Access' />
			</div>
			{/* <div className="mt-6 flex justify-between items-center">
        <div className="rounded-3xl flex  py-3 px-6 bg-gray-100">
          <img src={searchIcon} alt="" />
          <input className="w-96  outline-0 ml-4 bg-gray-100" type="text" placeholder="search by student name, enrollment number" />
        </div>
        <div>
          <div className="py-2 px-4 bg-[#F8FAFB] rounded-lg border-2 border-[#6F6AF8] flex  cursor-pointer items-center">
            <div>
              <img src={filterIcon} alt="" />
            </div>
            <div className="text-[16px] ml-4">Add Filter</div>
          </div>
        </div>
      </div> */}

			<div className='mr-2'>
				<div className='flex items-center justify-end '>
					{user.access === Access.SUPER || user.access === Access.ALL_ACCESS ? (
						<div className='ml-2 text-right '>
							<button
								onClick={() => {
									setAddMember((prev) => !prev);
								}}
								className=' bg-[#6F6AF8] text-white rounded-lg font-medium py-2 px-5'
							>
								Add New Member
							</button>
						</div>
					) : (
						''
					)}
				</div>

				<PortalAccessUsers
					isAddMember={isAddMember}
					setAddMember={setAddMember}
					isEditMember={isEditMember}
					isDeleteMember={isDeleteMember}
					setEditMember={setEditMember}
					setDeleteMember={setDeleteMember}
					portalUsers={portalUsers}
					setPortalUsers={setPortalUsers}
					currentUser={currentUser}
				/>
			</div>
			{/* <div className={`${isAddMember || isEditMember.isEdit || isDeleteMember ? 'scale-1' : 'scale-0'}`}>
				{isAddMember ? (
					<AddNewMember setAddMember={setAddMember} />
				) : isEditMember ? (
					<EditMemeber setEditMember={setEditMember} />
				) : isDeleteMember ? (
					<DeleteMember setDeleteMember={setDeleteMember} />
				) : (
					''
				)}
				<div className='z-0 fixed top-0 bottom-0 left-0 right-0 bg-black opacity-50'></div>
			</div> */}
		</div>
	);
};

export default PortalAccess;
