import React, { useState } from 'react';
import KmRateCard from '../components/KmRateCard';
import OtherFields from '../components/OtherFields';
import leftarrow from '../../../../../assets/leftarrow.svg';
import rightarrow from '../../../../../assets/rightarrow.svg';
import { TransportFeeType } from '../TransportFee';
function FeePerKmTab({ set_transport_modal, otherField, setOtherField }: any) {
	const [activeTab, setActiveTab] = useState(1);
	const [data, setData] = useState({
		breakPoint: [],
		fees: [0],
		type: TransportFeeType.KM_BASED,
	});

	return (
		<div className='flex space-x-4'>
			<div className='w-1/2'>
				<div className='flex justify-between flex-col'>
					<button
						onClick={() => {
							setActiveTab(1);
						}}
						className={`mt-4 flex justify-between items-center  rounded-md shadow cursor-pointer ${
							activeTab === 1 ? 'bg-[#F8FAFB]' : 'bg-[#DADBFC]'
						} `}
					>
						<div className='py-2 px-6 text-md text-edvion_black'>Add Rate Card</div>
						<div className='py-2 pr-10'>
							{activeTab === 1 ? <img src={leftarrow} alt='' /> : <img src={rightarrow} alt='' />}
						</div>
					</button>
					<button
						onClick={() => {
							if (otherField === true) {
								setActiveTab(2);
							}
						}}
						className={`mt-4 flex justify-between items-center  rounded-md shadow ${
							activeTab === 2 ? 'bg-[#F8FAFB]' : 'bg-[#DADBFC]'
						} ${otherField === true ? ' cursor-pointer' : ' cursor-not-allowed'}`}
					>
						<div className='py-2 px-6 text-md text-edvion_black'>Select Other Fields</div>
						<div className='py-2 pr-10'>
							{activeTab === 2 ? <img src={leftarrow} alt='' /> : <img src={rightarrow} alt='' />}
						</div>
					</button>
				</div>
			</div>
			<div className='w-1/2'>
				<div className={activeTab === 1 ? ' block' : ' hidden'}>
					<KmRateCard setData={setData} data={data} setActiveTab={setActiveTab} setOtherField={setOtherField} />
				</div>

				<div className={activeTab === 2 ? ' block' : ' hidden'}>
					<OtherFields setData={setData} data={data} set_transport_modal={set_transport_modal} />
				</div>
			</div>
		</div>
	);
}

export default FeePerKmTab;
