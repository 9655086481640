import React, { useState } from 'react';
import { useEffect } from 'react';
import leftarrow from '../../../../assets/leftarrow.svg';
import rightarrow from '../../../../assets/rightarrow.svg';

import FeeDetails from './FeeDetails';
import DocumentDetails from './DocumentDetails';
import AddressDetails from './AddressDetails';
import ParentsDetails from './ParentsDetails';
import ParentsBankDetails from './ParentsBankDetails';
import PreviousSchoolDetails from './PreviousSchoolDetails';
import MedicalDetails from './MedicalDetails';
import AdditionDetails from './AdditionDetails';
// @ts-ignore
const DetailOptions = ({
	studentId,
	setSelectedComponent,
	setStudent,
	getStudent,
	updateStudent,
	studentData,
	installments,
}: any) => {
	const [isFeeSelected, setFeeSelected] = useState(true);
	const [isDocumentSelected, setDocumentSelected] = useState(false);
	const [isAddressSelected, setAddressSelected] = useState(false);
	const [isParentsSelected, setParentsSelected] = useState(false);
	const [isParentsBankSelected, setParentsBankSelected] = useState(false);
	const [isPreviousSchoolSelected, setPreviousSchoolSelected] = useState(false);
	const [isMedicalDetialsSelected, setMedicalDetialsSelected] = useState(false);
	const [isAdditionDetailsSelected, setAdditionDetailsSelected] = useState(false);

	useEffect(() => {
		setSelectedComponent(
			<FeeDetails
				installments={installments}
				studentId={studentId}
				studentdata={studentData}
				setFeeSelected={setFeeSelected}
			/>,
		); // eslint-disable-next-line
	}, []);
	return (
		<div className=' w-full mr-4'>
			<div
				onClick={() => {
					setSelectedComponent(
						<FeeDetails
							getStudent={getStudent}
							updateStudent={updateStudent}
							studentId={studentId}
							studentdata={studentData}
							setFeeSelected={setFeeSelected}
							installments={installments}
						/>,
					);
				}}
				className={`mt-4 flex justify-between items-center  rounded-md shadow cursor-pointer ${
					isFeeSelected ? 'bg-[#F8FAFB]' : 'bg-[#DADBFC]'
				} `}
			>
				<div className='py-2 px-6 text-md text-edvion_black'>Fee and Installment Details</div>
				<div className='py-2 pr-10'>
					{isFeeSelected ? <img src={leftarrow} alt='' /> : <img src={rightarrow} alt='' />}
				</div>
			</div>
			<div
				onClick={() => {
					setSelectedComponent(
						<DocumentDetails
							getStudent={getStudent}
							updateStudent={updateStudent}
							setDocumentSelected={setDocumentSelected}
						/>,
					);
				}}
				className={`mt-4 flex justify-between items-center  rounded-md shadow cursor-pointer ${
					isDocumentSelected ? 'bg-[#F8FAFB]' : 'bg-[#dadbfc]'
				} `}
			>
				<div className='py-2 px-6 text-md text-edvion_black'>Document Details</div>
				<div className='py-2 pr-10'>
					{isDocumentSelected ? <img src={leftarrow} alt='' /> : <img src={rightarrow} alt='' />}
				</div>
			</div>
			<div
				onClick={() => {
					setSelectedComponent(
						<AddressDetails
							getStudent={getStudent}
							updateStudent={updateStudent}
							setAddressSelected={setAddressSelected}
						/>,
					);
				}}
				className={`mt-4 flex justify-between items-center  rounded-md shadow cursor-pointer ${
					isAddressSelected ? 'bg-[#F8FAFB]' : 'bg-[#dadbfc]'
				} `}
			>
				<div className='py-2 px-6 text-md text-edvion_black'>Address Details</div>
				<div className='py-2 pr-10'>
					{isAddressSelected ? <img src={leftarrow} alt='' /> : <img src={rightarrow} alt='' />}
				</div>
			</div>
			<div
				onClick={() => {
					setSelectedComponent(
						<ParentsDetails
							getStudent={getStudent}
							updateStudent={updateStudent}
							setParentsSelected={setParentsSelected}
						/>,
					);
				}}
				className={`mt-4 flex justify-between items-center  rounded-md shadow cursor-pointer ${
					isParentsSelected ? 'bg-[#F8FAFB]' : 'bg-[#dadbfc]'
				} `}
			>
				<div className='py-2 px-6 text-md text-edvion_black'>Parent's/Guardian Details</div>
				<div className='py-2 pr-10'>
					{isParentsSelected ? <img src={leftarrow} alt='' /> : <img src={rightarrow} alt='' />}
				</div>
			</div>
			<div
				onClick={() => {
					setSelectedComponent(
						<ParentsBankDetails
							getStudent={getStudent}
							updateStudent={updateStudent}
							setParentsBankSelected={setParentsBankSelected}
						/>,
					);
				}}
				className={`mt-4 flex justify-between items-center  rounded-md shadow cursor-pointer ${
					isParentsBankSelected ? 'bg-[#F8FAFB]' : 'bg-[#dadbfc]'
				} `}
			>
				<div className='py-2 px-6 text-md text-edvion_black'>Parent's Bank Details</div>
				<div className='py-2 pr-10'>
					{isParentsBankSelected ? <img src={leftarrow} alt='' /> : <img src={rightarrow} alt='' />}
				</div>
			</div>
			<div
				onClick={() => {
					setSelectedComponent(
						<PreviousSchoolDetails
							getStudent={getStudent}
							updateStudent={updateStudent}
							setPreviousSchoolSelected={setPreviousSchoolSelected}
						/>,
					);
				}}
				className={`mt-4 flex justify-between items-center  rounded-md shadow cursor-pointer ${
					isPreviousSchoolSelected ? 'bg-[#F8FAFB]' : 'bg-[#dadbfc]'
				} `}
			>
				<div className='py-2 px-6 text-md text-edvion_black'>Previous Institute Details</div>
				<div className='py-2 pr-10'>
					{isPreviousSchoolSelected ? <img src={leftarrow} alt='' /> : <img src={rightarrow} alt='' />}
				</div>
			</div>
			<div
				onClick={() => {
					setSelectedComponent(
						<MedicalDetails
							getStudent={getStudent}
							updateStudent={updateStudent}
							setMedicalDetialsSelected={setMedicalDetialsSelected}
						/>,
					);
				}}
				className={`mt-4 flex justify-between items-center  rounded-md shadow cursor-pointer ${
					isMedicalDetialsSelected ? 'bg-[#F8FAFB]' : 'bg-[#dadbfc]'
				} `}
			>
				<div className='py-2 px-6 text-md text-edvion_black'>Medical Details</div>
				<div className='py-2 pr-10'>
					{isMedicalDetialsSelected ? <img src={leftarrow} alt='' /> : <img src={rightarrow} alt='' />}
				</div>
			</div>
			<div
				onClick={() => {
					setSelectedComponent(
						<AdditionDetails
							getStudent={getStudent}
							updateStudent={updateStudent}
							setAdditionDetailsSelected={setAdditionDetailsSelected}
						/>,
					);
				}}
				className={`mt-4 flex justify-between items-center  rounded-md shadow cursor-pointer ${
					isAdditionDetailsSelected ? 'bg-[#F8FAFB]' : 'bg-[#dadbfc]'
				} `}
			>
				<div className='py-2 px-6 text-md text-edvion_black'>Additional Details</div>
				<div className='py-2 pr-10'>
					{isAdditionDetailsSelected ? <img src={leftarrow} alt='' /> : <img src={rightarrow} alt='' />}
				</div>
			</div>

			{/* 
			<div className='mt-4 flex justify-between items-center  rounded-md shadow cursor-pointer bg-[#dadbfc]'>
				<div className='py-2 px-6 text-md'>Additional Details</div>
				<div className='py-2 pr-10'>{false ? <img src={leftarrow} alt='' /> : <img src={rightarrow} alt='' />}</div>
			</div>
			 */}
		</div>
	);
};

export default DetailOptions;
