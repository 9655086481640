import React, { useEffect, useState } from 'react';
import SelectClassSection from '../../../../../../components/SelectClassSection/SelectClassSection';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import Table from '../../../../../../components/Table/Table';
import { Link } from 'react-router-dom';
import BackButton from '../../../../../../components/BackButton/BackButton';
import { gql, useQuery } from '@apollo/client';

function StudentWiseReport() {
	// const [students, setStudents] = useState<any>([]);
	const [section_id, set_section_id] = useState(null);
	const [clas, set_clas] = useState(null);
	const [section, set_section] = useState(null);
	const [search_input, set_search_input] = useState('');
	const {
		loading,
		data: students,
		error,
	} = useQuery(gql`
	{
		school{
		  section_fee_report(class:"${clas}", section:"${section}"){
			total_amount
			total_amount_paid
			student{
			  name
			  father_name
			  school_generated_id
			  _id
			}
		  }
		  }
	  }`);

	return (
		<div>
			<BackButton label='Student Wise Report' />

			<div className='w-1/2'>
				<SelectClassSection
					className='flex'
					onChange={(s: any) => {
						set_clas(s.class);
						set_section(s.section);
					}}
				></SelectClassSection>
			</div>
			<p className='text-[#fc4343] text-xs ml-5'>
				All Amounts shown here are calculated without considering fines. Please refer to the Fine Wise Report to view
				the fines applied.*
			</p>
			{
				<LoadAndRender loading={loading}>
					{students && (
						<div className='w-full'>
							<Table
								heading={
									<div className='bg-[#817DFF] mt-2 max-w-md h-12 mb-2 ml-2 items-center bg-opacity-10 flex py-3 px-6 rounded-[24px]'>
										<i className='text-edvion_black text-opacity-50 fa-solid fa-magnifying-glass'></i>
										<input
											onChange={(e) => set_search_input(e.target.value)}
											type='text'
											placeholder='Search by student name, enrolment number'
											className='ml-4 bg-transparent focus:outline-none w-full placeholder:font-normal placeholder:text-base'
										/>
									</div>
								}
								data={[
									['Student Details', 'Father Name', 'School Generated Id', 'Total Fee', 'Total Paid', 'Total Unpaid'],

									...JSON.parse(JSON.stringify(students))
										.school.section_fee_report.sort((a: any, b: any) => {
											return a.student.name.localeCompare(b.student.name);
										})
										.filter((s: any) =>
											[s.student.name, s.student.father_name, s.student.school_generated_id]
												.join(',')
												.toLowerCase()
												.includes(search_input.toLowerCase()),
										)
										.map((r: any) => [
											<Link to={'/student_profile/' + r.student._id}>
												<span className='text-base text-violet-500'>{r.student.name}</span>
											</Link>,
											r.student.father_name,
											r.student.school_generated_id,
											<h2>{'₹ ' + r.total_amount.toLocaleString('hi')}</h2>,
											<h2 className='text-[#2EB418]'>{'₹ ' + r.total_amount_paid.toLocaleString('hi')}</h2>,
											<h2 className='text-[#FC4343]'>
												{'₹ ' + (r.total_amount - r.total_amount_paid).toLocaleString('hi')}
											</h2>,
										]),
								]}
								footer={<div></div>}
							/>
						</div>
					)}
				</LoadAndRender>
			}
		</div>
	);
}

export default StudentWiseReport;
