import { gql } from '@apollo/client';

export const GET_INSTALLMENT_WISE_REPORT = gql`
	{
		school {
			instllment_wise_fee_report {
				due_date
				total_amount
				total_amount_paid
				total_students
				total_students_paid
			}
		}
	}
`;

export const GET_AVAILED_DISCOUNT_REPORT = gql`
	query GetAvailedDiscountReport($page: Float!) {
		school {
			totalAvailedDiscountPages
			availed_discounts(page: $page) {
				_id
				createdAt
				student {
					name
					father_name
					school_generated_id
					_id
					category
					class
					dob
					gender
					phone_number
					section
				}
				school {
					_id
					name
					phone_number
					email_id
				}
				discount {
					_id
					applicable_on
					createdAt
					discount_type
					name
					value
					fee_head {
						_id
						name
					}
				}
				discount_id
				invoice {
					_id
					fee_breakup
				}
			}
		}
	}
`;
export const GET_CHEQUE_DD_REPORT = gql`
	{
		school {
			cheques {
				_id
				cheque_description
				cheque_number
				clearing_date
				date
				status
				student {
					_id
					name
				}
			}
			demand_drafts {
				_id
				clearing_date
				date
				dd_description
				dd_number
				status
				student {
					_id
					name
				}
			}
		}
	}
`;
export const GET_INDIVIDUAL_FINE_REPORT = gql`
	{
		school {
			individual_fines {
				updatedAt
				student
				name
				is_paid
				createdAt
				amount
				_id
			}
		}
	}
`;
export const MARK_CHEQUE_CLEARED = gql`
	mutation Mark_cheque_cleared($cheque_id: ID!, $clearing_date: DateTime!) {
		mark_cheque_cleared(cheque_id: $cheque_id, clearing_date: $clearing_date) {
			status
			fee_breakup
			createdAt
			_id
		}
	}
`;

export const REVERT_CHEQUE_STATUS = gql`
	mutation Revert_cheque_status($cheque_id: ID!) {
		revert_cheque_status(cheque_id: $cheque_id) {
			status
			fee_breakup
			createdAt
			_id
		}
	}
`;

export const MARK_CHEQUE_BOUNCED = gql`
	mutation Mark_cheque_bounced($cheque_id: ID!) {
		mark_cheque_bounced(cheque_id: $cheque_id) {
			status
			fee_breakup
			createdAt
			_id
		}
	}
`;

export const MARK_DD_PAID = gql`
	mutation Mark_dd_cleared($clearing_date: DateTime!, $dd_id: ID!) {
		mark_dd_cleared(clearing_date: $clearing_date, dd_id: $dd_id) {
			_id
			createdAt
			fee_breakup
			status
		}
	}
`;

export const REVERT_DD_STATUS = gql`
	mutation Revert_dd_status($dd_id: ID!) {
		revert_dd_status(dd_id: $dd_id) {
			status
			fee_breakup
			createdAt
			_id
		}
	}
`;
