import React from 'react';

function ConfirmationBox({ setOpen, funtion, confirmationText, ButtonText }: any) {
	return (
		<div className='w-11/12 m-auto text-center'>
			<div className='mt-10'>Are you sure you want to {confirmationText}?</div>

			<div className='mt-10 mb-10 grid grid-cols-2 gap-4'>
				<button
					onClick={() => setOpen(false)}
					className='py-1 px-4 rounded-md bg-[#F8FAFB] text-red-400 border-2 border-red-200 outline-none'
				>
					Cancel
				</button>

				<button
					onClick={async () => {
						funtion();
						setOpen(false);
					}}
					className={`py-2 text-white px-10 rounded-lg hover:bg-red-400 bg-red-300`}
				>
					{ButtonText}
				</button>
			</div>
		</div>
	);
}

export default ConfirmationBox;
