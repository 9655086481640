import React, { useEffect, useState, useContext } from 'react';
import Input from '../../../../../components/Input/Input';
import Select from '../../../../../components/Select/Select';
import uploadIcon from '../../../assets/uploadicon.svg';
import { Access } from '../AccessEnum';
import { useNavigate } from 'react-router-dom';

import { dashboardContext, getSchool } from '../../../Dashboard';

export const update_school = (data: any) => {
	return new Promise(async (resolve, reject) => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
		myHeaders.append('Content-Type', 'application/json');
		var raw = JSON.stringify(data);
		var requestOptions: RequestInit = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		let res = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/update', requestOptions);
		if (res.ok) {
			const data = await res.json();
			resolve(data);
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
			reject(new Error('something wrong!'));
		}
	});
};

const EditSchoolDetails = ({}: any) => {
	const { classes, set_school, school, user } = useContext(dashboardContext);
	console.log(user.access);
	const schools = school.school;

	const { sections } = useContext(dashboardContext);
	const [id, setId] = useState(schools._id);
	const [name, setName] = useState(schools.name);
	const [type, setType] = useState(schools.school_type);
	const [logo, setLogo] = useState(schools.logo_url);
	// const [classes, setClasses] = useState('');
	// const [teachers, setTeachers] = useState('');
	// const [sections, setSections] = useState('');

	const [students, setStudents] = useState(schools.student_count);
	const [edviron_Reg_Number, setEdviron_Reg_Number] = useState(schools.edviron_id);
	const [DateOfReg, setDateOfReg] = useState(schools.date_of_establishment);
	const [address, setAddress] = useState(schools.address && schools.address.street);
	const [city, setCity] = useState(schools.address && schools.address.city);
	const [state, setState] = useState(schools.address && schools.address.state);
	const [pin, setPin] = useState(schools.address && schools.address.pin);
	// const [vision, setVision] = useState('');
	// const [UDISE, setUDISE] = useState('');
	// const [school_code, setSchoolCode] = useState('');
	const [principalName, setPrincipalName] = useState('');
	const [phone_number, setPhoneNumber] = useState(schools.phone_number);
	const [email, setEmail] = useState(schools.email_id);

	const [account_holder, setAccountHolder] = useState(schools.bank_details && schools.bank_details.account_holder);
	const [account_number, setAccountNumber] = useState(schools.bank_details && schools.bank_details.account_number);
	const [ifsc, set_ifsc] = useState(schools.bank_details && schools.bank_details.ifsc);
	const [fee_collection_date, set_fee_collection_date] = useState(schools.fee_collection_date);
	const [late_fee, set_late_fee] = useState(schools.late_fee);
	const [late_fee_grace_period, set_late_fee_grace_period] = useState(schools.late_fee_grace_period);

	const navigate = useNavigate();

	function handleLogoUpload(e: any) {
		setLogo(e.target.files[0]);
	}

	useEffect(() => {}, [name]);

	function handleUpdate() {
		const formData = new FormData();
		formData.append('file', logo);
		const data = {
			name: name,
			fee_collection_date: fee_collection_date,
			late_fee_grace_period: late_fee_grace_period,
			late_fee: late_fee,
			address: {
				street: address,
				city: city,
				state: state,
				pin: pin,
			},
			bank_details: {
				account_number: account_number,
				ifsc: ifsc,
				account_holder: account_holder,
			},
			phone_number: phone_number,
			email_id: email,
			logo_url: '',
			school_type: type,
			date_of_establishment: DateOfReg,
		};

		update_school(data)
			.then(async (res) => {
				const updatedData = {
					_id: id,
					edviron_id: edviron_Reg_Number,
					student_count: students,
					fee_collection_date: fee_collection_date,
					late_fee_grace_period: late_fee_grace_period,
					late_fee: late_fee,
					__v: 0,

					address: {
						street: address,
						city: city,
						state: state,
						pin: pin,
					},

					bank_details: {
						account_number: account_number,
						ifsc: ifsc,
						account_holder: account_holder,
					},
					date_of_establishment: DateOfReg,
					email_id: email,
					legal_details: {
						structure: 'to be decided',
					},
					logo_url: '',

					name: name,
					phone_number: phone_number,
					school_type: type,
				};
				set_school(await getSchool());
				navigate('/institute_profile', { replace: true });
			})
			.catch((err) => console.log(err));
	}

	return (
		<>
			<div className='text-2xl  text-violet-600'>Edit Details</div>
			<div className='my-3 h-[1px] w-full bg-[#6F6AF8]'></div>

			<div className='w-full m-auto '>
				{/* <div className='flex items-center'>
					<div className='w-[15%]'>Upload School Logo</div>

					<label
						htmlFor='file-upload'
						className='p-2 px-4 w-full  border-2 border-gray-200 justify-between flex  items-end  relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500'
					>
						<input
							id='file-upload'
							name='file-upload'
							type='file'
							className='sr-only'
							accept='image/*'
							onChange={handleLogoUpload}
							disabled
						/>
						<div>{logo ? <span>{logo.name}</span> : ''}</div>

						<div className='flex'>
							<img src={uploadIcon} alt='' />
							<span className='ml-1'>Upload</span>
						</div>
					</label>
				</div> */}
				<div className='mt-6 flex items-center'>
					<div className='w-[15%]'>Institute Name</div>
					<div className='w-full flex justify-between items-center'>
						<div className='w-1/3 '>
							<Input
								type='text'
								add_error={() => {}}
								value={name}
								onChange={(k: string, v: string) => setName(v)}
								disabled
							/>
						</div>
						<div className='text-right'>Classes</div>
						<div className='w-1/3'>
							<Input
								type='text'
								add_error={() => {}}
								value={classes.length}
								// onChange={(k: string, v: string) => setClasses(v)}

								disabled
							/>
						</div>
					</div>
				</div>
				<div className='mt-6 flex items-center'>
					<div className='w-[15%]'>Sections</div>
					<div className='w-full flex justify-between items-center'>
						<div className='w-1/3'>
							<Input
								type='text'
								add_error={() => {}}
								value={sections.length}
								// onChange={(k: string, v: string) => setSections(v)}
								disabled
							/>
						</div>
						{/* <div className='text-right'>Teachers</div>
						<div className='w-1/3'>
							<Input
								type='text'
								add_error={() => {}}
								value={teachers}
								onChange={(k: string, v: string) => setTeachers(v)}

								disabled
							/>
						</div> */}
						<div className='text-right'>Students</div>
						<div className='w-1/3'>
							<Input
								type='text'
								add_error={() => {}}
								value={students}
								onChange={(k: string, v: string) => setStudents(v)}
								disabled
							/>
						</div>
					</div>
				</div>
				<div className=' mt-6 flex items-center'>
					<div className='w-[15%]'>Edviron Id</div>
					<div className='w-full flex justify-between items-center'>
						<div className='w-1/3'>
							<Input
								type='text'
								add_error={() => {}}
								value={edviron_Reg_Number}
								onChange={(k: string, v: string) => setEdviron_Reg_Number(v)}
								disabled
							/>
						</div>
						<div className='text-right'>Institute Type</div>
						<div className='w-1/3'>
							<Input type='text' add_error={() => {}} value={type} onChange={(k: string, v: string) => setType(v)} />
						</div>
					</div>
				</div>
				<div className='mt-6 flex items-center'>
					<div className='w-[15%]'>Date of Registration</div>
					<div className='w-full flex justify-between items-center'>
						<div className='w-1/3'>
							<Input
								type='text'
								add_error={() => {}}
								value={new Date(DateOfReg).toDateString()}
								onChange={(k: string, v: string) => setDateOfReg(v)}
								disabled
							/>
						</div>
						<div className='text-right'>Address</div>
						<div className='w-1/3'>
							<Input
								type='text'
								add_error={() => {}}
								value={address}
								onChange={(k: string, v: string) => setAddress(v)}
							/>
						</div>
					</div>
				</div>
				<div className='mt-6 flex items-center'>
					<div className='w-[15%]'>City</div>
					<div className='w-full flex justify-between items-center'>
						<div className='w-1/3'>
							<Input type='text' add_error={() => {}} value={city} onChange={(k: string, v: string) => setCity(v)} />
						</div>
						<div className='text-right'>State</div>
						<div className='w-1/3'>
							<Input type='text' add_error={() => {}} value={state} onChange={(k: string, v: string) => setState(v)} />
						</div>
					</div>
				</div>
				<div className='mt-6 flex items-center'>
					<div className='w-[15%]'>Pincode</div>
					<div className='w-full flex justify-between items-center'>
						<div className='w-1/3'>
							<Input
								type='number'
								maxLength={6}
								add_error={() => {}}
								value={pin}
								onChange={(k: string, v: string) => setPin(v)}
							/>
						</div>
						<div className='text-right'>Fee Collection Date</div>
						<div className='w-1/3'>
							<Select
								selected={{ name: fee_collection_date }}
								setSelected={(v: any) => set_fee_collection_date(v.name)}
								options={[
									1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
								].map((d) => {
									return { name: d };
								})}
							/>
						</div>
					</div>
				</div>

				{/* <div className='mt-6 flex items-center'>
					<div className='w-[15%]'>Late Fee</div>
					<div className='w-full flex justify-between items-center'>
						<div className='w-1/3'>
							<Input
								type='text'
								value={late_fee}
								add_error={() => {}}
								onChange={(k: string, v: string) => set_late_fee(v)}
								disabled
							/>
						</div>
						<div className='text-right'>Late Fee Grace Period</div>
						<div className='w-1/3'>
							<Input
								type='text'
								add_error={() => {}}
								value={late_fee_grace_period}
								onChange={(k: string, v: string) => set_late_fee_grace_period(v)}
							/>
						</div>
					</div>
				</div> */}
				{/* <div className='mt-6 flex items-center'>
					<div className='w-full flex justify-between items-center'></div>
				</div> */}

				<div className='mt-6 font-semibold text-2xl text-violet-600'>Primary Contact</div>

				<div className='mt-6 flex items-center'>
					<div className='w-[15%]'>Contact Number</div>
					<div className='w-full flex justify-between items-center'>
						{/* <div className='w-1/3'>
							<Input
								type='text'
								add_error={() => {}}
								value={principalName}
								onChange={(k: string, v: string) => setPrincipalName(v)}
								disabled
							/>
						</div> */}
						{/* <div className='text-right'>Contact Number</div> */}
						<div className='w-1/3'>
							<Input
								type='text'
								add_error={() => {}}
								value={phone_number}
								onChange={(k: string, v: string) => setPhoneNumber(v)}
								disabled
							/>
						</div>
						<div className='text-right'>Email Address</div>
						<div className='w-1/3'>
							<Input
								type='text'
								add_error={() => {}}
								value={email}
								onChange={(k: string, v: string) => setEmail(v)}
								disabled
							/>
						</div>
					</div>
				</div>
				<div className='mt-6 font-semibold text-2xl text-violet-600'>Bank Details</div>

				<div className='mt-6 flex items-center'>
					<div className='w-[15%]'>Account Holder Name</div>
					<div className='w-full flex justify-between items-center'>
						{/* <div className='w-1/3'>
							<Input
								type='text'
								add_error={() => {}}
								value={principalName}
								onChange={(k: string, v: string) => setPrincipalName(v)}
								disabled
							/>
						</div> */}
						{/* <div className='text-right'>Contact Number</div> */}
						<div className='w-1/3'>
							<Input
								type='text'
								add_error={() => {}}
								value={account_holder}
								onChange={(k: string, v: string) => setAccountHolder(v)}
							/>
						</div>
						<div className='text-right'>Accout Number</div>
						<div className='w-1/3'>
							<Input
								type='text'
								add_error={() => {}}
								value={account_number}
								onChange={(k: string, v: string) => setAccountNumber(v)}
							/>
						</div>
					</div>
				</div>

				<div className='mt-6 flex items-center'>
					<div className='w-[15%]'>Accoutn IFSC Code</div>
					<div className='w-full flex justify-between items-center'>
						<div className='w-1/3'>
							<Input type='text' add_error={() => {}} value={ifsc} onChange={(k: string, v: string) => set_ifsc(v)} />
						</div>
					</div>
				</div>

				<div className='text-right'>
					{user.access === Access.SUPER ? (
						<button onClick={handleUpdate} className='mt-6 py-2 px-4 rounded-md bg-[#6F6AF8] text-white'>
							Update School Profile
						</button>
					) : (
						''
					)}
				</div>
			</div>
		</>
	);
};

export default EditSchoolDetails;
