import React, { useContext, useState, useRef, useEffect } from 'react';
import Modal from '../../../../../../components/Modal/Modal';
import Form from '../../../../../../components/Form/Form';
import Input from '../../../../../../components/Input/Input';
import CheckboxInput from '../../../../../../components/CheckboxInput/CheckboxInput';
import RadioInput from '../../../../../../components/RadioInput/RadioInput';
import { fee_type_string_to_frequency_months } from '../../pages/FeeStructureTab/FeeStructureTab';
import { boolean_map_to_array } from '../../pages/FeeStructureTab/FeeStructureTab';
import { get_fee_heads } from '../../pages/FeeStructureTab/FeeStructureTab';
import { dashboardContext } from '../../../../Dashboard';
import editCross from '../../../../assets/editportalcross.svg';
import { Access } from '../../../PortalAccess/AccessEnum';
import TransportFee, { TransportFeeType } from '../../pages/TransportFeeTab/TransportFee';
import ConfirmationBox from '../../../../../../components/ConfirmationBox/ConfirmationBox';

export const frequency_to_text = (n: number) => {
	if (n === 1) return 'Monthly';
	if (n === 2) return 'Bimonthly';
	if (n === 3) return 'Quarterly';
	if (n === 6) return 'Half Yearly';
	if (n === 12) return 'One Time';
};

const update_fee_head = async (data: any, id: any) => {
	const { name, fm, amt, classes, genders, categories, tax } = data;
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	myHeaders.append('Content-Type', 'application/json');

	var raw = JSON.stringify({
		name: name,
		frequency_months: fm,
		amount: amt,
		class: classes,
		gender: genders,
		category: categories,
		tax: tax,
		start_date: 1680287400000,
	});

	var requestOptions: RequestInit = {
		method: 'PATCH',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	const res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/fee-config/fee-head/${id}`, requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};
export const delete_fee_head = async (id: any) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	var requestOptions: RequestInit = {
		method: 'DELETE',
		headers: myHeaders,
		redirect: 'follow',
	};

	let res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/fee-config/fee-head/${id}`, requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

const EditDelete = ({ setEditFeeHead, setDeleteFeeHead, setToggle }: any) => {
	return (
		<div className='absolute py-2  bottom-0 right-5 hover:bg-slate-200 px-4 w-36 rounded-lg shadow bg-[#F8FAFB]'>
			{/* <div
				onClick={() => {
					setEditFeeHead(true);
					setToggle(false);
				}}
				className='py-2 cursor-pointer'
			>
				Edit
			</div> */}
			{/* <hr /> */}
			<div
				onClick={() => {
					setDeleteFeeHead(true);
					setToggle(false);
				}}
				className=' text-red-400   cursor-pointer'
			>
				Delete
			</div>
		</div>
	);
};

const DeleteFeeHead = ({ setDeleteFeeHead, id, set_fee_heads }: any) => {
	async function handleDelete() {
		try {
			let res = await delete_fee_head(id);
			if (res instanceof Error) {
				console.log('something went wrong!');
			} else {
				setDeleteFeeHead(false);
				set_fee_heads(await get_fee_heads());
			}
		} catch (err) {
			console.log(err);
		}
	}
	return (
		<>
			<ConfirmationBox
				setOpen={setDeleteFeeHead}
				funtion={handleDelete}
				confirmationText='delete this fee head'
				ButtonText='Delete'
			/>
		</>
	);
};
const FeeHeadCard = ({ fee_head, set_fee_heads }: any) => {
	const { classes, user, school_data } = useContext(dashboardContext);
	const [showModal, setShowModal] = React.useState(false);
	const [showRateModal, setShowRateModal] = React.useState(false);
	const [transportDetails, setTransportDetails] = useState<any>(null);
	const [isEditFeeHead, setEditFeeHead] = React.useState(false);
	const [isDeleteFeeHead, setDeleteFeeHead] = React.useState(false);
	const [toggle, setToggle] = useState(false);

	return (
		<div
			onMouseLeave={() => {
				if (toggle === true) {
					setToggle(false);
				}
			}}
			className='fee-head-card h-full'
		>
			<Modal open={showModal} setOpen={setShowModal} title='Classes'>
				{
					<div className='mx-auto text-center'>
						{fee_head.class.map((fh: any) => (
							<div>{fh}</div>
						))}
					</div>
				}
			</Modal>
			<Modal className='w-full max-w-3xl' open={showRateModal} setOpen={setShowRateModal} title='Rate'>
				<h4 className=' text-base mb-2 font-medium text-edvion_black'>
					Fee Type:{' '}
					{transportDetails?.transport_fee_type === TransportFeeType.KM_BASED
						? 'Km Based'
						: transportDetails?.transport_fee_type === TransportFeeType.SLAB_BASED
						? 'Slab Based'
						: transportDetails?.transport_fee_type === TransportFeeType.STOP_BASED
						? 'Stop Based'
						: ''}
				</h4>
				{transportDetails?.transport_fee_slabs && (
					<>
						<div className='flex justify-between items-center'>
							<h3 className=' text-sm font-medium text-edvion_black'>Range</h3>
							<h3 className=' text-sm font-medium text-edvion_black'>Amount</h3>
						</div>
						<div className='flex justify-between'>
							<div>
								{transportDetails?.transport_fee_slabs?.breakpoints.length ? (
									[0, ...transportDetails?.transport_fee_slabs?.breakpoints].map((d: any, i: number) => (
										<div className='flex justify-between gap-4 items-center mt-4'>
											<div className='flex items-end gap-4 min-w-[16rem] max-w-[20rem]'>
												<input
													type='number'
													name='initialRange'
													disabled={true}
													value={i === 0 ? d : transportDetails?.transport_fee_slabs?.breakpoints[i - 1]}
													placeholder='set initial range'
													className='rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs '
												/>

												<input
													type='text'
													name='finalRange'
													disabled={true}
													value={
														i === transportDetails?.transport_fee_slabs?.breakpoints.length
															? '∞'
															: transportDetails?.transport_fee_slabs?.breakpoints[i]
													}
													placeholder='set final range'
													className={
														'rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs '
													}
												/>
												<span className='text-[#959595] text-opacity-80 -ml-2 text-xs flex-none'>(in km)</span>
											</div>
										</div>
									))
								) : (
									<div className='flex justify-between gap-4 items-center mt-4'>
										<div className='flex items-end gap-4 min-w-[16rem] max-w-[20rem]'>
											<input
												type='number'
												name='initialRange'
												disabled={true}
												value={0}
												placeholder='set initial range'
												className='rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs '
											/>

											<input
												type='text'
												name='finalRange'
												disabled={true}
												value={'∞'}
												placeholder='set final range'
												className={
													'rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs '
												}
											/>
											<span className='text-[#959595] text-opacity-80 -ml-2 text-xs flex-none'>(in km)</span>
										</div>
									</div>
								)}
							</div>

							<div className='w-1/4'>
								{transportDetails?.transport_fee_slabs?.fees.length &&
									transportDetails?.transport_fee_slabs?.fees.map((d: any) => (
										<div className='flex justify-between gap-4 items-center mt-4'>
											<input
												type='number'
												value={d}
												disabled={true}
												name='amount'
												className='rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs '
											/>
										</div>
									))}
							</div>
						</div>
					</>
				)}
				{transportDetails?.transport_fee_stops.length > 0 && (
					<>
						{transportDetails?.transport_fee_stops.map((d: any) => (
							<>
								{school_data['school'].stops.filter((n: any) => n._id === d.stop_id)[0]?.name && (
									<div className='flex justify-between gap-4 items-center mt-2'>
										<div className='w-full'>
											{school_data['school'].stops.filter((n: any) => n._id === d.stop_id)[0]?.name}
										</div>
										<div className='max-w-[8rem]'>
											<input
												type='number'
												name='stopFee'
												value={d.fees}
												disabled={true}
												placeholder='enter in ₹ '
												className='rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-[10px] w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs placeholder:text-right'
											/>
										</div>
									</div>
								)}
							</>
						))}
					</>
				)}
			</Modal>
			<Modal
				children={
					<div className='create-fee-head mt-5 w-full'>
						<Form
							className='w-full'
							onSubmit={async (data: any) => {
								await update_fee_head(
									{
										name: data['Fee Head Name'],
										fm: fee_type_string_to_frequency_months(data['Fee Type']),
										amt: data['Fee Amount'],
										classes: boolean_map_to_array(data['Class']),
										genders: boolean_map_to_array(data['Gender']),
										categories: boolean_map_to_array(data['Category']),
										tax: data['Tax(%)'],
									},
									fee_head._id,
								);
								set_fee_heads(await get_fee_heads());
								setEditFeeHead(false);
							}}
						>
							<Input value={fee_head.name} name='Fee Head Name' required />
							<Input value={fee_head.amount} name='Fee Amount' type='number' prefix='₹' required />
							<Input value={fee_head.tax} name='Tax(%)' type='number' required />
							<RadioInput
								name='Fee Type'
								value={fee_head.frequency_months}
								options={['Monthly', 'Bimonthly', 'Quarterly', 'Half Yearly', 'One Time']}
								required
							/>
							<CheckboxInput value={fee_head.class} name='Class' options={classes} required />
							<CheckboxInput value={fee_head.gender} name='Gender' options={['Male', 'Female', 'Others']} required />
							<CheckboxInput
								value={fee_head.category}
								name='Category'
								options={['General', 'ST', 'SC', 'OBC']}
								required
							/>

							<button
								type='submit'
								className=' ml-auto inline-flex justify-center rounded-md bg-[#6F6AF8] px-3 py-2 text-sm font-semibold text-white shadow hover:bg-[#6F6AF8]  sm:w-auto'
							>
								Submit
							</button>
						</Form>
					</div>
				}
				title='Edit Fee Head'
				open={isEditFeeHead}
				setOpen={setEditFeeHead}
			/>
			<Modal
				children={<DeleteFeeHead setDeleteFeeHead={setDeleteFeeHead} id={fee_head._id} set_fee_heads={set_fee_heads} />}
				title='Delete'
				open={isDeleteFeeHead}
				setOpen={setDeleteFeeHead}
			/>
			{fee_head.is_trasport_fee === false && (
				<div className='p-5 shadow rounded-md h-full'>
					<div className='fee_head_header font-medium flex justify-between items-center'>
						<div> {fee_head.name}</div>
						<div className='relative'>
							{(user.access === Access.SUPER || user.access === Access.ALL_ACCESS) && (
								<i
									onClick={() => setToggle((prev) => !prev)}
									className='fa-solid fa-ellipsis-vertical ml-auto cursor-pointer p-2'
								></i>
							)}
							{toggle ? (
								<EditDelete
									setEditFeeHead={setEditFeeHead}
									setDeleteFeeHead={setDeleteFeeHead}
									feeHeadId={fee_head._id}
									setToggle={setToggle}
								/>
							) : (
								''
							)}
						</div>
					</div>

					<div className='grid grid-cols-3 mt-4 gap-4'>
						<div className=''>
							<div className='text-sm font-light '>Type</div>
							<div className='mt-2 text-sm font-medium'>{frequency_to_text(fee_head.frequency_months)}</div>
						</div>

						<div className='border-l-2 px-4'>
							<div className='text-sm font-light '>Fee</div>
							<div className='mt-2 text-sm font-medium'>{'₹' + (fee_head?.amount as number)?.toLocaleString('hi')}</div>
						</div>

						<div className='border-l-2 px-4'>
							<div className='text-sm font-light '>Tax</div>
							<div className='mt-2 text-sm font-medium'>{fee_head.tax + '%'}</div>
						</div>
						<div className=''>
							<div className='text-sm font-light '>Class</div>
							<div
								className='mt-2 text-sm font-medium text-violet-600 cursor-pointer'
								onClick={() => {
									setShowModal(true);
								}}
							>
								{fee_head.class.length + ' Classes'}
							</div>
						</div>

						<div className='border-l-2 px-4'>
							<div className='text-sm font-light '>Starting Date</div>
							<div className='mt-2 text-sm font-medium'>{new Date(fee_head.start_date).toDateString()}</div>
						</div>
						<div className='border-l-2 px-4'>
							<div className='text-sm font-light '>Ending Date</div>
							<div className='mt-2 text-sm font-medium'>{new Date(fee_head.end_date).toDateString()}</div>
						</div>
					</div>
				</div>
			)}
			{fee_head.is_trasport_fee === true && (
				<div className='p-5 shadow rounded-md h-full'>
					<div className='fee_head_header font-medium flex justify-between items-center'>
						<div> {fee_head.name}</div>
						<div className='relative'>
							{(user.access === Access.SUPER || user.access === Access.ALL_ACCESS) && (
								<i
									onClick={() => setToggle((prev) => !prev)}
									className='fa-solid fa-ellipsis-vertical ml-auto cursor-pointer p-2'
								></i>
							)}
							{toggle ? (
								<EditDelete
									setEditFeeHead={setEditFeeHead}
									setDeleteFeeHead={setDeleteFeeHead}
									feeHeadId={fee_head._id}
									setToggle={setToggle}
								/>
							) : (
								''
							)}
						</div>
					</div>

					<div className='grid grid-cols-3 mt-4 gap-4'>
						<div className=''>
							<div className='text-sm font-light '>Type</div>
							<div className='mt-2 text-sm font-medium'>{frequency_to_text(fee_head.frequency_months)}</div>
						</div>

						<div className='border-l-2 px-4'>
							<div className='text-sm font-light '>Tax</div>
							<div className='mt-2 text-sm font-medium'>{fee_head.tax + '%'}</div>
						</div>
						<div className='border-l-2 px-4'>
							<div className='text-sm font-light '>Rate</div>
							<div
								className='mt-2 text-sm font-medium text-violet-600 cursor-pointer'
								onClick={() => {
									setTransportDetails(fee_head);
									setShowRateModal(true);
								}}
							>
								Rate card
							</div>
						</div>

						<div>
							<div className='text-sm font-light '>Starting Date</div>
							<div className='mt-2 text-sm font-medium'>{new Date(fee_head.start_date).toDateString()}</div>
						</div>
						<div className='border-l-2 px-4'>
							<div className='text-sm font-light '>Ending Date</div>
							<div className='mt-2 text-sm font-medium'>{new Date(fee_head.end_date).toDateString()}</div>
						</div>
						<div className='border-l-2 px-4'>
							<div className='text-sm font-light '>Fee Type</div>
							<div className='mt-2 text-sm font-medium'>
								{fee_head.transport_fee_type === TransportFeeType.KM_BASED
									? 'Km Based'
									: fee_head.transport_fee_type === TransportFeeType.SLAB_BASED
									? 'Slab Based'
									: fee_head.transport_fee_type === TransportFeeType.STOP_BASED
									? 'Stop Based'
									: ''}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default FeeHeadCard;
