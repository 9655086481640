import { Fragment, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import React from 'react';

function classNames(...classes: any[]) {
	return classes.filter(Boolean).join(' ');
}

export default function Example({
	options,
	label,
	selected,
	setSelected,
	className,
	labelStyle,
	boxStyle,
	icon,
}: {
	options: any[];
	label?: string;
	selected?: any;
	setSelected?: any;
	className?: string;
	labelStyle?: string;
	boxStyle?: string;
	icon?: any;
}) {
	const people: any[] = options;
	useEffect(() => {
		if (!selected) setSelected(people[0]);

		// eslint-disable-next-line
	}, []);

	return (
		<div className={'block ' + className}>
			<Listbox value={selected} onChange={setSelected}>
				{({ open }) => (
					<>
						<Listbox.Label
							className={
								'block  text-sm font-medium leading-6 text-gray-900 ' + labelStyle
									? labelStyle
									: ' text-sm font-medium text-gray-900 '
							}
						>
							{label}
						</Listbox.Label>
						<div className='relative mt-2'>
							<Listbox.Button
								className={
									'relative w-full cursor-default  py-1.5 pl-3 pr-10 text-left text-gray-900 sm:text-sm sm:leading-6 ' +
									(boxStyle && boxStyle ? boxStyle : ' rounded-md')
								}
							>
								<span className='flex items-center '>
									{/* <img src={selected.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" /> */}
									<span className='ml-3 block truncate'>{selected?.name}</span>
								</span>
								<span className='pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2'>
									{icon && icon ? icon : <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />}
								</span>
							</Listbox.Button>

							<Transition
								show={open}
								as={Fragment}
								leave='transition ease-in duration-100'
								leaveFrom='opacity-100'
								leaveTo='opacity-0'
							>
								<Listbox.Options className=' absolute  z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-[#F8FAFB] py-1 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
									{people.map((person) => (
										<Listbox.Option
											key={person.id}
											className={({ active }) =>
												classNames(
													active ? 'bg-indigo-600 text-white' : 'text-gray-900',
													'relative cursor-default select-none py-2 pl-3 pr-9',
												)
											}
											value={person}
										>
											{({ selected, active }) => (
												<>
													<div className='flex items-center'>
														{/* <img src={person.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" /> */}
														<span
															className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
														>
															{person.name}
														</span>
													</div>

													{selected ? (
														<span
															className={classNames(
																active ? 'text-white' : 'text-indigo-600',
																'absolute inset-y-0 right-0 flex items-center pr-4',
															)}
														>
															<CheckIcon className='h-5 w-5' aria-hidden='true' />
														</span>
													) : null}
												</>
											)}
										</Listbox.Option>
									))}
								</Listbox.Options>
							</Transition>
						</div>
					</>
				)}
			</Listbox>
		</div>
	);
}
