import React, { useState, useContext } from 'react';
import { collectIcon, feepaidIcon } from '../../../../../../assets/assets';
import DisplayCard from '../../../../../../components/DisplayCard';
import Table from '../../../../../../components/Table/Table';
import Select from '../../../../../../components/Select/Select';
import { Link } from 'react-router-dom';
import { RightSideBar } from '../../../Overview/Overview';
import { dashboardContext } from '../../../../Dashboard';
import { Access } from '../../../PortalAccess/AccessEnum';

import Modal from '../../../../../../components/Modal/Modal';
import jsPDF from 'jspdf';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import SkeletonLoader from '../../../../../../components/Skeleton/DashboardSkeletonLoader';
import Loading from '../../../../Loading';
import FeeCollectionSmallLoader from '../../../SmallSkeleton/FeeCollectionSmallLoader';
import html2canvas from 'html2canvas';
import InvoicePDF from './InvoicePDF';
import { useQuery } from '@apollo/client';
import { GET_FINE_DATA } from './Querries';

// import { recent } from './data';
// import collectIcon from "../../../../assets/collect.svg";
// import feePaidIcon from "../../../../assets/feepaid.svg";
// import notificationIcon from "../../../../assets/notification.svg";

export const get_invoice_data = async (transaction_id: any) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

	var requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	const res = await fetch(
		process.env.REACT_APP_BACKEND_URL + '/api/school/transactions/transaction?id=' + transaction_id,
		requestOptions,
	);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

export const DownloadPDF = ({ data, name, title }: any) => {
	const [loading, setLoading] = useState(false);
	const handleDownload = () => {
		setLoading(true);
		html2canvas(data, { scale: 1.5 }).then((canvas: any) => {
			const imgWidth = 208;
			const pageHeight = 295;
			const imgHeight = (canvas.height * imgWidth) / canvas.width;
			let heightLeft = imgHeight;
			let position = 0;
			heightLeft -= pageHeight;
			const doc = new jsPDF('p', 'mm');
			doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
			while (heightLeft >= 0) {
				position = heightLeft - imgHeight;
				doc.addPage();
				doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
				heightLeft -= pageHeight;
			}
			if (name) {
				doc.save(`${title}_${name.replace(/\s/g, '_').toLowerCase()}.pdf`);
				setLoading(false);
			}
			setLoading(false);

			doc.save('generated.pdf');
		});
	};

	return (
		<div className='flex justify-center mt-5 mb-5'>
			<button onClick={handleDownload} className=' text-violet-600 border border-violet-300 p-2 rounded-md'>
				{loading ? (
					<span>
						Downloading <i className='fa-solid ml-2 animate-spin fa-spinner'></i>
					</span>
				) : (
					<span className='mr-1'>
						{`Download ${title}`} <i className='fa-sharp fa-solid fa-download'></i>
					</span>
				)}
			</button>
		</div>
	);
};

const FeeOverview = ({  school, set_path }: any) => {
	const [selected, setSelected]: [any, any] = useState({ name: 'Total Annual Fee' });
	const { user, latest_due_date, instllment_wise_fee_report, school_data } = useContext(dashboardContext);
	const [open_modal, set_modal] = useState(false);
	const [invoice_data, set_invoice_data] = useState<any>([]);

	async function handleInvoice(transaction_id: any) {
		const res = await get_invoice_data(transaction_id);
		set_invoice_data(res);
	}

	const selected_installment = instllment_wise_fee_report?.filter((i: any) =>
		selected.name.includes(new Date(i.due_date).toDateString()),
	)[0];
const { data: fine_data, loading, error } = useQuery(GET_FINE_DATA);
// console.log({ fine_data});
const selected_installment_fine_report = fine_data?.school.instllment_wise_fine_report.filter((i: any) =>
	selected.name.includes(new Date(i.due_date).toDateString()),
)[0];

	return (
		<Loading component={<FeeCollectionSmallLoader />} loading={false}>
			<div className='flex pr-[20rem]'>
				<div className='flex-1'>
					<div className='w-80'>
						<Select
							selected={selected}
							setSelected={setSelected}
							options={[
								'Total Annual Fee',
								`This Installment (${new Date(latest_due_date).toDateString()})`,
								...instllment_wise_fee_report.map((installment: any, idx: any) => {
									return `Installment ${idx + 1} (${new Date(installment.due_date).toDateString()})`;
								}),
							].map((e) => {
								return { name: e };
							})}
						/>
					</div>
					<div className='flex  my-2 text=edvion_black gap-5'>
						<DisplayCard
							icon={collectIcon}
							to={'/fee_reports/section_report'}
							value={
								'₹' +
								(selected.name === 'Total Annual Fee'
									? instllment_wise_fee_report?.reduce((acc: any, curr: any) => acc + curr.total_amount_paid, 0)
											.toLocaleString('hi')
									: selected_installment.total_amount_paid.toLocaleString('hi'))
							}
							label='Total fee collected'
						/>

						{fine_data && (
							<DisplayCard
								icon={collectIcon}
								value={
									'₹' +
									(selected.name === 'Total Annual Fee'
										? fine_data?.school.instllment_wise_fine_report
												.reduce((acc: any, curr: any) => acc + curr.total_amount, 0)
												.toLocaleString('hi')
										: selected_installment_fine_report?.total_amount || 0)
								}
								label='Total fine collected'
							/>
						)}
						{instllment_wise_fee_report && 
						<DisplayCard
						icon={feepaidIcon}
						to={'/fee_reports/student_report'}
						value={(selected.name === 'Total Annual Fee'
							? JSON.parse(JSON.stringify(instllment_wise_fee_report)).sort((a: any, b: any) => {
									return new Date(b.due_date).getTime() - new Date(a.due_date).getTime();
							  })[0]?.total_students_paid
							: selected_installment?.total_students_paid
						)?.toString()}
						label='Number of student who have paid fees'
						denominator={school_data?.school.number_of_students.toString()}
					/>}
						
						{/* <DisplayCard icon={collectIcon} value={'500'} label='Reminders sent today' /> */}
					</div>

					{user.access === Access.SUPER || user.access === Access.ALL_ACCESS || user.access === Access.MANAGEMENT ? (
						<div className='bg-[#F8FAFB] shadow rounded-[8px]  mt-4 mb-8  h-[156px] p-6 w-full max-w-lg  first-letter:xl transform transition duration-500 hover:scale-105'>
							<Link to='/fee_collection/collect_fee' className='flex flex-col justify-between h-full'>
								<div className='flex justify-between items-center'>
									<h2 className='text-edvion_black font-medium text-[40px]'>Collect Fee</h2>
									<span>
										<i className='fa-solid text-[#9747FF] text-xl fa-chevron-right'></i>
									</span>
								</div>
								<p className='text-base font-medium text-edvion_black'>Click to view class wise pending fees</p>
							</Link>
						</div>
					) : (
						''
					)}

					{/* <Table
						className='hidden '
						heading={
							<Link
								to='/fee_collection/transactions'
								onClick={() => {
									set_path('/fee_collection/transactions');
								}}
							>
								<div>
									<span className='hover:underline hover:underline-offset-2'> Recent Transactions</span>
									<i className='fa-solid fa-arrow-right ml-2 text-[#9747FF] text-xl transform -rotate-45'></i>
								</div>
							</Link>
						}
						data={[
							['Student Name', 'Father Name', 'Class & Section', 'Transaction ID', 'Amount', 'Payment Mode', 'Time'],
							...totalfeecollection.recent_transactions.map((r: any) => [
								<Link to={'/student_profile/' + r.student._id} className='text-violet-500 '>
									{r.student.name}
								</Link>,
								r.student.father_name,
								r.student.class + '-' + r.student.section,
								<div
									className=' truncate cursor-pointer text-violet-500 '
									onClick={() => {
										set_modal(true);
										handleInvoice(r?._id);
									}}
								>
									{`${r._id.slice(0, 4)}...${r._id.slice(r._id.length - 4, r._id.length)}`}
								</div>,
								<h2 className='text-[#2EB418]'>{'₹ ' + r.fee_total?.toLocaleString('hi')}</h2>,
								r.payment_mode,
								new Date(r.updatedAt).toDateString(),
								'',
							]),
						]}
						footer={<div></div>}
					/> */}
					{/* <Modal open={open_modal} className='max-w-[45rem] w-full' setOpen={set_modal} title={'Transaction Details'}>
						{invoice_data.length && <InvoicePDF invoice_data={invoice_data[0]} />}
					</Modal> */}
				</div>
				<RightSideBar></RightSideBar>
			</div>
		</Loading>
	);
};

export default FeeOverview;
