import React, { useState } from 'react';

import payment_pending from '../../../../assets/payment_pending.svg';
import { useParams } from 'react-router-dom';
import LoadAndRender from '../../../../components/LoadAndRender/LoadAndRender';

function PaymentPending() {
	let { id } = useParams();
	const [data, setData] = useState<any>([]);

	const getInvoice = async () => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};
		return await (await fetch(process.env.REACT_APP_BACKEND_URL + `/payment/invoice/${id}`, requestOptions)).json();
	};

	return (
		<LoadAndRender
			className=' h-full w-full'
			promise={async () => {
				setData(await getInvoice());
			}}
		>
			<div className='flex w-full h-full justify-between  items-center p-4'>
				<div className='max-w-3xl w-full mx-auto rounded-lg shadow border px-4 py-2'>
					<div className='w-full flex justify-center items-center my-4'>
						<img src={payment_pending} alt='payment_pending' />
					</div>
					<p className='text-center my-8'>
						<span className='text-black text-2xl font-medium '>
							We are confirming your payment. Please wait for a few minutes.
						</span>{' '}
					</p>
					<p className='text-center  text-edvion_black text-md font-medium'>Order ID: {id}</p>
					<p className='text-center mt-4 mb-8 text-edvion_black text-md font-medium'>
						Amount: ₹{data?.transaction?.amount.toLocaleString('hi')}
					</p>
					<p className='text-xl text-center font-medium text-[#959595] my-4'>
						If any money was debited from your account, it will be credited back  in 7 business days. Sorry for the
						inconvenience caused.
					</p>
				</div>
			</div>
		</LoadAndRender>
	);
}

export default PaymentPending;
