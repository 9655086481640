import React, { useState } from 'react';
import Table from '../../../../../components/Table/Table';
import Select from '../components/Select/Select';
function WalletTransaction({ data, loading, rate }: any) {
	const [transactionStatus, setTransactionStatus] = useState({
		name: 'Status',
	});
	const [descriptionData, setDescriptionData] = useState('');

	return (
		<div className={'mt-4 ' + (loading ? ' ml-10' : ' ')}>
			{loading ? (
				<div className='rounded-lg bg-gray-300 animate-pulse shadow h-5 max-w-md'></div>
			) : (
				<h3 className={' text-edvion_black text-2xl font-semibold ' + (loading ? ' ' : ' ml-10')}>All Transactions</h3>
			)}
			{loading ? (
				<>
					<div className='rounded-lg  bg-gray-300 animate-pulse shadow h-96 pr-5 w-full mx-auto mt-4'></div>
				</>
			) : (
				<>
					{data?.transactions?.length > 0 ? (
						<Table
							description={
								<div className='flex w-full justify-between items-center'>
									<div className='bg-[#817DFF] h-12 items-center bg-opacity-10 flex  px-6 max-w-xl w-full rounded-lg'>
										<i className='text-edvion_black text-opacity-50 fa-solid fa-magnifying-glass'></i>
										<input
											onChange={(e) => setDescriptionData(e.target.value)}
											type='text'
											placeholder='Search description'
											className='ml-4 bg-transparent focus:outline-none w-full placeholder:font-normal'
										/>
									</div>
									<div className='flex items-center space-x-2'>
										<Select
											selected={transactionStatus}
											setSelected={setTransactionStatus}
											options={['All', 'Failed', 'Success', 'Pending'].map((type: any) => {
												return { name: type };
											})}
											boxStyle=' border border-[#6F6AF8] rounded-md'
											className='w-44'
											icon={<i className='fa-solid text-edvion_black fa-chevron-down'></i>}
										/>
									</div>
								</div>
							}
							className='hidden'
							truncate='false'
							shadow='false'
							textAlignMent='text-left pl-4'
							data={[
								['Date', 'Description', 'Credit/Debit', 'Amount', 'Status'],
								...data?.transactions
									?.filter(
										(d: any) =>
											(d?.transaction_status === transactionStatus?.name?.toUpperCase() ||
												transactionStatus?.name === 'All' ||
												transactionStatus?.name === 'Status') &&
											(descriptionData?.length === 0 ||
												d?.description?.toLowerCase().includes(descriptionData?.toLowerCase())),
									)
									.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
									.map((transaction: any) => {
										return [
											<div>
												{new Date(transaction?.createdAt).toLocaleDateString('en-US', {
													day: 'numeric',
													year: 'numeric',
													month: 'short',
												})}
											</div>,
											<div>
												{transaction?.transaction_type === 'CREDIT'
													? 'Wallet Recharge'
													: transaction?.transaction_type === 'DEBIT'
													? `Debited for WhatsaApp Reminder sent on ${new Date(
															transaction?.createdAt,
													  ).toLocaleDateString('en-US', {
															day: 'numeric',
													  })}${
															new Date(transaction?.createdAt)?.getDay() === 1
																? 'st'
																: new Date(transaction?.createdAt)?.getDay() === 2
																? 'nd'
																: new Date(transaction?.createdAt)?.getDay() === 3
																? 'rd'
																: 'th'
													  } ${new Date(transaction?.createdAt).toLocaleDateString('en-US', {
															year: 'numeric',
															month: 'short',
													  })}`
													: ''}
											</div>,
											<div className='capitalize'>{transaction?.transaction_type.toLowerCase()}</div>,

											<div className='flex flex-col items-start w-full max-w-fit'>
												<div>
													<h4>₹{transaction?.amount.toLocaleString('hi')}</h4>
												</div>
												<p className='w-full mt-1 bg-[#F2D484] h-[19px] truncate text-[9px] font-medium flex justify-center items-center rounded-md max-w-fit text-[#1E1B59CC] px-3'>
													<span>WhatsApp credits: {Number(transaction?.amount / rate)}</span>
												</p>
											</div>,
											<div
												className={`text-sm font-medium text-white max-w-fit h-[27px] flex justify-center items-center capitalize px-4 rounded-3xl  
										 ${
												transaction?.transaction_status === 'FAILED'
													? ' bg-[#FC4343E5]'
													: transaction?.transaction_status === 'SUCCESS'
													? ' bg-[#2EB418E5]'
													: transaction?.transaction_status === 'PENDING'
													? ' bg-[#EDAE0D]'
													: ''
											}
								`}
											>
												{transaction?.transaction_status}
											</div>,
										];
									}),
							]}
						/>
					) : (
						<Table className='hidden' truncate='false' data={[['Description', 'Credit/Debit', 'Amount', 'Status']]} />
					)}
				</>
			)}
		</div>
	);
}

export default WalletTransaction;
