import React, { useState } from 'react';
import Modal from '../../../../../../../components/Modal/Modal';
import Input from '../../../../../../../components/Input/Input';
import { useMutation } from '@apollo/client';
import { CREATE_STOP, DELETE_STOP, GET_ALL_STOPS } from '../Querries';
import ConfirmationBox from '../../../../../../../components/ConfirmationBox/ConfirmationBox';

function AddBusStops({ data }: any) {
	const [showAddBusStopModal, setShowAddBusStopModal] = useState(false);
	const [create_new_stop] = useMutation(CREATE_STOP, { refetchQueries: [{ query: GET_ALL_STOPS }] });
	const [delete_stop] = useMutation(DELETE_STOP, { refetchQueries: [{ query: GET_ALL_STOPS }] });
	const [busStop, setBusStop] = useState('');
	const [stopId, setStopId] = useState('');
	const [showConfirmatinBox, setConfirmationBox] = useState(false);
	const addBusStop = async () => {
		const res = await create_new_stop({
			variables: {
				name: busStop,
			},
		});
		setBusStop('');
		if (res.data) setShowAddBusStopModal(false);
	};
	return (
		<div className='min-h-[32rem] h-full shadow rounded-md p-4 flex flex-col justify-between'>
			<Modal title='Add Bus Stop' open={showAddBusStopModal} setOpen={setShowAddBusStopModal}>
				<Input
					type='text'
					name={'Bus Stop'}
					placeholder={'Enter Bus Stop Name'}
					add_error={() => {}}
					onChange={(name: any, e: any) => {
						const isEmptyOrWhitespace = /^\s*$/;
						if (!isEmptyOrWhitespace.test(e)) {
							setBusStop(e);
						} else {
							setBusStop('');
						}
					}}
				/>
				<div className='flex justify-center'>
					<button
						disabled={busStop.length < 1}
						onClick={addBusStop}
						className='py-2 px-10 bg-green-400 disabled:bg-green-300 text-white rounded-lg'
					>
						Add
					</button>
				</div>
			</Modal>
			<Modal open={showConfirmatinBox} setOpen={setConfirmationBox}>
				<ConfirmationBox
					setOpen={setConfirmationBox}
					funtion={async () => {
						delete_stop({
							variables: {
								id: stopId,
							},
						});
					}}
					confirmationText='delete stop'
					ButtonText='Delete'
				/>
			</Modal>
			<div>
				<div>
					<div className='flex justify-between items-center mb-8'>
						<h3 className=' text-lg font-medium text-edvion_black'>Bus Stops</h3>
						<button
							className='capitalize text-[#6F6AF8] text-xs font-medium'
							onClick={() => setShowAddBusStopModal(true)}
						>
							+ add new stop
						</button>
					</div>
				</div>
				<div className='rounded-lg min-h-[20rem]  bg-[#6F6AF8] bg-opacity-5 h-full border border-[#817DFF] border-opacity-25 p-4'>
					<ul className='flex flex-wrap gap-x-4 gap-y-2'>
						{data.school.stops.map((stop: any) => {
							return (
								<li key={stop._id} className='px-2 py-1 bg-[#D9D9D9] flex gap-x-2 items-center rounded-lg'>
									<span className='text-xs font-normal'>{stop.name}</span>
									<span
										onClick={() => {
											setStopId(stop._id);
											setConfirmationBox(true);
										}}
										className=' text-[#4E4E4E] font-normal cursor-pointer'
									>
										x
									</span>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
}

export default AddBusStops;
