import React, { useState } from 'react';
import { DownloadPDF } from './FeeOverview';
import { PaymentMode } from '../../../../AccessEnum';

const InvoicePDF = ({ invoice_data, tId, school }: any) => {
	const [fee_breakup, set_fee_breakup] = useState(invoice_data?.fee_breakup && JSON.parse(invoice_data?.fee_breakup));
	const [selected_transaction, set_selected_transaction] = useState<any>(null);

	return (
		<>
			<div
				className='p-8 w-full'
				ref={(e) => {
					set_selected_transaction(e);
				}}
			>
				<div className='flex mb-4 justify-between items-center'>
					<h2 className='text-3xl font-normal'>Fee Receipt</h2>
					{school?.logo_url ? (
						<div className='flex flex-col items-center justify-center'>
							<img className='rounded-full w-10 h-10' src={school?.logo_url} alt='school logo' />
							<h3>{school?.name}</h3>
						</div>
					) : (
						<h2 className='text-2xl font-normal'>Institute Logo</h2>
					)}
				</div>
				<hr className='h-1 bg-black' />
				<div className='mt-4 flex justify-between items-center'>
					<div className='flex flex-col w-1/2'>
						<div className='mt-2 w-full space-x-4 items-center flex'>
							<div className='space-y-4 flex-none'>
								<p className='text-sm text-gray-500 font-light'>Address </p>
								<p className='text-sm text-gray-500 font-light'>Phone No. </p>
								<p className='text-sm text-gray-500 font-light'>Email </p>
							</div>
							<div className='space-y-4 '>
								<p className='text-sm text-gray-500 font-light'>:</p>
								<p className='text-sm text-gray-500 font-light'>:</p>
								<p className='text-sm text-gray-500 font-light'>:</p>
							</div>
							<div className='space-y-3  flex-none'>
								<p className='text-md text-black font-bold'>{school?.address?.street || 'delhi'}</p>
								<p className='text-md text-black font-bold'>{school?.phone_number}</p>
								<p className='text-md text-black font-bold'>{school?.email_id}</p>
							</div>
						</div>
					</div>
					<div className='w-1/2'>
						<div className='w-full space-x-4 justify-end items-center flex'>
							<div className='space-y-4 flex-none'>
								<p className='text-sm text-gray-500 font-light'>Receipt No. </p>
								<p className='text-sm text-gray-500 font-light'>Date </p>
							</div>
							<div className='space-y-4'>
								<p className='text-sm text-gray-500 font-light'>:</p>
								<p className='text-sm text-gray-500 font-light'>:</p>
							</div>
							<div className='space-y-3 flex-none'>
								<p className='text-md text-black font-bold'>{tId}</p>
								<p className='text-md text-black font-bold'>{new Date().toDateString()}</p>
							</div>
						</div>
					</div>
				</div>
				<hr className='h-[2px] mt-4 bg-black' />
				<div className='mt-4 flex justify-between items-center'>
					<div className='flex flex-col w-1/2'>
						<h3>Student Details</h3>
						<div className='mt-2 w-full space-x-4 items-center flex'>
							<div className='space-y-4 flex-none'>
								<p className='text-sm text-gray-500 font-light '>Student Name </p>
								<p className='text-sm text-gray-500 font-light'>Student ID </p>
								<p className='text-sm text-gray-500 font-light'>Father Name </p>
							</div>
							<div className='space-y-4'>
								<p className='text-sm text-gray-500 font-light'>:</p>
								<p className='text-sm text-gray-500 font-light'>:</p>
								<p className='text-sm text-gray-500 font-light'>:</p>
							</div>
							<div className='space-y-3 h-[6rem] flex-none'>
								<p className='text-md text-black font-bold '>{fee_breakup?.student?.name}</p>
								<p className='text-md text-black font-bold'>{fee_breakup?.student?.school_generated_id}</p>
								<p className='text-md text-black font-bold'>{fee_breakup?.student?.father_name}</p>
							</div>
						</div>
					</div>
					<div className='w-1/2'>
						<div className='w-full space-x-4 justify-end items-center flex'>
							<div className='space-y-4'>
								<p className='text-sm text-gray-500 font-light'>Class </p>
								<p className='text-sm text-gray-500 font-light'>Mobile No. </p>
							</div>
							<div className='space-y-4'>
								<p className='text-sm text-gray-500 font-light'>:</p>
								<p className='text-sm text-gray-500 font-light'>:</p>
							</div>
							<div className='space-y-3'>
								<p className='text-md text-black font-bold'>
									{fee_breakup?.student?.class + '-' + fee_breakup?.student?.section}
								</p>
								<p className='text-md text-black font-bold'>{fee_breakup?.student?.phone_number[0]}</p>
							</div>
						</div>
					</div>
				</div>
				<div className='mt-4'>
					<h1 className='text-center text-2xl'>Payment Mode : {invoice_data?.payment_mode} </h1>
					<div className='w-full rounded-lg border-2 mt-4 border-black'>
						<div className='flex w-full'>
							<div className='border-r-2 text-center border-b-2 border-black w-full h-10 flex justify-center items-center'>
								Paid Fee Details
							</div>
							<div className='h-10 flex justify-center border-b-2 border-black items-center w-full text-center'>
								Amount
							</div>
						</div>
						<div className='h-auto p-4 flex w-full'>
							<ul className='w-full'>
								{fee_breakup &&
									fee_breakup.installments_to_pay?.map((b: any, key: any) => {
										return (
											<>
												<li className='mt-4'>
													{`${key + 1})`} {new Date(b?.due_date).toDateString()}
													{b.applicable_fee_heads.map((v: any) => {
														return (
															<div className='flex w-full justify-between items-center'>
																<span className='pl-5 w-1/2'>{v?.fee_head?.name} :</span>

																<div className='text-center w-1/2'>₹ {v?.to_pay?.toLocaleString()}</div>
															</div>
														);
													})}
													{
														<div className='flex w-full justify-between items-center'>
															<span className='pl-5 w-1/2'>Late Fine :</span>

															<div className='text-center w-1/2'>₹ {b.applicable_late_fine?.sum?.toLocaleString()}</div>
														</div>
													}
												</li>
											</>
										);
									})}
							</ul>
						</div>
						<div className='w-full p-4'>
							{fee_breakup['unpaid_fines'].length > 0 && (
								<div className='text-lg'>
									<span className=' font-medium text-edvion_black'>fine</span>
									<div className='text-sm ml-5'>
										{fee_breakup['unpaid_fines']?.map((fine: any) => {
											return (
												<div className='flex justify-between items-center'>
													<span className='pl-5 w-1/2'>{fine?.name}</span>{' '}
													<span className='text-center w-1/2'>₹ {fine?.amount.toLocaleString('hi')}</span>
												</div>
											);
										})}
									</div>
								</div>
							)}
						</div>
						<div className='w-full p-4'>
							{fee_breakup['applied_discounts'].length > 0 && (
								<div className='text-lg'>
									<span className=' font-medium text-edvion_black'>Discount</span>
									<div className='text-sm ml-5'>
										{fee_breakup['applied_discounts'].map((discount?: any) => {
											return (
												<div className='flex justify-between items-center'>
													<span className='pl-5 w-1/2'>{discount?.discount?.name}</span>{' '}
													<span className='text-center w-1/2'>₹ {discount?.discount?.value.toLocaleString('hi')}</span>
												</div>
											);
										})}
									</div>
								</div>
							)}
						</div>
						{invoice_data?.amount_towards_previous_session_due > 0 && (
							<div className='w-full p-4 pb-2'>
								<div className='text-lg'>
									<div className='flex justify-between items-center'>
										<span className=' font-medium text-edvion_black'>Previous Session Dues:</span>
										<span className='text-center text-sm w-1/2'>
											₹ {invoice_data?.amount_towards_previous_session_due}
										</span>
									</div>
								</div>
							</div>
						)}
						<div className='w-full p-4 pt-2 pb-0 border-t-2 border-black'>
							{invoice_data?.payment_mode === PaymentMode.ONLINE && (
								<div className='text-lg'>
									<div className='flex justify-between items-center'>
										<span className=' font-medium text-edvion_black'>Total</span>
										<span className='text-center text-sm w-1/2'>
											₹{' '}
											{(
												fee_breakup?.amount -
												school.convenience_fee *
													fee_breakup.installments_to_pay
														.map((d: any) => {
															return d.applicable_fee_heads.map((s: any) => {
																if (s.to_pay > 0) {
																	return s.to_pay;
																}
															});
														})
														.filter((s: any) => s.length > 0).length
											).toLocaleString('hi')}
										</span>
									</div>
								</div>
							)}
						</div>
						<div className='w-full p-4'>
							{invoice_data?.payment_mode === PaymentMode.ONLINE && (
								<div className='text-lg'>
									<div className='flex justify-between items-center'>
										<span className=' font-medium text-edvion_black'>Platform Fee</span>
										<span className='text-center text-sm w-1/2'>
											₹{' '}
											{(
												school.convenience_fee *
												fee_breakup.installments_to_pay
													.map((d: any) => {
														return d.applicable_fee_heads.map((s: any) => {
															if (s.to_pay > 0) {
																return s.to_pay;
															}
														});
													})
													.filter((s: any) => s.length > 0).length
											).toLocaleString('hi')}
										</span>
									</div>
								</div>
							)}
						</div>

						<div className='flex w-full'>
							<div className='border-t-2 border-r-2 text-center border-black w-full h-10 flex justify-center items-center'>
								Total (all inclusive)
							</div>
							<div className='h-10 flex justify-center border-t-2 border-black items-center w-full text-center'>
								₹ {fee_breakup?.amount?.toLocaleString('hi')}
							</div>
						</div>
					</div>
				</div>
				<div className='mt-4 '>
					<h4 className='text-xs '>PG Charges as applicable (Additional)</h4>
					<h1 className='text-2xl font-medium mt-3'>Student Fee Details</h1>
					<div className='w-full space-x-4 mt-4 pl-20 items-center flex'>
						<div className='space-y-4'>
							<p className='text-md text-black font-bold'>Paid </p>
						</div>
						<div className='space-y-4'>
							<p className='text-md text-black font-bold'>:</p>
						</div>
						<div className='space-y-4'>
							<p className='text-md text-black font-bold'>₹ {fee_breakup?.amount?.toLocaleString('hi')}</p>
						</div>
					</div>
				</div>
				<div className='flex justify-end items-center'>
					<p className='border-t-2 max-w-[12rem] text-center w-full border-black'>
						<div className='mt-3'>Name</div>
					</p>
				</div>
				<p className='text-md mt-4'>This is a computer generated receipt and does not need a signature.</p>
			</div>
			<div className='flex justify-center mt-5 mb-5'>
				<a
					href={invoice_data.invoice_pdf_link}
					target='_blank'
					className=' text-violet-600 border border-violet-300 p-2 rounded-md'
					rel='noreferrer'
				>
					<span className='mr-1'>
						{`Download Invoice`} <i className='fa-sharp fa-solid fa-download'></i>
					</span>
				</a>
			</div>
			{/* <div>
				<DownloadPDF data={selected_transaction} name={fee_breakup?.student?.name} title={'Invoice'} />
			</div> */}
		</>
	);
};

export default InvoicePDF;
