import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Input from '../../../../../../components/Input/Input';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import Modal from '../../../../../../components/Modal/Modal';
import { preventNegativeValues } from '../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../helper/preventPasteNegative';
import { dashboardContext } from '../../../../Dashboard';
import { Access } from '../../../PortalAccess/AccessEnum';
import LoadingIcon from '../../../../../../assets/loading-purple.svg';
import Form from '../../../../../../components/Form/Form';
function PreviousSchoolDetails({ setPreviousSchoolSelected, getStudent, updateStudent }: any) {
	const [loader, setLoader] = useState(false);
	const { id } = useParams();
	const { user } = useContext(dashboardContext);
	const [student, setStudent] = useState<any>({});
	const [show_modal, set_show_modal] = useState(false);

	useEffect(() => {
		setPreviousSchoolSelected(true);
		return () => {
			setPreviousSchoolSelected(false);
		}; // eslint-disable-next-line
	}, []);

	return (
		<LoadAndRender
			promise={async () => {
				setStudent(await getStudent(id));
			}}
			height='none'
		>
			{loader ? (
				<div className='m-auto'>
					<img src={LoadingIcon} alt='Loading...' />
				</div>
			) : (
				<div className='ml-4 mt-4 mr-2 w-full border-gray-200 rounded-md shadow p-5 mx-5'>
					<Modal open={show_modal} setOpen={set_show_modal}>
						<Form
							onSubmit={async (data: any) => {
								let studentUpdatedData = {
									school_name: data['School Name'] || student[0]?.school_name,
									school_address: data['School Address'] || student[0]?.school_address,
									board: data['School Board'] || student[0]?.board,
									medium_of_instruction: data['Medium Of Instruction'] || student[0]?.medium_of_instruction,
									tc_number: data['Tc Number'] || student[0]?.tc_number,
									last_class_passed: data['Last Class Passed'] || student[0]?.last_class_passed,
									percentage_of_last_class_passed:
										data['Percentage Of Last Class Passed'] || student[0]?.percentage_of_last_class_passed,
								};
								setLoader(true);
								await updateStudent(studentUpdatedData, id);
								setStudent(await getStudent(id));
								setLoader(false);
								set_show_modal(false);
							}}
						>
							<Input value={student[0]?.school_name} type='text' name='School Name' add_error={() => {}} />
							<Input value={student[0]?.school_address} type='text' name='School Address' add_error={() => {}} />
							<Input value={student[0]?.board} type='text' name='School Board' add_error={() => {}} />
							<Input
								value={student[0]?.medium_of_instruction}
								type='text'
								name='Medium Of Instruction'
								add_error={() => {}}
							/>
							<Input value={student[0]?.tc_number} type='number' name='Tc Number' add_error={() => {}} />
							<Input value={student[0]?.last_class_passed} type='text' name='Last Class Passed' add_error={() => {}} />
							<Input
								value={student[0]?.percentage_of_last_class_passed}
								type='number'
								postfix='%'
								name='Percentage Of Last Class Passed'
								add_error={() => {}}
								onKeyDown={preventNegativeValues}
								onPaste={preventPasteNegative}
								min={0}
								maxAmount={100}
							/>
							{(user.access === Access.SUPER ||
								user.access === Access.ALL_ACCESS ||
								user.access === Access.MANAGEMENT) && (
								<div className='mt-4 text-right'>
									<button
										className={` bg-[#6F6AF8]
							 text-white rounded-lg py-2 px-10 `}
									>
										Update
									</button>
								</div>
							)}
						</Form>
					</Modal>
					<div className='flex justify-between items-center mb-2'>
						<h2 className='text-md text-violet-500 text-lg'>Previous Institute Details</h2>
						<i
							className='fa-regular fa-edit mr-2 text-[#959595] text-[20px] cursor-pointer'
							onClick={() => set_show_modal(!show_modal)}
						></i>
					</div>
					<div className='grid grid-cols-2 gap-5'>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Institute Name</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.school_name || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Institute Address</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.school_address || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Board</div>
							<div className='text-base text-edvion_black capitalize'>{student[0]?.board || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Medium Of Instruction</div>
							<div className='text-base text-edvion_black uppercase'>{student[0]?.medium_of_instruction || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Tc Number</div>
							<div className='text-base text-edvion_black uppercase'>{student[0]?.tc_number || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Last Class Passed</div>
							<div className='text-base text-edvion_black uppercase'>{student[0]?.last_class_passed || 'NA'}</div>
						</div>
						<div className='p-2'>
							<div className=' text-base text-[#959595]'>Percentage Of Last Class Passed</div>
							<div className='text-base text-edvion_black uppercase'>
								{student[0]?.percentage_of_last_class_passed
									? `${student[0]?.percentage_of_last_class_passed} %`
									: 'NA'}
							</div>
						</div>
					</div>
				</div>
			)}
		</LoadAndRender>
	);
}

export default PreviousSchoolDetails;
