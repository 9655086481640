import { TransportFeeType } from '../pages/Dashboard/components/FeeConfig/pages/TransportFeeTab/TransportFee';

export const calculateTransportFee = (fee_head: any, student: any) => {
	let amount = 0;
	if (student.distance_in_km && fee_head.transport_fee_type === TransportFeeType.KM_BASED) {
		amount = fee_head.transport_fee_slabs.fees[0] * student.distance_in_km;
		for (let i = 0; i < fee_head.transport_fee_slabs.breakpoints.length; i++) {
			if (student.distance_in_km >= fee_head.transport_fee_slabs.breakpoints[i]) {
				amount = fee_head.transport_fee_slabs.fees[i + 1] * student.distance_in_km;
			}
		}
	} else if (student.distance_in_km && fee_head.transport_fee_type === TransportFeeType.SLAB_BASED) {
		amount = fee_head.transport_fee_slabs.fees[0];
		for (let i = 0; i < fee_head.transport_fee_slabs.breakpoints.length; i++) {
			if (student.distance_in_km >= fee_head.transport_fee_slabs.breakpoints[i]) {
				amount = fee_head.transport_fee_slabs.fees[i + 1];
			}
		}
	} else if (student.stop_id && fee_head.transport_fee_type === TransportFeeType.STOP_BASED) {
		const stop_fee = fee_head.transport_fee_stops?.find((stop: any) => {
			return stop.stop_id && stop.stop_id.toString() === student.stop_id.toString();
		});
		amount = stop_fee?.fees || 0;
	}
	return amount.toLocaleString('hi');
};
