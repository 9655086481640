import React from 'react';

import { Link } from 'react-router-dom';
import Table from '../../../../../../components/Table/Table';
import BackButton from '../../../../../../components/BackButton/BackButton';
import { useQuery } from '@apollo/client';
import { GET_INDIVIDUAL_FINE_REPORT } from '../../Querries';

const FineWiseReport = () => {
	const { data, loading, error } = useQuery(GET_INDIVIDUAL_FINE_REPORT);
	if (loading) return <div>Loading...</div>;
	return (
		<div className='w-full'>
			<div className='mt-2 ml-2 flex items-center py-2'>
				<BackButton label='Fine Wise Report' />
			</div>
			{data?.school?.individual_fines && (
				<Table
					data={[
						['Student Id', 'Name', 'Date', 'Amount', 'Status'],

						...data.school.individual_fines.map((s: any) => [
							<Link to={`/student_profile/${s?.student}`}>
								<span className='text-violet-500 text-base'> {s?.student}</span>
							</Link>,

							s?.name,
							new Date(s.createdAt).toLocaleDateString('hi'),
							'₹' + s.amount.toLocaleString('hi'),
							s.is_paid ? 'Paid' : 'Unpaid',
						]),
					]}
				/>
			)}
		</div>
	);
};

export default FineWiseReport;
