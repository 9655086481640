import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { RECHARGE_WALLET } from '../Querries';
import Modal from '../../../../../components/Modal/Modal';
import Input from '../../../../../components/Input/Input';
import { preventNegativeValues } from '../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../helper/preventPasteNegative';
function WalletCard({ icon, amount, label, amountStyle, creditBoxStyle, type, balance, rate }: any) {
	const [showRechargeModal, setShowRechargeModal] = useState(false);
	const [rechargeWallet, { loading }] = useMutation(RECHARGE_WALLET);
	const [balanceAmount, setBalanceAmount] = useState(0);

	return (
		<>
			<Modal open={showRechargeModal} setOpen={setShowRechargeModal}>
				<div className='max-w-xs mx-auto my-8'>
					<Input
						type='number'
						labelStyle='text-[#6F6AF8]'
						onChange={(e: any, v: any) => setBalanceAmount(Number(v))}
						name={`Available balance: ₹ ${amount?.toLocaleString('hi')}`}
						add_error={() => {}}
						onKeyDown={preventNegativeValues}
						onPaste={preventPasteNegative}
						min={0}
						required
					/>

					<button
						disabled={balanceAmount <= 0}
						onClick={async () => {
							const res = await rechargeWallet({
								variables: {
									amount: balanceAmount,
									wallet_type: type.name.toUpperCase(),
								},
							});
							if (res?.data['recharge_wallet']) {
								window.open(res?.data['recharge_wallet'], '_blank');
								setShowRechargeModal(false);
								setBalanceAmount(0);
							}
						}}
						className='bg-[#6F6AF8] disabled:bg-gray-400 w-full py-1 mt-3 px-4 rounded-lg text-white text-xl font-medium'
					>
						{loading ? (
							<i className='fa-solid animate-spin fa-spinner'></i>
						) : (
							`Proceed to add ₹ ${balanceAmount.toLocaleString('hi')}`
						)}
					</button>
				</div>
			</Modal>
			<div className='flex flex-col justify-between rounded-lg space-y-4 shadow overflow-hidden p-5 w-full'>
				<h4 className=' text-[18px] font-medium text-edvion_black'>{label}</h4>
				<div className='flex space-x-4 items-center  w-full'>
					<div>{icon && icon}</div>
					<div className='flex space-x-4 justify-between items-center  w-full'>
						<p className={'truncate	' + amountStyle}>
							{label === 'Required Balance (next month)' && type?.name === 'WhatsApp' ? (
								<>₹ {balance.toLocaleString('hi')}</>
							) : (
								<>{label === 'Current Balance' && `₹ ${amount.toLocaleString('hi')}`}</>
							)}
							{label === 'Required Balance (next month)' && type?.name === 'SMS' && '₹ 0'}
						</p>
						{label === 'Current Balance' && (
							<button
								onClick={async () => {
									setShowRechargeModal(true);
								}}
								className='bg-[#6F6AF8] px-4 rounded-lg py-2 text-xl text-white'
							>
								Recharge
							</button>
						)}
					</div>
				</div>
				<div className='flex space-x-4 items-center text-edvion_black w-full'>
					{type?.name === 'WhatsApp' && (
						<div className={' max-w-fit rounded-lg py-1 px-4 w-full ' + creditBoxStyle}>
							<h5>
								WhatsApp credits:{' '}
								{label === 'Required Balance (next month)' && type?.name === 'WhatsApp'
									? isNaN(balance / rate)
										? 0
										: balance / rate
									: amount / rate}
							</h5>
						</div>
					)}
					{type?.name === 'SMS' && (
						<div className={'max-w-fit rounded-lg py-1 px-4 w-full ' + creditBoxStyle}>
							<h5>
								SMS credits: {label === 'Required Balance (next month)' && type?.name === 'SMS' ? 0 : amount / rate}
							</h5>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default WalletCard;
