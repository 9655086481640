import { gql } from '@apollo/client';

export const CREATE_TRANSPORT_FEE = gql`
	mutation Create_transport_fee(
		$frequency_months: Float!
		$name: String!
		$start_date: DateTime!
		$tax: Float!
		$type: TransportFeeType!
		$slab_details: SlabDetailsInput!
		$end_date: DateTime!
	) {
		create_transport_fee(
			frequency_months: $frequency_months
			name: $name
			start_date: $start_date
			tax: $tax
			type: $type
			slab_details: $slab_details
			end_date: $end_date
		) {
			_id
			school_id
		}
	}
`;

export const CREATE_STOP = gql`
	mutation Create_stop($name: String!) {
		create_stop(name: $name) {
			_id
			name
			school_id
		}
	}
`;

export const GET_ALL_STOPS = gql`
	query School {
		school {
			stops {
				name
				school_id
				_id
			}
		}
	}
`;

export const CREATE_STOP_WISE_FEE = gql`
	mutation Create_stop_wise_transport_fee(
		$frequency_months: Float!
		$name: String!
		$start_date: DateTime!
		$tax: Float!
		$type: TransportFeeType!
		$stops: [StopInput!]
		$end_date: DateTime!
	) {
		create_transport_fee(
			frequency_months: $frequency_months
			name: $name
			start_date: $start_date
			tax: $tax
			type: $type
			stops: $stops
			end_date: $end_date
		) {
			end_date
			frequency_months
			gender
			is_trasport_fee
			name
			school_id

			_id
		}
	}
`;

export const DELETE_STOP = gql`
	mutation Delete_stop($id: String!) {
		delete_stop(id: $id)
	}
`;
