import React, { useState, useContext, createContext } from 'react';
import { useEffect } from 'react';
import rightarrow from '../../../../assets/rightarrow.svg';
import LoadAndRender from '../../../../../../components/LoadAndRender/LoadAndRender';
import Modal from '../../../../../../components/Modal/Modal';
import Table from '../../../../../../components/Table/Table';

import CollectFeeModal from '../../../FeeCollection/pages/CollectOverview/CollectFeeModal';
import { dashboardContext } from '../../../../Dashboard';
import Form from '../../../../../../components/Form/Form';
import Input from '../../../../../../components/Input/Input';
import RadioInput from '../../../../../../components/RadioInput/RadioInput';
import { Access } from '../../../PortalAccess/AccessEnum';
import { preventNegativeValues } from '../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../helper/preventPasteNegative';
import { useMutation, useQuery } from '@apollo/client';
import { GET_INSTALLMENTS } from '../../../FeeCollection/pages/CollectOverview/Queries';
import * as queries from './Queries';
import { Mixpanel } from '../../../../../../util/mixpanel';
import {
	create_fee_head,
	fee_type_string_to_frequency_months,
	get_fee_heads,
} from '../../../FeeConfig/pages/FeeStructureTab/FeeStructureTab';
import { delete_fee_head, frequency_to_text } from '../../../FeeConfig/components/FeeHeadCard/FeeHeadCard';
import { deletDiscount } from '../../../FeeConfig/pages/DiscountsTab/DiscountsTab';
import { toast } from 'react-toastify';


import Select from '../../../../../../components/Select/Select';
import { ADD_TAG_STUDENT, GET_STUDENT_TAGS, GET_TAGS, REMOVE_TAG_FROM_STUDENT } from '../../../SchoolTags/Querries';
import { NewTags } from '../../../../../../components/Tags/Tags';

import { calculateTransportFee } from '../../../../../../helper/calculateFee';
import ConfirmationBox from '../../../../../../components/ConfirmationBox/ConfirmationBox';


export enum DiscountType {
	RECURRING_PERCENTAGE_OF_FEE_HEAD = 'RECURRING_PERCENTAGE_OF_FEE_HEAD',
	RECURRING_PERCENTAGE_OF_INSTALLMENT = 'RECURRING_PERCENTAGE_OF_INSTALLMENT',
	ABSOLUTE_ONE_TIME = 'ABSOLUTE_ONE_TIME',
}

export const feeDetailsContext = createContext<any>(null);
const getFeeData = async (studentId: any) => {
	let myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

	var requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	let res = await fetch(
		process.env.REACT_APP_BACKEND_URL + `/api/student/dues?student_id=${studentId}`,
		requestOptions,
	);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

const get_fines = async (id: any) => {
	let myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	var requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	const res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/student/fine?id=${id}`, requestOptions);

	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

const getStudentDiscountsById = async (id: any) => {
	let myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
	var requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	const res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/student/discounts/${id}`, requestOptions);

	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

const getStudentDues = async (id: any) => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

	var requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	const res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/student/dues?student_id=${id}`, requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};
// @ts-ignore

const Summary = ({
	statusData,
	setViewDetails,
	set_status_wise,
	installments,
	value,
	total_installment_discount,
	student_id,
}: any) => {
	return (
		<div className=''>
			<div className='text-base'>Fee Summary</div>
			<div className='grid grid-cols-2'>
				<div className='mt-4  p-4  rounded-lg border-2 border-gray-200'>
					<div>
						₹{' '}
						{installments.length > 0 &&
							installments
								?.map((d: any) => {
									return d?.applicable_fee_heads?.reduce((a: any, b: any) => {
										return a + b.amount_after_discounts;
									}, 0);
								})
								?.reduce((a: any, b: any) => {
									return a + b;
								})
								.toLocaleString()}
					</div>
					<div className='flex text-[12px]'>
						<div>Total Annual Fee</div>
						<div
							onClick={() => {
								setViewDetails(true);
								set_status_wise('Total');
							}}
							className='text-violet-500 ml-2 cursor-pointer'
						>
							View Details
						</div>
					</div>
				</div>

				<div className=' mt-4  p-4 ml-5 rounded-lg border-2 border-gray-200'>
					<div className='text-[16px] text-green-400'>
						₹{' '}
						{installments.length > 0 &&
							installments
								?.map((d: any) => {
									return d?.applicable_fee_heads.reduce((a: any, b: any) => {
										return a + b.amount_after_discounts - b.to_pay;
									}, 0);
								})
								?.reduce((a: any, b: any) => {
									return a + b;
								})
								.toLocaleString()}
					</div>
					<div className='flex text-[12px]'>
						<div>Paid</div>
						<div
							onClick={() => {
								setViewDetails(true);
								set_status_wise('Paid');
							}}
							className='text-violet-500 ml-2 cursor-pointer'
						>
							View Details
						</div>
					</div>
				</div>
				<div className=' mt-4  p-4  rounded-lg border-2 border-gray-200'>
					<div className='text-[16px] text-red-400'>
						₹{' '}
						{installments.length > 0 &&
							installments
								?.map((d: any) => {
									return d?.applicable_fee_heads.reduce((a: any, b: any) => {
										return a + b.to_pay;
									}, 0);
								})
								.reduce((a: any, b: any) => {
									return a + b;
								})
								.toLocaleString()}
					</div>
					<div className='flex text-[12px]'>
						<div>Unpaid/Overdue</div>
						<div
							onClick={() => {
								setViewDetails(true);
								set_status_wise('Unpaid');
							}}
							className='text-violet-500 ml-2 cursor-pointer'
						>
							View Details
						</div>
					</div>
				</div>
				{/* <div className=' mt-4  p-4 ml-5  rounded-lg border-2 border-gray-200'>
					<div className='text-[16px] text-red-400'>₹ {0}</div>
					<div className='flex text-[12px]'>
						<div>Applied Discount</div>
						<div
							onClick={() => {
								setViewDetails(true);
								set_status_wise('Applied Discount');
							}}
							className='text-violet-500 ml-2 cursor-pointer'
						>
							View Details
						</div>
					</div>
				</div> */}
			</div>
		</div>
	);
};

//@ts-ignore
const Installments = ({
	installments,
	setViewDetails,
	viewDetails,
	feeDetails,
	statusData,
	status_wise,
	set_status_wise,
	category,
	fee_heads,
	delete_individual_fine,
	student,
}: any) => {
	const [total, setTotal] = useState(0);
	const [paid, setPaid] = useState(0);
	const [due, setDue] = useState(0);
	const {
		view_fine,
		view_discount,
		set_view_fine,
		set_view_discount,
		fines,
		discounts,
		set_fines,
		studentId,
		set_discounts,

		school,
	} = useContext(feeDetailsContext);
	const { set_students, getStudent } = useContext(dashboardContext);
	const [get_discounts, set_get_discounts] = useState([]);
	const [get_fee_heads, set_get_fee_heads] = useState<any>([]);
	const [fee_head_modal, set_fee_head_modal] = useState(false);
	const [deleteDiscountModal, setDeleteDiscountModal] = useState(false);
	const [deleteFineModal, setDeleteFineModal] = useState(false);
	const [deleteFeeHeadModal, setDeleteFeeHeadModal] = useState(false);
	const [tags_modal, set_tags_modal] = useState(false);
	const [studenTags, setStudentTags] = useState<any>([]);
	const [id, setId] = useState('');
	const [tag, setTag] = useState({
		tagCode: '',
		name: '',
		description: '',
	});
	const [isTagOpen, setIsTagOpen] = useState(false);
	const {
		data: studentTags,
		loading: studentTagsLoading,
		error: studentTagsError,
	} = useQuery(GET_STUDENT_TAGS, {
		variables: {
			id: studentId,
		},
	});
	
	//console.log({studentTags})

	const getFeeHeads = async () => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};

		const res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/school/fee_heads`, requestOptions);
		if (res.ok) {
			set_get_fee_heads(await res.json());
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
		}
	};



	const [remove_tag_student] = useMutation(REMOVE_TAG_FROM_STUDENT);


	useEffect(() => {
		const getDiscounts = async () => {
			var myHeaders = new Headers();
			myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

			var requestOptions: RequestInit = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow',
			};

			const res = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/fee-config/discounts`, requestOptions);
			if (res.ok) {
				set_get_discounts(await res.json());
				//return await res.json();
			} else {
				const message = await res.json();
				if (message.statusCode === 401) {
					localStorage.removeItem('token');
					window.location.reload();
				}
			}
		};
		getFeeHeads();
		getDiscounts();
		//console.log(student[0])
	}, []);

	return (
		<div className='mt-6'>
			<div className='flex justify-between items-center'>
				<div>Installments</div>
				<div>{/* <img src={dotIcon} alt='' /> */}</div>
			</div>
			<div className='mt-4 grid grid-cols-3 gap-2'>
				<div
					onClick={() => {
						setViewDetails(true);
						set_status_wise('Total');
					}}
					className='cursor-pointer flex justify-between text-center rounded-lg border-2 border-gray-200 '
				>
					<div className='text-left p-4'>
						<div>{installments?.length}</div>
						<div>Total</div>
					</div>
					<div
						onClick={() => {
							setViewDetails(true);
							set_status_wise('Total');
						}}
						className='p-4 cursor-pointer'
					>
						<img src={rightarrow} alt='' />
					</div>
				</div>
				<div
					onClick={() => {
						setViewDetails(true);
						set_status_wise('Paid');
					}}
					className='cursor-pointer flex justify-between text-center rounded-lg border-2 border-gray-200 '
				>
					<div className='text-left p-4'>
						<div>
							{
								installments

									?.map((d: any) => {
										return d.to_pay > 0 ? 'Unpaid' : 'Paid';
									})
									.filter((d: any) => {
										return d === 'Paid';
									}).length
							}
						</div>
						<div>Paid</div>
					</div>
					<div
						onClick={() => {
							setViewDetails(true);
							set_status_wise('Paid');
						}}
						className='p-4 cursor-pointer'
					>
						<img src={rightarrow} alt='' />
					</div>
				</div>
				<div
					onClick={() => {
						setViewDetails(true);
						set_status_wise('Unpaid');
					}}
					className='cursor-pointer flex justify-between text-center rounded-lg border-2 border-gray-200 '
				>
					<div className='text-left p-4'>
						<div>
							{
								installments

									?.map((d: any) => {
										return d.to_pay > 0 ? 'Unpaid' : 'Paid';
									})
									?.filter((d: any) => {
										return d === 'Unpaid';
									}).length
							}
						</div>
						<div>Due</div>
					</div>
					<div
						onClick={() => {
							setViewDetails(true);
							set_status_wise('Unpaid');
						}}
						className='p-4 cursor-pointer'
					>
						<img src={rightarrow} alt='' />
					</div>
				</div>
				<div
					onClick={() => {
						set_view_discount(true);
					}}
					className='cursor-pointer flex justify-between text-center rounded-lg border-2 border-gray-200 '
				>
					<div className='text-left p-4'>
						<div>
							{
								get_discounts
									.map((data: any) => data)
									.filter((discount: any) => {
										return (
											discount.student?._id === studentId ||
											(discount.applicable_on === 'GROUP' && discount.group_category.includes(category))
										);
									})?.length
							}
						</div>
						<div>Discounts</div>
					</div>
					<div
						onClick={() => {
							set_view_discount(true);
						}}
						className='p-4 cursor-pointer'
					>
						<img src={rightarrow} alt='' />
					</div>
				</div>
				<div
					onClick={() => {
						set_view_fine(true);
					}}
					className='cursor-pointer flex justify-between text-center rounded-lg border-2 border-gray-200 '
				>
					<div className='text-left p-4'>
						<div>{fines.length}</div>
						<div>Fines</div>
					</div>
					<div
						onClick={() => {
							set_view_fine(true);
						}}
						className='p-4 cursor-pointer'
					>
						<img src={rightarrow} alt='' />
					</div>
				</div>
				<div
					onClick={() => {
						set_fee_head_modal(true);
					}}
					className='cursor-pointer flex justify-between text-center rounded-lg border-2 border-gray-200 '
				>
					<div className='text-left p-4'>
						<div>{fee_heads ? fee_heads.length : 0}</div>
						<div>Fee Head</div>
					</div>
					<div className='p-4 cursor-pointer'>
						<img src={rightarrow} alt='' />
					</div>
				</div>

				<div
					onClick={() => {
						//set_fee_head_modal(true);
						set_tags_modal(true);
					}}
					className='cursor-pointer flex justify-between text-center rounded-lg border-2 border-gray-200 '
				>
					<div className='text-left p-4'>
						<div>{studentTags?.student?.tags ? studentTags?.student?.tags.length : 0}</div>
						<div>Tags</div>
					</div>
					<div className='p-4 cursor-pointer'>
						<img src={rightarrow} alt='' />
					</div>
				</div>
			</div>

			<Modal title={''} open={isTagOpen} setOpen={setIsTagOpen}>
				<div className='flex flex-col justify-between items-center mb-2'>
					<h2 className='text-md text-center text-violet-500 text-lg'>Tag Details</h2>
				</div>
				<div className='flex justify-evenly items-center gap-5'>
					<div className='p-2 text-center'>
						<div className=' text-base text-[#959595]'>Tag Code</div>
						<div className='text-base text-edvion_black capitalize'>{tag.tagCode}</div>
					</div>
					<div className='p-2 text-center'>
						<div className=' text-base text-[#959595]'>Tag Name</div>
						<div className='text-base text-edvion_black capitalize'>{tag.name}</div>
					</div>
				</div>
				<div className='p-2 text-center'>
					<div className=' text-base text-[#959595]'>Tag Description</div>
					<div className='text-base text-edvion_black capitalize'>{tag.description}</div>
				</div>
			</Modal>

			<Modal title='Tags' className='max-w-7xl' open={tags_modal} setOpen={set_tags_modal}>
				{studentTags?.student?.tags && (
					<Table
						data={[
							['Tag Name', 'Tag Code', 'Tag Description', 'Action'],
							...studentTags?.student?.tags?.map((tag: any) => [
								<div>{tag.name.length>20?`${tag.name.substring(0,5)}...`:tag.name}
								</div>,
								<div>{tag.tagCode.length>20?`${tag.tagCode.substring(0,5)}...`:tag.tagCode}
								</div>,
								<div
									className=''
									onClick={() => {
										//setTag({ ...tag });
										//setIsTagOpen(!isTagOpen);
									}}
								>
									{tag.description.length > 20 ? `${tag.description.substring(0, 20)}...` : tag.description}
								</div>,
								<div>
									<i
										onClick={async () => {
											try {
												const res = await remove_tag_student({
													variables: {
														student: studentId,
														tag: tag._id,
													},
													refetchQueries: [{ query: GET_STUDENT_TAGS, variables: { id: studentId } },
														{ query: GET_TAGS}],
												});
												toast.success(`tag deleted successfully`);
												set_students(await getStudent());
											} catch (error) {
	
											}
										}}
										className='fa-solid fa-trash cursor-pointer'
										aria-hidden='true'
									></i>
								</div>,
							]),
						]}
					></Table>
				)}
			</Modal>
			<Modal title='Installment Details' open={viewDetails} setOpen={setViewDetails} className='max-w-7xl'>
				{installments && (
					<Table
						data={[
							['Installment No.', 'Due Date', 'Amount', 'Status'],

							...installments
								?.map((install: any, key: any) => [
									++key,
									new Date(install.due_date).toDateString(),

									<div>
										₹
										{install?.applicable_fee_heads
											?.map((fee: any) => {
												return fee.amount_after_discounts;
											})
											?.reduce((a: any, b: any) => {
												return a + b;
											})
											.toLocaleString()}
									</div>,

									<div>{install.to_pay > 0 ? 'Unpaid' : 'Paid'}</div>,
								])
								.filter((res: any) => {
									if (status_wise === 'Total') return true;
									if (status_wise === 'Paid' && res[3].props.children === 'Paid') return true;
									if (status_wise === 'Unpaid' && res[3].props.children === 'Unpaid') return true;
								}),
						]}
					/>
				)}
			</Modal>

			<Modal title='Discounts Details' open={view_discount} setOpen={set_view_discount} className='max-w-7xl'>
				<Table
					heading={'Discounts'}
					data={[
						['Name', 'Frequency', 'Amount', 'Status', 'Action'],
						...get_discounts
							.map((data: any) => data)
							.filter((discount: any) => {
								return (
									discount.student?._id === studentId ||
									(discount.applicable_on === 'GROUP' && discount.group_category.includes(category))
								);
							})
							.map((discount: any) => [
								discount.name,
								<div>{discount?.discount_type === DiscountType.ABSOLUTE_ONE_TIME ? 'One Time' : 'Recurring'}</div>,
								<div>
									{discount?.discount_type === DiscountType.ABSOLUTE_ONE_TIME
										? `₹ ${discount?.value.toLocaleString('hi')}`
										: `${discount?.value} % of ${
												discount?.discount_type === DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD
													? get_fee_heads.filter((f: any) => f._id === discount?.fee_head)[0]?.name
													: discount?.discount_type === DiscountType.RECURRING_PERCENTAGE_OF_INSTALLMENT
													? 'Installment'
													: null
										  }`}
								</div>,
								<div>{discount?.availed ? 'Applied' : 'Not Applied'}</div>,
								discount.applicable_on !== 'GROUP' ? (
									<div>
										<i
											onClick={() => {
												setId(discount._id);
												setDeleteDiscountModal(true);
											}}
											className='fa-solid fa-trash cursor-pointer'
											aria-hidden='true'
										></i>
									</div>
								) : (
									'-'
								),
							]),
					]}
				></Table>
			</Modal>
			<Modal title='Fine Details' open={view_fine} setOpen={set_view_fine} className='max-w-7xl'>
				<Table
					heading={'Fines'}
					data={[
						['Name', 'Amount', 'Status', 'Action'],
						...fines.map((fine: any) => [
							fine.name,
							<div>₹ {fine.amount}</div>,
							fine.is_paid ? 'Paid' : 'Unpaid',
							fine.is_paid ? (
								'-'
							) : (
								<div>
									<i
										onClick={() => {
											setId(fine._id);
											setDeleteFineModal(true);
										}}
										className='fa-solid fa-trash cursor-pointer'
										aria-hidden='true'
									></i>
								</div>
							),
						]),
					]}
				></Table>
			</Modal>
			<Modal title='Fee Head' open={fee_head_modal} setOpen={set_fee_head_modal} className='max-w-7xl'>
				<Table
					data={[
						['Name', 'Amount', 'Type', 'Starting Date', 'End Date', 'Division', 'Action'],
						...fee_heads.map((feeHead: any) => [
							feeHead?.name,
							<div>
								₹ {feeHead?.amount && (feeHead?.amount as number)?.toLocaleString('hi')}
								{feeHead.is_trasport_fee === true && calculateTransportFee(feeHead, student)}
							</div>,
							frequency_to_text(feeHead?.frequency_months),
							new Date(feeHead?.start_date).toDateString(),
							new Date(feeHead?.end_date).toDateString(),
							<div>
								{feeHead.is_trasport_fee === true
									? feeHead.is_trasport_fee === true && 'Transport Fee'
									: feeHead?.student
									? 'Individual'
									: 'Group'}
							</div>,
							feeHead?.student ? (
								<i
									onClick={() => {
										setId(feeHead?._id);
										setDeleteFeeHeadModal(true);
									}}
									className='fa-solid fa-trash cursor-pointer'
									aria-hidden='true'
								></i>
							) : (
								'-'
							),
						]),
					]}
				></Table>
			</Modal>
			<Modal open={deleteFineModal} setOpen={setDeleteFineModal}>
				<ConfirmationBox
					setOpen={setDeleteFineModal}
					funtion={async () => {
						try {
							await delete_individual_fine({
								variables: {
									fine_id: id,
								},
							});
							setDeleteFineModal(false);
						} catch (err) {
							console.log(err);
						}
					}}
					confirmationText='delete fine'
					ButtonText='Delete'
				/>
			</Modal>
			<Modal open={deleteDiscountModal} setOpen={setDeleteDiscountModal}>
				<ConfirmationBox
					setOpen={setDeleteFineModal}
					funtion={async () => {
						try {
							const res = await deletDiscount(id);
							setDeleteDiscountModal(false);
							const message = await res?.json();
							if (message.error) toast.error(message.error);
							if (!message.error) window.location.reload();
						} catch (err) {
							console.log(err);
						}
					}}
					confirmationText='delete discount'
					ButtonText='Delete'
				/>
			</Modal>
			<Modal open={deleteFeeHeadModal} setOpen={setDeleteFeeHeadModal}>
				<ConfirmationBox
					setOpen={setDeleteFineModal}
					funtion={async () => {
						try {
							const res = await delete_fee_head(id);
							if (res.error) toast.error(res.error);
							if (!res.error) window.location.reload();
						} catch (err) {
							console.log(err);
						}
					}}
					confirmationText='delete fee head'
					ButtonText='Delete'
				/>
			</Modal>
		</div>
	);
};
//@ts-ignore

const FeeDetails = ({ studentId, setFeeSelected, studentdata, updateStudent, installments }: any) => {
	const [installmentData, setInstallmentData] = useState<any>();
	const { classes } = useContext(dashboardContext);
	const [feeDetails, setFeeDetails] = useState<any>();
	const [fee_heads, set_fee_heads] = useState<any>([]);
	const [fee_head, set_fee_head] = useState<any>({});
	const [viewDetails, setViewDetails] = useState(false);
	const [statusData, setStatusData] = useState<any>();
	const [fee_type, set_fee_type] = useState('');
	const [fee_value, set_fee_value] = useState<any>(null);
	const [discount_type, set_discount_type] = useState<any>('');
	const [discount_applied_on, set_discount_applied_on] = useState<any>('');
	const [show_modal, set_modal] = useState(false);
	const [discountModalShow, setDiscoutModalShow] = useState(false);
	const [addFeeHeadModalShow, setAddFeeHeadModalShow] = useState(false);
	const { students, user, school } = useContext(dashboardContext);
	const student = students.filter((s: any) => s._id === studentId);
	const [status_wise, set_status_wise] = useState('');
	const [fineModal, setFineModal] = useState(false);
	const [view_discount, set_view_discount] = useState(false);
	const [view_fine, set_view_fine] = useState(false);
	const [addTagModal, setAddTagModal] = useState(false);
	const [selectedTags, setSelectedTags] = useState([]);
	const [differentTag, setDifferentTag] = useState([]);

	const [_classes, setClasses] = useState<any>([]);
	// const [fines, set_fines] = useState<any>([]);
	const [set_discounts] = useState<any>([]);
	const [discount_name, set_discount_name] = useState<any>('');
	const [sendReminderConfirmationModal, setSendReminderConfirmationModal] = useState(false);
	const [send_whatsapp_reminder_to_student] = useMutation(queries.SEND_WHATSAPP_REMINDER_TO_STUDENT);
	const { getStudent, set_students } = useContext(dashboardContext);
	const [feeValue, setFeeValue] = useState<any>({
		total_annual_fee: 0,
		paid: 0,
		unpaid_or_due: 0,
		applied_discount: 0,
	});
	const [total_installment_discounts, set_total_installment_discounts] = useState(0);
	const [createDiscount, { data: result, loading, error }] = useMutation(queries.CREATE_DISCOUNT);
	const [create_fine] = useMutation(queries.CREATE_FINE, {
		refetchQueries: [
			{
				query: GET_INSTALLMENTS,
				variables: {
					id: studentId,
				},
			},
		],
	});
	const [delete_individual_fine] = useMutation(queries.DELETE_INDIVIDUAL_FINE, {
		refetchQueries: [
			{
				query: GET_INSTALLMENTS,
				variables: {
					id: studentId,
				},
			},
		],
	});
	// console.log(fee_heads.filter((f: any) => f.student === student[0]._id || f.class.includes(student[0].class)));

	const { data: tags, loading: tagLoading, error: tagError } = useQuery(GET_TAGS);

	const [addTag] = useMutation(ADD_TAG_STUDENT);

	const {
		data: studentTags,
		loading: studentTagsLoading,
		error: studentTagsError,
	} = useQuery(GET_STUDENT_TAGS, {
		variables: {
			id: studentId,
		},
	});
	//console.log(tags)
	const getFeeHeadForGroup = async () => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
		myHeaders.append('Content-Type', 'application/json');

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};

		const res = await fetch(
			process.env.REACT_APP_BACKEND_URL + `/api/fee-config/fee-head-group?class=${student[0]?.class}`,
			requestOptions,
		);
		if (res.ok) {
			return await res.json();
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
		}
	};
	useEffect(() => {
		(async () => {
			if (discount_applied_on === 'RECURRING_PERCENTAGE_OF_FEE_HEAD') {
				set_fee_heads(await getFeeHeadForGroup());
			}
		})();
	}, [discount_applied_on]);

	useEffect(() => {
		setFeeSelected(true);

		return () => {
			setFeeSelected(false);
		}; // eslint-disable-next-line
	}, []);

	useEffect(() => {
		set_fee_value(0);
	}, [discount_type]);

	const get_fee_head_by_student = async (id: any) => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
		myHeaders.append('Content-Type', 'application/json');

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,

			redirect: 'follow',
		};
		const res = await fetch(
			process.env.REACT_APP_BACKEND_URL + `/api/student/fee_head?student_id=${id}`,
			requestOptions,
		);
		if (res.ok) {
			return await res.json();
		} else {
			const message = await res.json();
			if (message.statusCode === 401) {
				localStorage.removeItem('token');
				window.location.reload();
			}
		}
	};

	const {
		loading: loading2,
		data: get_installments_response,
		error: error2,
	} = useQuery(GET_INSTALLMENTS, {
		variables: {
			id: studentId,
		},
	});

	if (loading || loading2) return <div>Loading...</div>;
	else if (error || error2) return <div>Error</div>;
	const {
		installments: installments2,
		individual_fines: fines,
		absolute_discounts: discounts,
	} = get_installments_response?.student;

	return (
		<feeDetailsContext.Provider
			value={{
				view_fine,
				set_view_fine,
				view_discount,
				set_view_discount,
				fines,
				discounts,
				studentId,
				set_discounts,
				school,
			}}
		>
			<LoadAndRender
				promise={async () => {
					set_fee_heads(await get_fee_heads());
				}}
				height='h-full'
			>
				<Modal
					open={discountModalShow}
					setOpen={setDiscoutModalShow}
					title='Create Discount'
					className='h-full '
					children={
						// <LoadAndRender promise={async () => {}} height={'0px'}>
						<div>
							<div className='w-full flex justify-center items-center'>
								<RadioInput
									onChange={(n: any, v: any) => {
										set_discount_type(v);
										// seType(() => {
										// 	if (v === 'Absolute One Time') return 'absolute-one-time';
										// 	if (v === 'Recurring Percentage') return 'recurring-percentage';
										// });
									}}
									color='text-violet-500'
									add_error={() => {}}
									options={['Absolute One Time', 'Recurring Percentage']}
									required
								/>
							</div>
							{discount_type && (
								<Form
									className='w-full h-full grid grid-cols-2 gap-4'
									onSubmit={async (data: any) => {
										let discount: any;
										discount_type === 'Recurring Percentage'
											? (discount = {
													value: fee_value,
													student: studentId,
													fee_head: fee_head[0]?._id,
													name: data['Discount Name'],
													// type: fee_type.toUpperCase(),
													discount_type:
														discount_applied_on === DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD
															? DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD
															: DiscountType.RECURRING_PERCENTAGE_OF_INSTALLMENT,
													// group_category: student[0].category,
													// group_gender: student[0].gender,
													// group_class: student[0].class,
											  })
											: (discount = {
													value: fee_value,
													student: studentId,
													name: data['Discount Name'],
													// type: fee_type.toUpperCase(),
													discount_type: DiscountType.ABSOLUTE_ONE_TIME,
													// group_category: student[0].category,
													// group_gender: student[0].gender,
													// group_class: student[0].class,
											  });

										await createDiscount({
											variables: {
												type: discount?.discount_type,
												fee_head_id: fee_head[0]?._id,
												value: discount?.value,
												student_id: studentId,
												discount_name: discount?.name,
											},
										});
										// set_discounts(await getStudentDiscountsById(studentId));
										setDiscoutModalShow(false);
									}}
								>
									<Input
										onChange={(n: any) => {
											set_discount_name(n);
										}}
										add_error={() => {}}
										name='Discount Name'
										type='text'
										placeholder='Enter Name'
										required
									/>

									<div className='flex space-x-4 sm:items-center sm:flex-row flex-col'>
										{/* <div className='w-full flex-1'>
											<RadioInput
												onChange={(n: any, v: any) => {
													set_discount_type(v);
												}}
												add_error={() => {}}
												name='Frequency'
												options={fee_heads?.length > 0 ? ['Recurring', 'One Time'] : ['One Time']}
												required
											/>
										</div> */}

										<div className='flex-1'>
											<Input
												onChange={(n: any, v: any) => {
													set_fee_value(Number(v));
												}}
												add_error={() => {}}
												name='Value'
												type='number'
												value={fee_value}
												prefix={discount_type === 'Absolute One Time' ? '₹' : '%'}
												onKeyDown={preventNegativeValues}
												onPaste={preventPasteNegative}
												min={0}
												maxAmount={discount_type === 'Recurring Percentage' && 100}
												placeholder='Enter Value'
												required
											/>
										</div>
									</div>
									<div>
										{discount_type === 'Recurring Percentage' && (
											<RadioInput
												name='Discount'
												onChange={(n: any, v: any) => {
													if (v === 'Feehead') set_discount_applied_on(DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD);
													else set_discount_applied_on(DiscountType.RECURRING_PERCENTAGE_OF_INSTALLMENT);
												}}
												add_error={() => {}}
												options={['Feehead', 'Installment']}
												required
											/>
										)}
									</div>

									{/* 
								<div>
									<RadioInput
										onChange={(n: any, v: any) => {
											set_fee_type(v);
										}}
										add_error={() => {}}
										name='Fee Type'
										options={discount_type === 'Recurring' ? ['Absolute', 'Percentage'] : ['Absolute']}
										required
									/>
								</div> */}

									{discount_type === 'Recurring Percentage' &&
									discount_applied_on === DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD ? (
										<>
											{fee_heads?.length > 0 ? (
												// <Select
												// 	selected={
												// 		!Object.keys(fee_head).length
												// 			? {
												// 					name: fee_heads[0]?.name,
												// 					id: fee_heads[0]?._id,
												// 			  }
												// 			: fee_head
												// 	}
												// 	setSelected={set_fee_head}
												// 	label='Select Fee Head'
												// 	options={fee_heads?.map((fee: any) => {
												// 		return { name: fee?.name, id: fee?._id };
												// 	})}
												// />
												<RadioInput
													name='Select Fee Head'
													onChange={(e: any, v: any) => {
														set_fee_head(
															Object.values(fee_heads).filter((fee: any) => {
																return fee?.name === v;
															}),
														);
													}}
													add_error={() => {}}
													options={Object.values(fee_heads).map((fee: any) => {
														return fee?.name;
													})}
													required
												/>
											) : (
												<div></div>
											)}
										</>
									) : (
										<div></div>
									)}

									{/* <CheckboxInput name='Gender' options={['male', 'female', 'others']} required />
							<CheckboxInput name='Category' options={['general', 'st', 'sc', 'obc']} required />
							<CheckboxInput name='Class' options={[...classes]} required /> */}

									<div className=' col-span-2 flex justify-center items-center'>
										<button
											disabled={
												// ((discount_type === 'Recurring' ? (fee_head.length ? false : true) : true) && fee_type?.length
												// 	? false
												// 	: true) && (discount_type !== 'One Time' && fee_type?.length ? false : true)
												discount_applied_on === DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD && !fee_head
													? true
													: false || !fee_value
													? true
													: false || !discount_name.length
													? true
													: false || (discount_type === 'Recurring Percentage' && !discount_applied_on.length)
													? true
													: false
													? true
													: false ||
													  (discount_type === 'Recurring Percentage' &&
															discount_applied_on === DiscountType.RECURRING_PERCENTAGE_OF_FEE_HEAD &&
															!Object.keys(fee_head).length)
													? true
													: false
											}
											className='inline-flex justify-center disabled:bg-violet-400 rounded-md bg-[#6F6AF8] px-3 py-2 text-sm font-semibold text-white shadow hover:bg-[#6F6AF8]  sm:w-auto'
										>
											Create Discount
										</button>
									</div>
								</Form>
							)}
						</div>
					}
				/>
				<Modal open={fineModal} setOpen={setFineModal} title='Create Fine'>
					<div className='mx-auto text-center'>
						<Form
							onSubmit={async (data: any) => {
								let fine = {
									fine_name: data['Fine Name'],
									fine_amount: data['Amount'],
								};
								await create_fine({
									variables: {
										amount: Number(fine.fine_amount),
										fine_name: fine.fine_name,
										student_id: studentId,
									},
								});

								setFineModal(false);
							}}
						>
							<Input name='Fine Name' type='text' required />
							<Input
								name='Amount'
								type='number'
								onKeyDown={preventNegativeValues}
								onPaste={preventPasteNegative}
								prefix='₹'
								min={0}
								required
							/>
							<button className='bg-[#6F6AF8] ml-auto mt-5 text-white px-10 py-2 rounded-lg hover:bg-[#6F6AF8]'>
								Create Fine
							</button>
						</Form>
					</div>
				</Modal>
				<Modal
					className='max-w-4xl w-full'
					open={addFeeHeadModalShow}
					setOpen={setAddFeeHeadModalShow}
					title='Create Fee Head'
				>
					<div className='mx-auto text-center'>
						<Form
							className='w-full grid grid-cols-2 gap-5'
							onSubmit={async (data: any) => {
								await create_fee_head({
									name: data['Fee Head Name'],
									amt: data['Fee Amount'],
									fm: fee_type_string_to_frequency_months(data['Fee Type']),
									tax: data['Tax(%)'],
									start_date: data['Start Date'],
									end_date: data['End Date'],
									student: student[0]._id,
								});
								setAddFeeHeadModalShow(false);
								window.location.reload();
							}}
						>
							<Input name='Fee Head Name' add_error={() => {}} required />
							<Input
								name='Fee Amount'
								type='number'
								prefix='₹'
								min={0}
								add_error={() => {}}
								onPaste={preventPasteNegative}
								onKeyDown={preventNegativeValues}
								required
							/>
							<Input
								name='Tax(%)'
								type='number'
								add_error={() => {}}
								onPaste={preventPasteNegative}
								onKeyDown={preventNegativeValues}
								min={0}
								maxAmount={100}
								required
							/>

							<Input type='date' name='Start Date' add_error={() => {}} />
							<Input type='date' name='End Date' add_error={() => {}} />

							<RadioInput
								name='Fee Type'
								add_error={() => {}}
								options={['Monthly', 'Bimonthly', 'Quarterly', 'Half Yearly', 'One Time']}
								required
							/>

							<div className='col-span-2 flex justify-center items-center'>
								<button
									type='submit'
									className=' ml-auto mx-auto max-w-[10rem] w-full rounded-md bg-[#6F6AF8] px-3 py-2 text-sm font-semibold text-white shadow hover:bg-[#6F6AF8]'
								>
									Submit
								</button>
							</div>
						</Form>
					</div>
				</Modal>
				<Modal open={sendReminderConfirmationModal} setOpen={setSendReminderConfirmationModal}>
					<ConfirmationBox
						setOpen={setSendReminderConfirmationModal}
						funtion={() => {
							send_whatsapp_reminder_to_student({
								variables: {
									student_id: studentId,
								},
							});
						}}
						confirmationText='send reminder'
						ButtonText='Send Reminder'
					/>

				</Modal>
				{/* Modal for tags */}
				<Modal title={'Add Tags'} open={addTagModal} setOpen={setAddTagModal}>
					{!tagLoading && (
						<div className='flex flex-col gap-5 justify-center '>
							<div className='mb-3'>
								<NewTags
									tags={tags?.tags?.filter((tag: any) => {
										return !studentTags?.student?.tags?.some((studentTag: any) => {
											return String(tag._id) === String(studentTag._id);
										});
									})}
									setSelectedTags={setSelectedTags}
								/>
							</div>

							<button
								disabled={selectedTags.length === 0}
								className='bg-[#6F6AF8] disabled:opacity-50 w-full h-[3rem] text-white self-center rounded-lg  px-4 text-center '
								onClick={async (data: any) => {
									//console.log(selectedTags)
									try {
										if (selectedTags.length === 0) {
											toast.error(`please select atleast one tag`);
											return;
										}
										const res = await addTag({
											variables: {
												student: studentId,
												tagIds: tags?.tags
													?.filter((tag: any) => {
														return selectedTags.some((selectedTag: any) => {
															return String(tag.tagCode) === String(selectedTag);
															//return tag._id;
														});
													})
													.map((tag: any) => {
														//console.log(tag._id)
														return tag._id;
													}),
											},
											refetchQueries: [{ query: GET_STUDENT_TAGS, variables: { id: studentId } },
												{ query: GET_TAGS}],
										});
										toast.success(`tag added successfully`);
										setAddTagModal(false);

										set_students(await getStudent());
										// console.log(students)
										//window.location.reload();
									} catch (error) {}
								}}
							>
								Add
							</button>
						</div>
					)}

				</Modal>
				<div className='ml-4 mt-4 mr-2 w-full border-gray-200 rounded-md shadow p-5 mx-5'>
					<div className='text-md text-violet-500 text-lg mb-2 flex justify-between items-center'>
						<h2> Fee & Installment Details</h2>
						<span
							onClick={() => setSendReminderConfirmationModal(true)}
							className='text-sm hover:underline cursor-pointer '
						>
							Send Reminder
						</span>
					</div>
					<Summary
						value={feeValue}
						statusData={statusData}
						setViewDetails={setViewDetails}
						set_status_wise={set_status_wise}
						total_installment_discount={total_installment_discounts}
						installments={installments2}
						student_id={studentId}
					/>

					<Installments
						installments={installments2}
						setViewDetails={setViewDetails}
						viewDetails={viewDetails}
						feeDetails={feeDetails}
						statusData={statusData}
						status_wise={status_wise}
						set_status_wise={set_status_wise}
						student={student[0]}
						category={student[0].category}
						delete_individual_fine={delete_individual_fine}

						fee_heads={fee_heads.filter(
							(f: any) =>
								f.student === student[0]._id || f.class.includes(student[0].class) || f.is_trasport_fee === true,
						)}
					/>
					{user.access === Access.SUPER || user.access === Access.ALL_ACCESS || user.access === Access.MANAGEMENT ? (
						<div className='mt-4 text-right grid  xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-2'>
							<button
								onClick={() => setAddFeeHeadModalShow(true)}
								className=' bg-[#6F6AF8] text-white rounded-lg py-2 px-4'
							>
								Add Fee Head
							</button>
							<button
								onClick={() => setDiscoutModalShow(true)}
								className=' bg-[#6F6AF8] text-white rounded-lg py-2 px-4'
							>
								+ Add Discount
							</button>
							<button onClick={() => setFineModal(true)} className=' bg-[#6F6AF8] text-white rounded-lg py-2 px-4'>
								Add Fine
							</button>
							<button
								onClick={() => {
									set_modal(true);
									Mixpanel.track('profile_collect_fee_button', {
										user_name: user?.name,
										schoolId: studentdata.school?.id,
										school: studentdata.school.name,
										studentId: student[0]._id,
										studentName: student[0].name,
									});
								}}
								className=' bg-[#6F6AF8] text-white rounded-lg py-2 px-4'
							>
								Collect Fee
							</button>
							<button
								className=' bg-[#6F6AF8] text-white rounded-lg py-2 px-4'
								onClick={() => setAddTagModal(!addTagModal)}
							>
								Add Tags
							</button>
						</div>
					) : (
						''
					)}
				</div>
				<CollectFeeModal showModal={show_modal} setShowModal={set_modal} students={students} student={studentdata} />
			</LoadAndRender>
		</feeDetailsContext.Provider>
	);
};

export default FeeDetails;
