import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

export const  NewTags=({tags,setSelectedTags}:{tags:any,setSelectedTags:any})=>{

  const options= tags.map((tag:any)=>{
    return {value:tag.tagCode,label:tag.tagCode}
  })
  return(
    <>
    <Select
						closeMenuOnSelect={false}
						maxMenuHeight={200}
						theme={(theme) => ({
							...theme,
							borderRadius: 8,
							fontSize: 12,
							colors: {
								...theme.colors,
								primary: 'gray',
							},
						})}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								fontSize: 14,
								border: state.isFocused ? '0' : '1',
								borderColor: state.isFocused ? '#6F6AF8' : '#6F6AF8',
							}),
							placeholder: () => ({
								color: '#00000066',
								marginTop: -26,
								marginLeft: 10,
							}),
							container: (base) => ({
								...base,
								padding: 5,
								border: '0',
							}),
              multiValueRemove: (styles, { data }) => ({
                ...styles,
                color: '#959595',
                ':hover': {
                  backgroundColor: '#6F6AF8',
                  color: 'white',
                },
              }),
						}}
						onChange={(d) => {
							setSelectedTags(d.map((tag:any)=>tag.value))
              
						}}
						// components={animatedComponents}
						isMulti
						options={options}
						placeholder='Select tags'
					/>
    </>
  )
}
