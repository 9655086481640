import React, { useState } from 'react';
import Form from '../../../../../../components/Form/Form';
import Input from '../../../../../../components/Input/Input';
import Select from '../../../../../../components/Select/Select';
import leftarrow from '../../../../assets/leftarrow.svg';
import rightarrow from '../../../../assets/rightarrow.svg';
import { preventNegativeValues } from '../../../../../../helper/preventNegativeValues';
import { preventPasteNegative } from '../../../../../../helper/preventPasteNegative';
import { update_school } from '../../../SchoolProfile/pages/EditSchoolDetails';
import { useMutation } from '@apollo/client';
import { UPDATE_PREV_DUE_MIN_PAYMENT_POLICY } from './Queries';
import { toast } from 'react-toastify';

function CollectionRuleModal({ setCollectionRuleModal }: any) {
	const [addRule, setAddRule] = useState(true);
	const [update_prev_due_min_payment_policy] = useMutation(UPDATE_PREV_DUE_MIN_PAYMENT_POLICY);
	const [selectFormate, setSelectFormate] = useState({
		name: 'Create a New Installment',
	});
	return (
		<div className='flex space-x-4 mt-4'>
			<div className='w-1/2'>
				<div className='flex w-full justify-between  items-center'>
					<div
						onClick={() => {
							setAddRule(true);
						}}
						className={` w-full flex justify-between items-center  rounded-md shadow cursor-pointer ${
							addRule ? 'bg-[#F8FAFB]' : 'bg-[#DADBFC]'
						} `}
					>
						<div className='py-2 px-6 text-md text-edvion_black'>Add Rules</div>
						<div className='py-2 pr-10'>
							{addRule ? <img src={leftarrow} alt='' /> : <img src={rightarrow} alt='' />}
						</div>
					</div>
				</div>
			</div>
			<div className='w-1/2 min-h-[28rem] h-full shadow rounded-md p-4 flex flex-col'>
				<h3 className=' text-lg font-medium text-edvion_black'>Rules</h3>
				<div className='h-full'>
					<Form
						onSubmit={async (data: any) => {
							const res = await update_prev_due_min_payment_policy({
								variables: {
									prev_due_min_payment_policy_perc: Number(data['2. Enter min amount to be collected per installment']),
								},
							});
							if ((await res.data.prev_due_min_payment_policy_perc_update) === true) {
								toast.success('Due Min Payment Policy Is Updated');
								setCollectionRuleModal(false);
							}
							if ((await res.data.prev_due_min_payment_policy_perc_update) === false) {
								toast.error('Something Went Wrong !');
								setCollectionRuleModal(false);
							}
						}}
					>
						<Select
							selected={selectFormate}
							setSelected={setSelectFormate}
							labelStyle='text-[#6F6AF8] text-sm'
							label='1. Select Fee Type'
							options={['Installment Formate 1', 'Installment Formate 2', 'Installment Formate 3'].map((c) => {
								return { name: c };
							})}
						/>
						<Input
							postfix='%'
							type='number'
							labelStyle='text-[#6F6AF8] text-sm'
							name='2. Enter min amount to be collected per installment'
							add_error={() => {}}
							required
							min={8}
							max={100}
							step='any'
							onKeyDown={preventNegativeValues}
							onPaste={preventPasteNegative}
						/>
						<Input type='date' labelStyle='text-[#6F6AF8] text-sm' name='3. Select due date' add_error={() => {}} />
						<div className='flex justify-end mt-32'>
							<button className='rounded-lg transition-all duration-200 hover:bg-blue-700 bg-[#D9D9D978] text-[#959595] hover:text-white  px-8 py-1 max-w-fit'>
								Update
							</button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default CollectionRuleModal;
