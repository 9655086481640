import React, { useState, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import FeeReportDasboard from './pages/FeeReportDashboard/FeeReportDashboard';
import InstallmentWiseReport from './pages/InstallmentWiseReport/InstallmentWiseReport';

import PaymentModeWiseReport from './pages/PaymentModeWiseReport/PaymentModeWiseReport';
import TimeWiseReport from './pages/TimeWiseReport/TimeWiseReport';
import ChequeDDStatusReport from './pages/ChequeDDStatusReport/ChequeDDStatusReport';
import SectionWiseReport from './pages/SectionWiseReport/SectionWiseReport';
import Defaulters from './pages/Defaulters/Defaulters';
import LoadAndRender from '../../../../components/LoadAndRender/LoadAndRender';
import DiscountReport from './pages/DIscountReport/DiscountReport';
import Loading from '../../Loading';
import FeeReportsSmallLoader from '../SmallSkeleton/FeeReportsSmallLoader';
import FineWiseReport from './pages/FineWiseReport/FineWiseReport';
import StudentWiseReport from './pages/StudentWiseReport/StudentWiseReport';
import { dashboardContext } from '../../Dashboard';
import PosTransactionReport from './pages/PosTransactionsReport/PosTransactionReport';
// const gerSchoolData = async () => {
// 	var myHeaders = new Headers();
// 	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

// 	var requestOptions: RequestInit = {
// 		method: 'GET',
// 		headers: myHeaders,
// 		redirect: 'follow',
// 	};

// 	return await (await fetch(process.env.REACT_APP_BACKEND_URL + '/api/fee-reports/overview', requestOptions)).json();
// };

const FeeReport = () => {
	const [overview_data, set_overview_data] = useState<any>(null);
	const { school_data, instllment_wise_fee_report } = useContext(dashboardContext);

	return (
		<Loading component={<FeeReportsSmallLoader />}>
			<div className='text-edvion_black w-full'>
				<Routes>
					<Route path='/' element={<FeeReportDasboard overview_data={school_data} />} />
					<Route path='/defaulters' element={<Defaulters />} />
					<Route path='/installment_report' element={<InstallmentWiseReport reports={instllment_wise_fee_report} />} />
					<Route path='/section_report' element={<SectionWiseReport />} />
					<Route path='/payment_report' element={<PaymentModeWiseReport />} />
					<Route path='/time_report' element={<TimeWiseReport />} />
					<Route path='/status_report' element={<ChequeDDStatusReport />} />
					<Route path='/discount_report' element={<DiscountReport />} />
					<Route path='/fine_report' element={<FineWiseReport />} />
					<Route path='/student_report' element={<StudentWiseReport />} />
					{/* <Route path='/pos_report' element={<PosTransactionReport data={school_data?.school?.pos_transactions} />} /> */}
				</Routes>
			</div>
		</Loading>
	);
};

export default FeeReport;
