import React, { useEffect, useState } from 'react';
import payment_failed from '../../../../../src/assets/payment_failed.svg';
import { useParams, Link } from 'react-router-dom';
import LoadAndRender from '../../../../components/LoadAndRender/LoadAndRender';

function PaymentFailure() {
	let { id } = useParams();
	const [data, setData] = useState<any>([]);
	const [seconds, setSeconds] = useState(5);

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds !== 0) {
				setSeconds((prevSeconds) => prevSeconds - 1);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [seconds]);
	if (seconds === 0) window.location.replace(`/student_profile/${data?.invoice?.student._id}`);
	const getInvoice = async () => {
		var myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

		var requestOptions: RequestInit = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		};
		return await (await fetch(process.env.REACT_APP_BACKEND_URL + `/payment/invoice/${id}`, requestOptions)).json();
	};

	return (
		<LoadAndRender
			className=' h-full w-full'
			promise={async () => {
				setData(await getInvoice());
			}}
		>
			<div className='flex w-full h-full justify-between items-center p-4'>
				<div className='max-w-3xl w-full mx-auto rounded-lg shadow border px-4 py-8'>
					<div className='w-full flex justify-center items-center my-4'>
						<img src={payment_failed} alt={payment_failed} />
					</div>

					<h3 className='text-edvion_black text-3xl font-medium text-center'>
						Oh no! <br /> Payment Failed
					</h3>

					<p className='text-center  text-edvion_black text-md font-medium'>Order ID: {id}</p>
					<p className='text-center mt-4 mb-8 text-edvion_black text-md font-medium'>
						Amount: ₹{data?.transaction?.amount.toLocaleString('hi')}
					</p>

					<p className='text-xl text-center font-medium text-[#959595] my-2'>
						If any money was debited from your account, it will be credited back in 7 business days. Sorry for the
						inconvenience caused.
					</p>
					<div className='w-full flex justify-center items-center'>
						<Link
							className='px-4 py-2 border bg-[#fc4343] text-white rounded-lg max-w-[15rem] text-center mt-2 w-full mx-auto'
							to={`/student_profile/${data?.invoice?.student._id}`}
						>
							Retry Payment
						</Link>
					</div>
					<p className='text-xs text-center font-medium text-[#959595] my-4'>Redirect In {seconds} Seconds</p>
				</div>
			</div>
		</LoadAndRender>
	);
}

export default PaymentFailure;
