import { gql } from '@apollo/client';

export const GET_INSTALLMENTS = gql`
	query Student($id: String!) {
		student(id: $id) {
			name
			_id
			individual_fines {
				_id
				amount
				is_paid
				name
			}
			absolute_discounts {
				availed
				discount {
					_id
					value
					name
				}
			}
			installments {
				due_date
				to_pay
				applicable_fee_heads {
					amount_after_discounts
					paid
					to_pay
					fee_head {
						name
					}
					discounts {
						value
						name
					}
				}
				discounts {
					value
					name
				}
				fine
			}
		}
	}
`;

export const GET_FINAL_AMOUNT = gql`
	mutation Generate_payment_information(
		$pay_till_installment: Float!
		$selected_discounts: [ID!]!
		$student_id: ID!
		$prev_due_amount: Float
	) {
		generate_payment_information(
			pay_till_installment: $pay_till_installment
			selected_discounts: $selected_discounts
			student_id: $student_id
			prev_due_amount: $prev_due_amount
		) {
			amount
			applied_discounts {
				discount {
					name
					value
				}
			}
			unpaid_fines {
				amount
				name
			}
			installments_to_pay {
				applicable_fee_heads {
					amount_after_discounts
				}
				to_pay
				fine
			}
			min_prev_sess_dues_to_pay
			max_prev_sess_dues_to_pay
		}
	}
`;

export const MARK_COLLECTED_PAY = gql`
	mutation Mark_collected(
		$account_holder: String
		$amount: Float!
		$cheque_dd_no: String
		$date_on_cheque_dd: DateTime
		$pay_till_installment: Float!
		$payment_mode: PaymentMode!
		$selected_discounts: [ID!]!
		$student_id: ID!
		$prev_due_amount: Float!
	) {
		mark_collected(
			student_id: $student_id
			selected_discounts: $selected_discounts
			pay_till_installment: $pay_till_installment
			account_holder: $account_holder
			payment_mode: $payment_mode
			amount: $amount
			date_on_cheque_dd: $date_on_cheque_dd
			cheque_dd_no: $cheque_dd_no
			prev_due_amount: $prev_due_amount
		) {
			status
			fee_breakup
			_id
			invoice_pdf_link
		}
	}
`;

export const GET_SECTION_DATA = gql`
	query Section_fee_report($class: String!, $section: String!) {
		school {
			section_fee_report(class: $class, section: $section) {
				student {
					school {
						_id
						name
						email_id
						phone_number
						convenience_fee
						logo_url
					}
					name
					father_name
					school_generated_id
					_id
					category
					class
					dob
					gender
					phone_number
					section
					previous_session_dues
				}
				total_amount
				total_amount_paid
			}
		}
	}
`;

export const PAY = gql`
	mutation Pay(
		$pay_till_installment: Float!
		$selected_discounts: [ID!]!
		$student_id: ID!
		$is_school: Boolean!
		$prev_due_amount: Float!
	) {
		pay(
			pay_till_installment: $pay_till_installment
			selected_discounts: $selected_discounts
			student_id: $student_id
			is_school: $is_school
			prev_due_amount: $prev_due_amount
		)
	}
`;

export const PAY_VIA_POS = gql`
	mutation Pay_via_pos(
		$pay_till_installment: Float!
		$selected_discounts: [ID!]!
		$student_id: ID!
		$is_school: Boolean!
		$prev_due_amount: Float!
		$pos_id: String!
	) {
		pay_via_pos(
			pay_till_installment: $pay_till_installment
			selected_discounts: $selected_discounts
			student_id: $student_id
			is_school: $is_school
			prev_due_amount: $prev_due_amount
			pos_id: $pos_id
		) {
			_id
			createdAt
			invoice
			p2pRequestId
			status
			timedOut
			updatedAt
		}
	}
`;
export const GET_POS_TRANSACTION = gql`
	query School($id: String!) {
		school {
			pos_transaction(id: $id) {
				_id
				status
				p2pRequestId
				invoice
				createdAt
				timedOut
				updatedAt
			}
		}
	}
`;
export const GET_INVOICE = gql`
	query School($invoice_id: String!) {
		school {
			invoice(invoice_id: $invoice_id) {
				_id
				createdAt
				fee_breakup
				invoice_pdf_link
				status
			}
		}
	}
`;
