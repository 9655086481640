import { gql } from '@apollo/client';

export const SCHOOL_WALLET = gql`
	query School($wallet_type: WalletType!) {
		school {
			school_wallet(wallet_type: $wallet_type) {
				_id
				balance
				school_id
				transactions {
					_id
					amount
					description
					school_id
					transaction_status
					transaction_type
					wallet_type
				}
				wallet_type
			}
			whatsapp_rate
			sms_rate
			fee_reminder_policy
		}
	}
`;

export const RECHARGE_WALLET = gql`
	mutation Recharge_wallet($amount: Float!, $wallet_type: WalletType!) {
		recharge_wallet(amount: $amount, wallet_type: $wallet_type)
	}
`;
