import { gql } from '@apollo/client';

export const GET_SCHOOL_DATA = gql`
	{
		school {
			number_of_students
			number_of_sections
			convenience_fee
			pos_transactions {
				_id
				createdAt
				invoice
				p2pRequestId
				school_id
				status
				timedOut
				updatedAt
			}
			instllment_wise_fee_report {
				due_date
				total_amount
				total_amount_paid
				total_students
				total_students_paid
			}
			stops {
				name
				school_id
				_id
			}
			pos {
				_id
				createdAt
				device_id
				name
				school
				updatedAt
			}
			sms_wallet: school_wallet(wallet_type: SMS) {
				_id
				balance
				school_id
				transactions {
					_id
					amount
					description
					school_id
					transaction_status
					transaction_type
					wallet_type
					createdAt
				}
				wallet_type
			}
			whatsapp_wallet: school_wallet(wallet_type: WHATSAPP) {
				_id
				balance
				school_id
				transactions {
					_id
					amount
					description
					school_id
					transaction_status
					transaction_type
					wallet_type
					createdAt
				}
				wallet_type
			}
			whatsapp_rate
			sms_rate
			fee_reminder_policy
		}
	}
`;

export const SENT_REMINDERS_TO_STUDENTS = gql`
	mutation Send_reminders {
		send_reminders
	}
`;
