
import React,{useEffect, useState} from "react";
import { useQuery,useLazyQuery, useMutation } from "@apollo/client";
import { DELETE_TAG, GET_TAGS } from "../Querries";
import Table from "../../../../../components/Table/Table";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Modal from "../../../../../components/Modal/Modal";

const TagList=({tags,tagLoading}:{tags:any,tagLoading:any})=>{
    const [isTagOpen,setIsTagOpen]= useState(false)
    const [tag,setTag]=useState({
        tagCode:'',
        name:'',
        description:''
    })

    const [deleteTag,{data:deleteData}]= useMutation(DELETE_TAG);

    return(
        <div>

<Modal title={""} open={isTagOpen} setOpen={setIsTagOpen}>

<div className='flex flex-col justify-between items-center mb-2 '>
            <h2 className='text-md text-center text-violet-500 text-lg'>Tag Details</h2>
        </div>
        <div className='flex justify-evenly items-center gap-5 text-wrap'>
            <div className='p-2 text-center text-wrap'>
                <div className=' text-base text-[#959595]'>Tag Code</div>
                <div className='text-base text-edvion_black capitalize text-balance overflow-auto max-h-40'>
                {tag.tagCode.length>20?`${tag.tagCode.substring(0,20)}...`:tag.tagCode}
                </div>
            </div>
            <div className='p-2 text-center text-wrap'>
                <div className=' text-base text-[#959595]'>Tag Name</div>
                <div className='text-base text-edvion_black capitalize text-balance overflow-auto max-h-40'>
                {tag.name.length>20?`${tag.name.substring(0,20)}...`:tag.name}
                </div>
            </div>
            </div>
            <div className='p-2 text-center text-wrap'>
                <div className='text-base text-[#959595]'>Tag Description</div>
                <div className='text-base text-edvion_black capitalize text-balance overflow-auto max-h-40'>
                    {tag.description}
                </div>
            </div>
          
        
</Modal>
            {!tagLoading &&
<Table heading={'Tags Available'}
 
data={[
    ['Tag Name','Tag Code','Tag Description','No of Students','Action'],
     ...tags?.map((tag:any)=>[
     <button className="text-violet-600" onClick={()=>{
        setTag({...tag});
        setIsTagOpen(!isTagOpen)

     }}>
    {tag.name.length>20?`${tag.name.substring(0,5)}...`:tag.name}

     </button>,
     <div>
        <span className="bg-[#6F6AF8] text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-[#6F6AF8]-900 dark:text-white">
        {tag.tagCode.length>20?`${tag.tagCode.substring(0,5)}...`:tag.tagCode}
            </span>
     </div>,
     <div
     className={tag.description.length>20?'cursor-pointer':''}
     onClick={()=>{
        if(tag.description.length>20)
        {
        setTag({...tag});
        setIsTagOpen(!isTagOpen)
        }
    
     }}>
        {tag.description.length>20?`${tag.description.substring(0,20)}...`:tag.description}
        </div>,
     <div>{tag.count}</div>,
     <div className='space-x-10'>
        <i className='fa-solid fa-trash cursor-pointer'
         onClick={async()=>{
            try{
            await deleteTag({variables:{
            id:tag._id
        },
    refetchQueries:[{query:GET_TAGS}]
    })
        toast.success(`Deleted tag successfully`)
    }
        catch(error){
           // toast.error(`couldn't delete tag`)
        }
        
        }}></i>
     </div>
     ],)]}/>
}
    </div>
)}

export default TagList;