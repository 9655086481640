import React, { useEffect, useState } from 'react';
import payment_failed from '../../../../../src/assets/payment_failed.svg';

function PosPaymentFailure({ data }: any) {
	return (
		<div className='flex w-full h-full justify-between items-center p-4'>
			<div className='max-w-3xl w-full mx-auto rounded-lg px-4 py-8'>
				<div className='w-full flex justify-center items-center my-4'>
					<img src={payment_failed} alt={payment_failed} />
				</div>

				<h3 className='text-edvion_black text-3xl font-medium text-center'>
					Oh no! <br /> Payment Failed
				</h3>

				<p className='text-center  text-edvion_black text-md font-medium'>Order ID: {data?.school?.invoice?._id}</p>
				<p className='text-center mt-4 mb-8 text-edvion_black text-md font-medium'>
					Amount: ₹ {JSON.parse(data.school.invoice.fee_breakup)['amount'].toLocaleString('hi')}
				</p>

				<p className='text-xl text-center font-medium text-[#959595] my-2'>
					If any money was debited from your account, it will be credited back in 7 business days. Sorry for the
					inconvenience caused.
				</p>
			</div>
		</div>
	);
}

export default PosPaymentFailure;
