import { Navigate } from 'react-router-dom';
import LoadAndRender from '../../components/LoadAndRender/LoadAndRender';
import { useState, createContext } from 'react';
import React from 'react';
import { Sidebar, SidebarItem, NestedSidebarItem } from './components/Sidebar/Sidebar';
import Overview from './components/Overview/Overview';
import PortalAccess from './components/PortalAccess/PortalAccess';
import StudentProfile from './components/StudentProfile/StudentProfile';
import Sections from './components/Sections/Section';
import FeeConfig from './components/FeeConfig/FeeConfig';
import DashboardIcon from './assets/dashboard-icon.svg';
import RupeeIcon from './assets/rupee-icon.svg';
import StudentIcon from './assets/student-icon.svg';
import LogoutIcon from './assets/logout-icon.svg';
import WalletIcon from './assets/wallet.svg';
import { Route, Routes } from 'react-router-dom';
import FeeReport from './components/FeeReports/FeeReport';
import SchoolProfile from './components/SchoolProfile/SchoolProfile';
import FeeCollection from './components/FeeCollection/FeeCollection';
import Select from '../../components/Select/Select';
import CollectFee from './components/FeeCollection/pages/CollectFee/CollectFee';
import { Access } from './AccessEnum';
import { useQuery } from '@apollo/client';
import { GET_SCHOOL_DATA } from './components/Overview/Querries';
import { Mixpanel } from '../../util/mixpanel';
import PaymentPending from './components/PaymentStatus/PaymentPending';
import PaymentSuccess from './components/PaymentStatus/PaymentSuccess';
import PaymentFailure from './components/PaymentStatus/PaymentFailure';
import Wallet from './components/Wallet/Wallet';
import SchoolTags from './components/SchoolTags/SchoolTags';

export const getSchool = async () => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

	var requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};

	const res: any = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/', requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

// const getFeeCollection = async () => {
// 	var myHeaders = new Headers();
// 	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

// 	var requestOptions: RequestInit = {
// 		method: 'GET',
// 		headers: myHeaders,
// 		redirect: 'follow',
// 	};
// 	const res =  await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/fee-collection-data', requestOptions);

// 	if (res.ok) {
// 		return await res.json();
// 	} else {
// 		const message = await res.json();
// 		if (message.statusCode === 401) {
// 			localStorage.removeItem('token');
// 			window.location.reload();
// 		}
// 	}
// };

export const getStudent = async () => {
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));

	var requestOptions: RequestInit = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow',
	};
	const res: any = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/school/students', requestOptions);
	if (res.ok) {
		return await res.json();
	} else {
		const message = await res.json();
		if (message.statusCode === 401) {
			localStorage.removeItem('token');
			window.location.reload();
		}
	}
};

export const dashboardContext = createContext<any>(null);

function Dashboard({ user, set_user }: any) {
	const [school, set_school] = useState<any>(null);
	const [portalUsers, setPortalUsers] = useState<any>(null);
	const [totalfeecollection, setTotalfeeCollection] = useState<any>(null);
	const [sections, set_sections] = useState<any>(null);
	const [students, set_students] = useState<any>(null);
	const [showSidebar, setShowSidebar] = useState(true);
	const { data: school_data, loading, error } = useQuery(GET_SCHOOL_DATA);

	const latest_due_date =
		!loading &&
		school_data?.school?.instllment_wise_fee_report
			.filter((i: any) => new Date(i.due_date) < new Date())
			.sort((a: any, b: any) => {
				return new Date(b.due_date).getTime() - new Date(a.due_date).getTime();
			})[0]?.due_date;

	if (!user) return <Navigate to='/login'></Navigate>;
	return (
		<dashboardContext.Provider
			value={{
				getStudent,
				school,
				set_school,
				school_data,
				portalUsers,
				setPortalUsers,
				// totalfeecollection,
				// setTotalfeeCollection,
				user,
				set_user,
				sections,
				set_sections,
				students,
				set_students,
				classes: sections && [...new Set(sections.map((section: any) => section.class))],
				instllment_wise_fee_report: school_data?.school.instllment_wise_fee_report,
				availed_discount_report: school_data?.school.availed_discounts,
				cheques: school_data?.school?.cheques,
				demand_drafts: school_data?.school?.demand_drafts,
				individual_fines: school_data?.school?.individual_fines,
				latest_due_date,
			}}
		>
			<LoadAndRender
				loading={loading}
				loader
				promise={async () => {
					const [school, students] = await Promise.all([getSchool(), getStudent()]);
					//setTotalfeeCollection(fees_collection);
					set_school(school);
					setPortalUsers(school.admins);
					set_sections(school.sections);
					set_students(students);
				}}
			>
				<div className='dashboard flex w-full'>
					<div className='h-full relative'>
						<Sidebar open={showSidebar} setOpen={setShowSidebar} schoolName={school?.school.name || 'Edviron'}>
							<SidebarItem icon={DashboardIcon} name='Overview' to='/' />
							<NestedSidebarItem icon={RupeeIcon} open={showSidebar} name='Fee Management'>
								{user.access === Access.SUPER ||
								user.access === Access.ALL_ACCESS ||
								user.access === Access.MANAGEMENT ? (
									<SidebarItem name='Fee Collection' to='/fee_collection' />
								) : (
									<></>
								)}
								{user.access === Access.SUPER ||
								user.access === Access.ALL_ACCESS ||
								user.access === Access.MANAGEMENT ? (
									<SidebarItem name='Fee Configuration and Settings' to='/fee_config' />
								) : (
									<></>
								)}

								<SidebarItem
									onTap={() => {
										Mixpanel.track('report_tab', {
											schoolId: user.school?.id,
											school: user.school?.name,
											user_name: user?.name,
										});
									}}
									name='Fee Reports'
									to='/fee_reports'
								/>

								<SidebarItem name='Tags' to='/tags' />
							</NestedSidebarItem>
							<SidebarItem icon={StudentIcon} name='Student Profile' to='/student_profile' />
							{user.access === Access.SUPER || user.access === Access.ALL_ACCESS ? (
								<SidebarItem icon={StudentIcon} name='Institute Profile' to='/institute_profile' />
							) : (
								<></>
							)}
							<SidebarItem icon={WalletIcon} name='Wallet' to='/wallet' />
							<SidebarItem
								className='mt-auto'
								icon={LogoutIcon}
								name='Log Out'
								onTap={() => {
									localStorage.removeItem('token');
									set_user(null);
								}}
							/>
						</Sidebar>
					</div>

					<div
						className={
							'flex flex-col flex-1 w-full bg-[#F8FAFB] transition-all duration-500 ' +
							(showSidebar ? ' pl-[17rem]' : ' pl-20')
						}
					>
						<div className='bg-[#F8FAFB] flex w-full h-12 sticky top-0 z-10'>
							<div className='top-menu ml-auto mr-5 flex gap-1 '>
								<Select
									options={[{ name: 'Session 2024-2025' }]}
									selected={{ name: 'Session 2024-2025' }}
									setSelected={() => {}}
								/>
								<Select
									options={[{ name: (user && user.school && user.school.name) || 'School Name' }]}
									selected={{ name: (user && user.school && user.school.name) || 'School Name' }}
									setSelected={() => {}}
								/>
							</div>
						</div>

						<Routes>
							{!loading && (
								<Route
									path='/'
									element={school && <Overview portalUsers={portalUsers} school={school_data?.school} />}
								/>
							)}
							<Route path='/fee_collection/*' element={school && <FeeCollection school={school?.school} />} />
							<Route path='/fee_collection/collect_fee/*' element={<CollectFee _loading={loading} />} />
							<Route path='/fee_config/*' element={<FeeConfig />} />
							<Route path='/student_profile/*' element={<StudentProfile />} />
							<Route path='/fee_reports/*' element={<FeeReport />} />
							<Route path='/sections/*' element={school && <Sections />} />
							<Route path='/payment/pending/:id' element={<PaymentPending />} />
							<Route path='/payment/success/:id' element={<PaymentSuccess />} />
							<Route path='/payment/failure/:id' element={<PaymentFailure />} />
							<Route path='/tags' element={<SchoolTags />} />

							<Route
								path='/portal_access/*'
								element={
									school &&
									portalUsers && (
										<PortalAccess portalUsers={portalUsers} setPortalUsers={setPortalUsers} currentUser={user} />
									)
								}
							/>
							<Route path='/institute_profile/*' element={school && <SchoolProfile school={school.school} />} />
							<Route path='/wallet/*' element={<Wallet />} />
						</Routes>
					</div>
				</div>
			</LoadAndRender>
		</dashboardContext.Provider>
	);
}

export default Dashboard;
