import { gql } from '@apollo/client';

export const UPDATE_PREVIOUS_SESSION_DUE_AMOUNT = gql`
	mutation UpdatePreviousSessionDues($student_id: String!, $previous_due_amount: Float!) {
		updatePreviousSessionDues(student_id: $student_id, previous_due_amount: $previous_due_amount)
	}
`;

export const UPDATE_PREV_DUE_MIN_PAYMENT_POLICY = gql`
	mutation Prev_due_min_payment_policy_perc_update($prev_due_min_payment_policy_perc: Float!) {
		prev_due_min_payment_policy_perc_update(prev_due_min_payment_policy_perc: $prev_due_min_payment_policy_perc)
	}
`;
