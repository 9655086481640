import React, { useContext, useEffect, useState } from "react";
import Form from '../../../../components/Form/Form';
import Input from "../../../../components/Input/Input";
import {  useMutation, useQuery } from '@apollo/client';
import { CREATE_TAG, GET_TAGS } from "./Querries";
import { toast } from "react-toastify";
import Modal from '../../../../components/Modal/Modal'
import TagList from "./components/TagList";


const SchoolTags=()=>{
   const [tag,setTag]= useState({})
   const [show_create_tag,set_show_create_tag]= useState(false)
   const [createTag,{data,loading,error}]=useMutation(CREATE_TAG)
   const {data:tagsData,loading:tagsLoading,error:tagsError}=useQuery(GET_TAGS)




return(
    <div>
        <Modal title={"Create Tag"} open={show_create_tag} setOpen={set_show_create_tag}>
        <Form 
        className='grid grid-cols-2 gap-5 '
        onSubmit={async (data:any)=>{
            
               //console.log(data);
            
            try{
            const res= await createTag({variables:
                { name:data['Tag Name'],
            tagCode:data['Tag-Code'],
            description:data['Tag-Description']
               },
               refetchQueries:[{query:GET_TAGS}]
    });
    toast.success(`Created tag successfully`)
    set_show_create_tag(false)


}catch(error){

}
        }}>
        <Input type='text' name='Tag Name' placeholder='Enter Tag Name' add_error={() => {}} required />
        <Input type='text' name="Tag-Code" placeholder="Enter Tag Code" add_error={() => {}} required />
        <Input type='text' name="Tag-Description" placeholder="Enter Tag description" add_error={() => {}} required />
        <div className='mt-2 col-span-2  mb-10 text-center'>
				<button className='py-2 px-16 max-w-[15rem] w-full rounded-lg bg-[#6F6AF8] text-white'>Add</button>
			</div>
        </Form>
        </Modal>
       

        <div className="flex gap-5 my-2 py-2">
        <button
								onClick={() => {
									set_show_create_tag(!show_create_tag)
								}}
								className=' bg-[#6F6AF8] text-white rounded-lg py-2 px-4 right'>
								Create Tag
							</button>

        </div>
                            {!tagsLoading &&  <TagList tags={tagsData?.tags} tagLoading={tagsLoading}/>}   
    </div>
)
}

export default SchoolTags;